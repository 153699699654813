.loginSectionWelcome {
  background: url('../../assests/images/bg-top.png') top right no-repeat,
    url('../../assests/images/bg-bottom.png') bottom left no-repeat;
  background-size: 21%, 23%;
  height: 100vh;
  overflow-y: auto;
}

.plan-div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
}

.loginFormWrp {
  width: 475px;
  background-color: #fff;
  padding: 13px 20px;
  border-radius: 23px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

/* Text styles */
.text-green {
  color: #469b88;
}

.registerText {
  color: #240f51;
  font-size: 26px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 0 !important;
  margin-top: 15px;
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 700;
}

.tagtext {
  color: #292b2d;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Neurialgrotesk', sans-serif;
}

.input-header-text {
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 3px;
  margin-left: 2px;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  font-weight: 400;
}

.check-label-text {
  margin: 2.2px;
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 400;
}

.textContainer {
  color: rgba(183, 184, 193, 1);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.14px;
  font-family: 'Neurialgrotesk', sans-serif;
}

/* Button styles */
.sign-btn {
  padding: 16px 147px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.setup-btn {
  padding: 16px 105px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.sign-btn-load {
  padding: 16px 165px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

/* Input styles */
.register-focus:focus {
  border: none;
}

.welcome-form-input-box {
  box-shadow: 0px 5px 30px 0px #0000001a;
  border: 0;
  border-radius: 10px;
  max-width: 100%;
  height: 55px;
  align-items: center;
}

.checkbox-welcome-new {
  width: 16px;
  height: 16px;
  padding: 0;
  margin-right: 6px;
  margin-left: 4px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: middle;
}

.verification-box:focus {
  border-color: #5796E9 !important;
  outline: none !important;
}

.feedback-btn {
  padding: 16px 85px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}

.feedback-btn-load {
  padding: 16px 85px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  font-family: 'Neurialgrotesk', sans-serif;
  font-weight: 500;
}


/* Responsive styles */
@media only screen and (max-width: 600px) {
  .loginFormWrp {
    width: 100% !important;
    margin-bottom: 18px;
    margin-right: 10%;
    margin-left: 10%;
  }
  .logoCss svg {
    width: 40vw;
  }
  .sign-btn {
    padding: 12px 120px !important;
  }
  .setup-btn {
    padding: 12px 60px !important;
  }
  .sign-btn-load {
    padding: 12px 70px !important;
  }
  .registerText {
    font-size: 5.5vw !important;
  }
  .tagtext {
    font-size: 12px;
  }
  .welcome-form-input-box {
    height: 45px;
  }
  .input-header-text {
    font-size: 14px;
    color: #040C22;
  }
  .store-logos {
    display: block;
  }
  .store-logos-img {
    width: 150px !important;
    height: 50px !important;
  }
  .social-logos {
    width: 100%;
  }
  .col-md-8 {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .verification-box:focus {
    border-color: #5796E9 !important;
    outline: none !important;
  }
}

@media only screen and (max-width: 430px) {
  .loginFormWrp {
    width: 100% !important;
    margin-bottom: 18px;
    margin-right: 10%;
    margin-left: 10%;
  }
  .logoCss {
    width: 100% !important;
  }
  .logoCss svg {
    width: 196px;
  }
  .sign-btn {
    padding: 12px 80px !important;
  }
  .sign-btn-load {
    padding: 12px 120px !important;
  }
  .setup-btn {
    padding: 12px 40px !important;
  }
  .store-logos {
    display: inline-grid !important;
  }
  .store-logos-img {
    margin-bottom: 10px !important;
    width: 150px !important;
    height: 60px !important;
  }
  .welcomeText {
    color: #240f51;
    font-size: 6vw !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 8px !important;
  }
  .tagtext {
    color: #292b2d;
    font-size: 3.2vw;
    margin-left: 0px !important;
  }
  .free-trial, .standard-month, .pro-month, .standard-year, .pro-year, .standard-month-current-plan, .standard-year-current-plan, .pro-month-current-plan, .pro-year-current-plan, .free-trial-current-paln, .no-current-plan {
    height: 40px !important;
  }
  .cancel-plan-btn {
    padding: 16px 28px !important;
  }
  .social-logos {
    margin-top: 30px;
    width: 325px;
    margin-left: 12px;
  }
  .welcome-form-input-text {
    color: #8f94a3;
    font-size: 10px !important;
    font-weight: 600;
  }
  .loginSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }
  .planSectionWelcome {
    background: url('../../assests/images/bg-top.png') top right no-repeat,
      url('../../assests/images/bg-bottom.png') bottom left no-repeat;
    background-size: 35%, 60%;
  }
  .loginSectionWelcomeBottom {
    background-image: url('../../assests/images/bg-bottom.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: bottom left;
  }
  .welcome-plan-text {
    text-align: center;
    width: 250px;
    height: 40px;
  }
  .padding-none {
    padding-left: 0px !important;
    margin-right: 5%;
    margin-left: 5%;
    padding-right: 0px;
  }
  .store-image {
    margin-top: 10px !important;
    width: auto !important;
    justify-content: center !important;
  }
  .verification-box {
    width: 38px;
    height: 38px;
    margin-right: 3px;
    margin-left: 3px;
    border: 1px solid #d0d0d0;
  }
  .email-gif {
    width: 60%;
    height: 60%;
  }
  .col-md-8 {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .verification-box:focus {
    border-color: #5796E9 !important;
    outline: none !important;
  }
}

  