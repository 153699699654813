@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafc2886377d58490d8_NeurialGrotesk-Bold.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf59d138f741e5fc77_NeurialGrotesk-Extrabold.otf')
      format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caafe9bad34580d6a81b_NeurialGrotesk-ExtraboldItalic.otf')
      format('opentype'),
    url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9b041d495e27948a_NeurialGrotesk-BoldItalic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf3222799eb0c2fbe3_NeurialGrotesk-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295caaf9d4478ec493b2f1e_NeurialGrotesk-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0f5b483166ec54b0f_NeurialGrotesk-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab020598e191aef5fc2_NeurialGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab09330f7deb6c477ab_NeurialGrotesk-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neurialgrotesk';
  src: url('https://uploads-ssl.webflow.com/6295c7949b041d1ca927834f/6295cab0cd89fc24cd355ffd_NeurialGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

.inter {
  font-family: 'Inter', sans-serif;
}

ul {
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}
a:hover {
  color: unset !important;
}
.yellow-btn .new-dark-yellow:hover {
  background: #dd7802;
}
.small-heading a {
  color: #989898;
}
.small-heading a:hover {
  color: #989898;
}
.white {
  color: #fff;
}
#file-format {
  /* display: none; */
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}
.black {
  color: #0e132f !important;
}
.light-black {
  color: #020202 !important;
}
body {
  background-color: #f5f5f5 !important;
  /* font-family: 'Neurial Grotesk' !important !important; */
}
.active .amount-active {
  margin-left: 10px;
  margin-top: 0px;
}
.text-13px {
  font-size: 13px;
  font-weight: 700px;
}
.red {
  color: #630000 !important;
  background-color: rgba(226, 92, 92, 0.5) !important;
}
.main-wrapper select {
  border: none;
  color: rgba(72, 159, 205, 1);
  text-align: center;
  font-size: 15px;
  -webkit-appearance: none;
}
.budget-select {
  -webkit-appearance: menulist !important;
}

.your-account-balance-wrp-logo-img {
  width: 47px;
}
.navbar-brand img {
  width: 40px;
  display: inline-block;
}
.navbar-brand {
  display: inline-block;
  white-space: nowrap;
}
.header-wrp .col-md-4 {
  display: inline-flex;
  justify-content: right;
}
.header-wrp .left {
  justify-content: left;
}
.comn-btn i {
  margin-left: 10px;
  font-size: 20px;
  vertical-align: middle;
}
img {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.header-wrp {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 15px 35px;
  margin-bottom: 15px;
}
.inline {
  display: inline-flex;
}
.heading {
  margin-bottom: 0px;
  vertical-align: middle;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}
.super-advice {
  display: inline-flex;
  align-items: center;
  /* width: 160px; */
  justify-content: center;
  vertical-align: middle;
}
.profile-img {
  margin-left: 35px;
}
.profile-img.upload-img {
  width: 49px;
}
.profile-img.upload-img img {
  width: 49px;
}
.btn i {
  margin-left: 10px;
}
.row.expenses-wrp {
  margin-top: 6rem;
}
.comn-btn {
  margin-left: 15px;
  border: none;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: normal;
  transition: 0.3s ease;
  border-radius: 10px;
  color: #fff !important;
  background-color: #469b88;
  line-height: 20px;
  font-family: Neurialgrotesk, sans-serif;
}
a.comn-btn:hover {
  background-color: #e2c55c !important;
}
.purple {
  color: #fff !important;
  background-color: #57469b !important;
}
.col-md-4.offset-md-4.right-section {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.profile-img img {
  border-radius: 50%;
  width: 59px;
}
a {
  text-decoration: none !important;
}

.appmain {
  flex: 1;
  display: flex;
  z-index: 8;
  position: relative;
}
.fixed-sidebar .app-sidebar {
  position: fixed;
}
.app-main__outer {
  width: 100%;
  overflow-y: scroll;
  padding: 0px 20px 135px 270px;
  height: 100vh;
  padding-bottom: 135px;
}
.app-sidebar {
  width: 260px;
  display: flex;
  z-index: 11;
  overflow: hidden;
  padding: 20px 25px;
  height: calc(100vh - 12%);
  min-width: 230px;
  position: fixed;
  flex: 0 0 230px;
  background-color: #fff;
  transition: all 0.2s;
  border-radius: 18px;
  justify-content: center;
  align-content: center;
  left: 0;
}
.fixed-sidebar .app-main .app-main__outer {
  z-index: 9;
  padding-left: 280px;
}
.app-main .app-main__outer {
  flex: 1;
  flex-direction: column;
  display: flex;
  z-index: 12;
}
.vertical-nav-menu li {
  list-style-type: none;
  margin-bottom: 10px;
  position: relative;
}
.scrollbar-sidebar,
.scrollbar-container {
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-between;
}
.vertical-nav-menu i {
  margin-right: 9px;
}
.vertical-nav-menu a {
  color: #767676;
  display: inline-flex;
  width: 100%;
  border-radius: 13px;
  text-decoration: none;
  transition: 0.3s ease;
  vertical-align: middle;
  padding: 11px 50px;
}
.hand-img {
  display: inline-block;
  width: 30px;
}
.block {
  display: inline-block;
}
.heading-main {
  margin-bottom: 15px;
}
.air-image img {
  width: 40px;
  display: inline-flex;
}
.new-table .new-img img {
  width: unset;
}
.new-img .table-content {
  margin-left: 10px !important;
}
.big-planner-new {
  padding: 10px 235px !important;
}
.networth {
  padding: 5px 0px !important;
}
.air-image {
  display: inline-table;
  margin-right: 15px;
  position: relative;
}
.vertical-nav-menu a::before {
  content: '';
  position: absolute;
  height: 41px;
  background-color: #469b88;
  width: 8px;
  left: -20px;
  opacity: 0;
  border-radius: 0px 10px 10px 0px;
  top: 3px;
}
.vertical-nav-menu a:hover {
  background-color: #469b88 !important;
  color: #fff !important;
}
.vertical-nav-menu a:hover svg path {
  stroke: #fff;
}
.vertical-nav-menu svg {
  margin-right: 10px;
}
a {
  text-decoration: none;
}
.vertical-nav-menu a:hover::before {
  opacity: 1;
}
.total-clients {
  background-color: #fff;
  padding: 28px;
  display: grid;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 100%;
  border-radius: 10px;
}
.percentage {
  background-color: #d5f1de;
  border-radius: 27px;
  font-size: 12px;
  line-height: 23px;
  display: flex;
  padding: 3px 15px;
  margin-left: 25px;
  color: #469b88;
  width: 85px;
  justify-content: center;
}
.margin-nu {
  margin-top: 75px;
  height: 30px;
  align-items: center;
}

/* .margin-nu .number-heading {
  color: #020202;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.32px;
} */

.main-wrp {
  display: flex;
}
.main-wrp .col-lg-3:first-child {
  margin-left: 0px;
}
.main-wrp .col-lg-3,
.main-wrp .col-lg-2,
.main-wrp .col-lg-4 {
  margin-left: 15px;
}
.text-client {
  color: #757575 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.15px;
}
/* .margin-amount {
  height: 60px;
} */
.air-btn .amount-active {
  margin-top: 0px;
  margin-left: 20px;
}
.air-image .amount-active {
  position: absolute;
  right: 0;
}
.amount-active {
  background-color: #d5f1de;
  border-radius: 30px;
  text-align: center;
  padding: 4px 16px;
  margin-top: 10px;
  color: #00634d !important;
  display: inline-block;
  font-size: 15px;
  line-height: 23px;
}
.amount-inactive {
  padding: 3px 13px;
  margin-top: 10px;
  color: #630000;
  border-radius: 27px;
  font-size: 16px;
  line-height: 23px;
  background-color: rgba(226, 92, 92, 0.5);
}
.amount-activated {
  padding: 2px 13px;
  border-radius: 27px;
  line-height: 20px;
  color: #630000;
  font-size: 16px;
  background-color: rgba(226, 197, 92, 0.5);
  margin-top: 10px;
}
.bold {
  font-weight: bold !important;
  font-family: 'NeurialGrotesk-Bold';
}
.amount-number {
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}
.share-number {
  border-radius: 50px;
  text-align: center;
  background: #d5f1de;
  padding: 10px;
  font-weight: bold;
  margin-top: 22px;
  font-size: 26px;
  color: #469b88;
}
.group-active {
  background-color: #d5f1de;
  border-radius: 27px;
  text-align: center;
  display: flex;
  font-size: 12px;
  line-height: 23px;
  justify-content: center;
  align-items: center;
}
.air-new {
  margin-top: 30px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.total-clients .air-new {
  margin-top: 0px;
}
.air-new-active {
  display: inline-flex;
  margin-top: 30px;
  align-items: center;
}
.share-view {
  text-align: center;
  padding: 10px;
  background: #469b88;
  color: #fff;
  border-radius: 11px;
  margin-top: 18px;
}
.total-clients .comn-btn {
  margin-left: 0px;
  margin-top: 40px;
  padding: 11px 20px;
}
.text-jeremy {
  font-weight: 700;
  font-size: 32px;
  line-height: 34px;
  color: #0e132f;
  letter-spacing: -0.32px;
}
.total-top-group {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.image {
  width: 35px;
}
.group-activated {
  background-color: lightgreen;
  width: 100px;
  padding: 1px;
  border-radius: 27px;
  font-size: 12px;
  padding-left: 10px;
  height: 22px;
  margin-top: 6px;
}

/* .comn-btn {
  margin-left: 15px;
  border: none;
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background-color: #469b88;
} */
.group-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.group-button {
  padding: 1px 14px 2px;
  font-size: 15px;
  display: inline-block;
  transition: 0.3s ease;
  background-color: white;
  border-radius: 13px;
  margin-bottom: 10px;
  color: black;
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 19px;
}
.group-plan {
  padding: 3px;
  width: 40px;
  font-size: 13px;
  background-color: #e2c55c;
  border-radius: 13px;
  color: white;
  text-align: center;
  margin-top: 15px;
  border: 1px solid #e5e5e5;
}
.button-blck {
  display: inline-flex;
  margin-right: 10px;
}
.button-grp {
  display: flex;
}
.group-status {
  padding: 3px;
  width: 40px;
  font-size: 13px;
  background-color: #469b88;
  border-radius: 13px;
  color: white;
  text-align: center;
  margin-top: 15px;
  border: 1px solid #e5e5e5;
}
.button-blck a {
  text-decoration: none;
}
.group-backgroud-color {
  background-color: white;
  padding: 10px 2px;
  border-radius: 10px;
  height: 100%;
}
.table-clients {
  margin-top: 30px;
  background-color: #469b88;
  display: flex;
  color: white;
  padding: 10px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bolder;
  justify-content: space-between;
}
.comn-btn:first-child {
  margin-left: 0px;
}

/* <--------------------------------------- Clients Table ---------------------------------------> */

.cursor-pointer {
  cursor: pointer;
}

.table-width {
  width: 20%;
}
.table-text-color {
  color: #6d6d6d !important;
  font-weight: 500;
  text-align: center;
}
.table-text-color svg path {
  stroke: #6d6d6d;
}
.profile-image {
  width: 45px;
  height: 45px;
}
.margin-amount h2 {
  margin-bottom: 0px;
  font-size: 45px;
}
.number-heading {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 5px;
}

.saving-acc-wrp .number-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0px;
  line-height: 40px;
  margin-bottom: 5px;
}

.profile-image {
  width: 45px;
  height: 45px;
}
.profile-table {
  display: flex;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
}
.profile-name {
  margin-top: 10px;
  margin-left: 30px;
  width: 20%;
}
.profile-open {
  margin-top: 10px;
  margin-left: 30px;
}
.profile-group {
  margin-top: 10px;
  margin-left: 30px;
}

/* <---------------------------------------------- Table -----------------------------------> */

table.rounded-corners {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
}

table.rounded-corners th,
table.rounded-corners td {
  border: 1px solid black;
}
.table.rounded-corners th {
  background: red;
}
.Company-head {
  font-size: 23px;
  display: inline-block;
}
.description.block {
  vertical-align: sub;
}
.total-top-group .air-new {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* <--------------------------------------------- Settings ---------------------------->*/

.setting_background_color {
  border-radius: 10px;
  padding: 15px 35px 15px 30px;
}

.setting_background_info_color {
  background: transparent;
}

.setting_text_color {
  color: rgba(144, 144, 144, 1);
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  margin-bottom: 0px;
  letter-spacing: -0.14px;
}
.setting_img {
  margin-left: 40px;
}
.input_border {
  height: 48px;
  font-size: 18px !important;
  line-height: 35px;
  border-radius: 10px !important;
  background-color: #fdfdfd !important;
}
.button_green {
  background-color: #469b88;
  width: 130px;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  color: white;
}
.button_white {
  background-color: #fdfdfd;
  width: 162px;
  height: 54px;
  border-radius: 10px;
  border: 1px solid #999;
  color: #999 !important;
}
.button {
  display: flex;
  justify-content: end;
  margin-right: 25px;
  margin-top: 50px;
}
.buttonEditBudget {
  display: flex;
  justify-content: end;
  margin-right: 25px;
}
.setting-img img {
  border-radius: 50%;
  height: 50px;
}
.setting-your-advisor {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  justify-content: space-between;
  padding: 10px 35px 10px 15px;
}
.setting-your-advisor-text {
  margin-left: 20px;
  font-size: 22px;
  font-weight: 800;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
}
.setting-your-advisor-view {
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.upload {
  position: relative;
  top: -50%;
}
.upload-logo {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.your-account-bal-add-cash .upload {
  top: 0%;
}

.chevron {
  position: relative;
}
.chevron-logo {
  position: absolute;
  top: 20px;
}
.input_border::placeholder {
  color: black !important;
  opacity: 1; /* Firefox */
}
.nav-link {
  padding: 7px 140px !important;
}
.nav {
  border: 1px solid #ced4da !important;
  border-radius: 10px;
  margin-right: 7px;
}
.nav {
  display: inline-flex;
  /* flex-wrap: wrap; */
  /* width: 100%; */
  padding-left: 0;
  margin-bottom: 0;
  /* list-style: none; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #469b88 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #469b88 !important;
}
.nav-link {
  color: black !important;
}
.table-image thead {
  background-color: #469b88;
  padding: 0px 20px;
}

.table-image thead tr {
  padding: 0px 20px;
}

.table-image thead th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px !important;
  text-align: start;
}
.group-image thead th:first-child {
  text-align: center !important;
}
.table-image thead th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* padding-right: 100px !important; */
}

/* -------------------------------Overlay Container------------------------- */

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  overflow: auto;
}

.locked {
  overflow: hidden;
}

.loading {
  display: flex;
  justify-content: center;
}
.col-md-10.col-lg-10.input-upload {
  position: relative;
}
/* .loading div {
  width: 1rem;
  height: 1rem;
  margin: 2rem 0.3rem;
  background: #001b49;
  border-radius: 50%;
  animation: 0.3s bounce infinite alternate;
}
.loading div:nth-child(2) {
  animation-delay: 0.1s;
  background-color: #008abe;
}
.loading div:nth-child(3) {
  animation-delay: 0.2s;
  background-color: #ffcb3d;
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
} */

.logo-class {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  height: 180px;
  margin: -60px 0 0 -60px;
  /* -webkit-animation: spin 0.4s linear infinite;
  -moz-animation: spin 0.4s linear infinite;
  animation: spin 0.4s linear infinite; */
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*--------------------------------- New cssss-------------------------- */

.add-new-group-table-wrp .table-image thead th:last-child,
.table-edit-group-page .table-image thead th:last-child {
  padding-right: 60px;
}
.account-number-dark-calendar {
  font-size: 15px;
  color: #40af66;
}

.first-table-edit-budget .table-image {
  position: relative;
}

.second-table-edit-budget .table-image {
  position: relative;
}
.regular-account-transfer-wrapper .table-image {
  position: relative;
}
.first-table-edit-budget select.planner.select-income {
  padding: 8px 33px;
}

.edit-cashflow-week-month-wrp .tabs-background-color {
  width: 100%;
}
.net-worth-main .planner {
  padding: 8px 0px;
  color: black;
  width: 126px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  /* overflow: auto; */
}
.net-worth-main .big-planner-new {
  color: black;
  width: 324px;

  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.net-worth-table .table-image,
.net-worth-table-second .table-image {
  position: relative;
}
.cashflow-calendar-wrp .edit-btn {
  width: 68% !important;
}

.income-exp-first-container {
  padding-bottom: 110px !important;
}

.net-worth-main .asset-wrp-first-side .net-worth-table .new-img,
.net-worth-main .liability-wrp-first-side .new-img {
  width: 190px;
}

/* Loader in the table body css */

.no-data-found-td {
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  font-size: 20px;
  background-color: #fff;
  color: #6d6d6d !important;
  font-weight: 600;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
}

.your-clients-list-table-wrapper .table-image {
  position: relative;
}

.your-clients-list-table-wrapper .table-image thead th:last-child {
  padding-right: 80px !important;
}

/* Loader in the table body css end */

.edit-delete-icon {
  display: flex;
  justify-content: space-between;
  padding: 5px 30px;
}
.edit-your-budget-main-wrapper .planner {
  padding: 8px 0px;
  color: black;
  width: 175px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
}
.edit-your-budget-main-wrapper .weekly-month-planner {
  padding: 5px 40px;
  text-align: center;
}

.main-front-dashboard-container .clients-with-share-rqst-wrapper td a {
  margin-left: 20%;
}
.main-front-dashboard-container .total-clients .text-client {
  line-height: 0px;
  font-size: 14px;
}
.fs-16 {
  font-size: 12px;
}

.text-14px {
  font-size: 14px;
}

.remove-goal-wrapp {
  display: flex;
  justify-content: center;
}

/* ----------Remove Goal button ------------- */

.delete-btn-edit {
  background-color: #c63838 !important;
  width: 170px !important;
  padding: 10px 20px !important;
  border: none;
  font-size: 16px;
  font-weight: normal;
  transition: 0.3s ease;
  border-radius: 10px;
  color: #fff !important;
  line-height: 20px;
}

.minus-symbol {
  margin-left: 10px;
  font-size: 16px;
}
.add-goal-paragraph {
  display: flex;
  margin-top: 50px;
  font-weight: 400;
}
.goal-grid-style {
  width: 76% !important;
}

/* ----------Remove Goal button end------------- */

/* -------Retirement planner-------------- */

.retirement-wrp-table-side th {
  color: #000 !important ;
  font-size: 20px !important;
  font-weight: 700;
  line-height: 26px;
}

.table-text-first {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #242424 !important;
}

.account-assets-column .account-assets-mini-title {
  font-size: 12px !important;
}
.box-wrp-15 {
  padding: 15px 12px 15px 12px !important;
}

.text-16px {
  font-size: 16px !important;
}

.nz-super-option-wrp .planner {
  background-size: 15px;
  font-size: 14px;
  width: 212px !important;
  height: 35px !important;
}

/* -------Retirement planner end-------------- */

.add-goals-btn {
  font-size: 18px !important;
  padding: 12px 30px !important;
  font-weight: 600 !important;
}
.setting-your-advisor:first-child {
  margin-top: 0px !important;
}

.edit-goal-box .edit-goal-house .edit-goal {
  top: 8px;
}

.edit-goal-box .edit-goal-house {
  font-weight: 500 !important;
}
.edit-goal-inner .edit-goal-house {
  width: 220px;
}
.edit-goal-inner {
  padding: 10px 55px 10px 16px !important;
}

.edit-goal-inner-personalised {
  padding: 10px 55px 10px 16px !important;
}

.edit-goal-inner-personalised .edit-goal-house {
  width: 185px;
}

.house-savings {
  width: 60% !important;
  margin-left: 15%;
}

.financial-goals-main .edit-btn-financial .edit-btn {
  width: 70%;
}
/* .button div {
  position: relative;
  margin-left: 20px;
} */
/* .button-new i {
  position: absolute;
  right: 16px;
  bottom: 33%;
  color: #fff;
} */
.positive-color {
  color: #53d258 !important;
}

.description-plan {
  /* width: 50%; */
  border: none;
  background-color: #fff;
  color: #6d6d6d !important;
  resize: none;
  /* text-align: center; */
  width: 200px;
  max-height: 40px;
  overflow-y: scroll;
  position: relative;
  left: 20%;
}
.read-more-cls {
}

.groceries-edit-table .table-image thead th:last-child {
  padding: 30px !important;
}

.common-btn-style {
  width: 162px;
  height: 54px !important;
}

/* Connect your accounts */

.re-sync-connection-section {
  display: inline-flex;
  align-items: center;
  justify-content: center !important;
}

.live-connection-main-wrapper .investment-connection-img {
  margin-right: 0px;
}

/* connect your accounts end */

/* Bar cart design */

.main-barcart-wrapper {
  position: relative;
}

.main-barcart-wrapper .title-legend-wrp {
  position: absolute;
  justify-content: space-between;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
}

.title-legend-wrp .income-exp-legend {
  display: flex;
  width: 240px;
  justify-content: space-between;
  align-items: center;
}

.title-legend-wrp .income-exp-legend h5 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.pointer-green {
  height: 12px;
  width: 12px;
  margin-top: 3px;
  border-radius: 100%;
  background-color: #469b88;
}

.pointer-red {
  height: 12px;
  width: 12px;
  margin-top: 3px;
  border-radius: 100%;
  background-color: #ff0a0a;
}

.main-barcart-wrapper .title-legend-wrp h3 {
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.main-barcart-wrapper .bar-cart-data-detail {
  position: absolute;
  display: flex;
  top: 50px;
  width: 270px;
  left: 19px;
  justify-content: space-between;
  align-items: center;
}

.bar-cart-data-detail .income-bar {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.bar-cart-data-detail .income-bar span {
  color: #469b88;
}

.bar-cart-data-detail .expense-bar {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.bar-cart-data-detail .expense-bar span {
  color: #ff0a0a;
}

/* bar cart design end */

/* .edit-group-page-main .table-image thead th:first-child {
  padding-left: 70px !important;
} */

.group-summary-page-main .total-clients .d-flex {
  align-items: center;
}
.group-summary-page-main .total-clients .selecet-box select {
  font-size: 16px;
}

/* .group-summary-page-main .table-image thead th:first-child {
  width: 23%;
} */
.group-summary-page-main .table-image thead th {
  background: #57469b;
}
.profile-img.setting-upld {
  margin-left: 18px;
}
.group-summary-page-table .table-text-color,
.group-summary-page-table .table-content {
  font-size: 18px;
}

.client-group-detail-page .total-clients {
  padding: 13px 28px;
}

.client-group-detail-page .share-number {
  color: #00634d !important;
}
.dark-green {
  color: #00634d;
}
.main-front-dashboard-container .share-number {
  color: #00634d !important;
}

.main-front-dashboard-container .total-clients .comn-btn {
  padding: 11px 12px !important;
}

/* .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image thead th {
    background: #469b88;
  } */
.main-table thead tr th:first-child {
  text-align: left;
}
.your-groups-main-front-wrapper .table-text-color {
  text-align: center;
  font-size: 18px;
}
.your-groups-main-front-wrapper .active-status {
  text-align: center;
}
.table-image .active-status {
  text-align: center;
}
.table-image .in-active-status-div {
  text-align: center;
}

.clients-with-share-rqst-wrapper .table-content,
.clients-with-share-rqst-wrapper .table-text-color {
  font-size: 18px;
}

.m-left {
  padding-left: 10px !important;
}

.your-clients-list-section-wrapper .your-clients-list-table-wrapper .air-image {
  margin-right: 0;
}

.your-groups-page-table-list .table-content {
  font-size: 18px;
}

/* .first-card-details {
  width: 27%;
} */

/* .edit-group-page-main .table-image thead th {
  padding: 10px 70px 10px 0px;
} */

.your-clients-list-table-wrapper .table-image thead th {
  background: #469b88;
}

.your-groups-page-table-list .table-image thead th {
  font-weight: 700;
}
.your-groups-page-table-list .table-image thead th:last-child {
  padding-right: 60px;
}
.your-groups-page-table-list .table-image {
  position: relative;
}

.main-page-details-settings .your-payment-methods-wrp .nav-link {
  padding: 7px 175px !important;
}

.main-front-dashboard-container .top-jeremy-heading {
  font-size: 32px !important;
}

.client-group-detail-page .total-clients .comn-btn {
  padding: 11px 15px !important;
}
.client-group-detail-page .total-clients .amount-number {
  font-size: 32px;
  font-weight: 500 !important;
}

.client-group-detail-page .margin-amount h2 {
  font-size: 45px;
}

.comn-btn .plus-icon-style {
  font-weight: 500 !important;
}

.comn-btn .minus-icon-style {
  font-weight: 600 !important;
}

/* .common-btn-style {
  padding: 15px 25px !important;
} */

.sub-head-text {
  font-size: 20px;
  /* font-weight: bolder; */
}

.big-top-heading {
  font-size: 32px !important;
  color: #0e132f;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.32px;
}

.second-num-head {
  font-size: 45px;
  font-weight: 600 !important;
}
.acc-num-head {
  font-size: 32px !important;
}
.inactive-div {
  padding: 4px 16px !important;
}
.active-div {
  padding: 4px 16px !important;
}
.activated-div {
  padding: 4px 16px !important;
}

.group-name-tab {
  font-size: 24px;
  font-weight: bold;
}

.main-front-dashboard-container .heading-main {
  font-size: 20px;
  font-weight: 500;
  line-height: 34px; /* 170% */
  letter-spacing: -0.2px;
  color: #0e132f;
}

.ranked-heading {
  font-size: 20px;
}

.edit-heading {
  font-size: 32px !important;
}
.edit-group-page-main .upload-csv-file .profile-img img {
  width: 50px;
}

.edit-group-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.grocery-edit-main .edit-your-budget .edit-btn {
  width: 70%;
}

.grocery-edit-main .back-to-portal-grocery .profile-dashboard h3 {
  font-size: 20px;
  font-weight: 500;
}

.grocery-edit-main .grocer-edit .blue-color {
  color: rgba(72, 159, 205, 1);
}

.grocery-edit-main .grocer-edit .name-merchant h5 {
  font-size: 16px;
  font-weight: bolder !important;
}

.number-share-10 {
  font-size: 26px !important;
}
.group-summary-page-main .amount-active {
  padding: 5px 16px;
  font-size: 13px;
}

.group-summary-page-main .amount-inactive,
.amount-activated {
  padding: 5px 16px;
  font-size: 13px;
}
.group-summary-page-main .amount-activated {
  padding: 7px 16px;
  font-size: 13px;
}

/* Account balances */

.account-bal-title {
  font-size: 20px;
}

.number-account {
  color: #707070;
  font-size: 14px;
}
.account-number-dark {
  color: #40af66 !important;
  font-size: 18px;
}

.account-number-dark-red {
  color: #ff4f79 !important;
  font-size: 18px;
  font-weight: bolder;
}

.your-account-bal-add-cash .small-account input {
  width: 148px;
}

/* Account balances end */

.veteran-pension-payment-second .retirement-planner-wrp {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.retirement-section-2-right-wrp .retirement-planner-wrp {
  display: flex;
  align-items: center;
}

.retirement-section-2-right-wrp .retirement-planner-text {
  font-size: 18px !important;
}

/* .contribution-right-side h2 {
  display: flex;
  align-items: center;
  padding-left: 50px;
} */

.second-line {
  font-size: 16px !important;
  margin-bottom: 65px;
}
.account-assets-total-amt .retirement-planner-wrp {
  display: flex;
  align-items: center;
}

/* Dashboard inner chart */

.income-exp-mnth-chart-edit {
  position: absolute;
  top: 17px;
  left: 17px;
}
.income-exp-bar-chart {
  position: relative;
}
.bar-chart-heading-h3 {
  font-size: 18px;
}
.bar-chart-heading-h4 {
  font-size: 16px;
}

.green-text {
  font-size: 16px;
  color: #469b88;
  margin-right: 20px;
}
.red-dark-text {
  color: #ff0a0a;
  font-size: 16px;
}

/* Dashboard inner chart end */

/* Your merchants */

.filter-merchants-wrp-side .group-btn {
  padding: 10px 0px;
}
/* Your merchants end */

/* Your transaction wrapper */

.filter-trans {
  color: #242424;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.filter-transaction-wrapper .group-backgroud-color {
  padding: 15px 15px 0px 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.filter-transaction-wrapper .group-btn {
  padding: 4px 0px;
}

.your-transaction-main-container .transactions-text {
  font-size: 16px;
  font-weight: 600;
}
.your-transaction-main-container .big-text-transaction {
  font-size: 18px;
  font-weight: 600;
}

.your-transaction-main-container .transactions-table {
  align-items: center;
}
.your-transaction-main-container .transactions-table .pending {
  padding: 6px 21px 7px;
  border-radius: 20px;
}

.new-light-red {
  color: #ff0c0c;
}

.light-red-trans {
  color: #e25c5c;
}
.light-green-trans {
  color: #0bba00;
}
.select-transaction .transactions-select {
  height: 33px;
  color: #707070 !important;
}

/* Your transaction wrapper end */

/* Your financial goals */

.financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
  padding: 10px 63px !important;
  font-weight: 500 !important;
}

.financial-goals-main .back-btn-financial .edit-btn {
  padding: 15px 25px;
}

.financial-goals-main .edit-goal-box .personalised-color svg {
  top: 12px;
}
/* Your financial goals end */

/* Edit your goal budget */
.edit-goal-budget-wrapper .table-image thead th:first-child {
  padding-left: 10px !important;
}

.edit-goal-budget-wrapper .table-image thead th:last-child {
  padding-right: 30px !important;
}

/* .grocery-edit-main .edit-your-budget .edit-btn {
  width: 70% !important;
} */

.back-to-budget-summ {
  font-size: 18px;
  font-weight: 700;
}

.mt-6-new {
  margin-top: 5rem !important;
}
.personalise-edit .back-section {
  padding: 15px !important;
}

/* edit your goal budget */

/* Edit your budget page css new */

/* .edit-your-budget-main-wrapper .top-head {
  color: #0e132f;
  font-size: 32px !important;
  font-weight: 800 !important;
  line-height: 34px;
  letter-spacing: -0.32px;
} */

/* .edit-your-budget-main-wrapper .edit-btn {
  width: 70%;
} */
.new-btn svg path {
  stroke: #fff;
}
.new-finance {
  justify-content: space-between;
  align-items: center;
}

/* .edit-your-budget-main-wrapper .semi-heading-edit-budget {
  color: #242424;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 28.6px;
} */
.edit-table thead th:first-child {
  padding-left: 0px !important;
}
.edit-table tbody th:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.edit-table tbody th:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.edit-table tbody td select.planner {
  background-size: 18px !important;
}
.edit-your-budget-main-wrapper .top-head-section p {
  font-size: 14px;
  line-height: 34px;
  margin-bottom: 0px;
  color: #242424;
}

.edit-your-budget-main-wrapper .planner-for-weekly .planner {
  width: 100% !important;
  color: #000;
  background-position: 200px 9px;
  background-color: #fff !important;
}

.edit-your-budget-main-wrapper .last-wrp-account-transfer .planner {
  width: 100% !important;
  background-color: #fff !important;
}

.edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .last-wrp-account-transfer {
  width: 100%;
}
.edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .last-wrp-account-transfer select {
  text-align: end;
  padding-right: 50px;
}

/* .edit-your-budget-main-wrapper .first-table-edit-budget .table-image thead th:last-child {
  padding-right: 120px;
} */

/* Edit your budget page css end new*/

/* Add a new group page css */

/* .add-new-group-main-section .main-heading-new-group h2 {
  color: #0e132f;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.32px;
} */

.add-new-group-main-section .second-heading-group {
  color: #0e132f;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.32px;
}
.upload-file-here {
  font-size: 22px;
  font-weight: 800;
}

.add-new-group-main-section .small-heading {
  font-size: 16px;
}
.add-new-group-main-section .save-your-detail-btns .button_white {
  border: 1px solid #999;
}

.w-236 {
  width: 236px;
}

/* Add a new group page css end */

/* Edit summary Page */

.client-summary-page-main .client-name {
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.32px;
  color: #0e132f;
}
.client-summary-page-main .share-status-wrp {
  justify-content: center !important;
}

.client-summary-page-main .top-wrapper-client-summary .profile-dashboard img {
  width: 80px;
  height: 80px;
}

.client-summary-page-main .profile-dashboard .active-btn {
  padding: 2px 15px;
}

.client-summary-page-main .financial .view-financials {
  padding-right: 30px;
}

.client-summary-page-main .card-title-client {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.2px;
}

.client-summary-page-main .card-inner-text {
  font-size: 16px;
  color: #263238;
}

.client-summary-page-main .total-clients .air-new .air-image img {
  width: 48px;
  height: 48px;
  /* margin-bottom: 10px; */
}
.client-summary-page-main .total-clients .air-new-zealand {
  color: #020202;
  font-size: 24px;
  font-weight: 600 !important;
  line-height: 34px;
  letter-spacing: -0.24px;
  padding-top: 3px;
  margin-bottom: 10px;
}

.annual-pro-plan {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.2px;
  margin-bottom: 10px;
}

.renews-plan {
  font-size: 25px;
  font-weight: 600;
}

.client-summary-page-main .total-clients .pro-plan .air-new,
.big-new-heading {
  color: #020202;
  font-size: 38px;
  font-weight: 500 !important;
  line-height: 34px;
  letter-spacing: -0.38px;
  margin-top: 15px;
}

.client-summary-page-main .share-number-open {
  border-radius: 50px;
  text-align: center;
  background: #d5f1de;
  padding: 3px 6px 10px 6px;
  font-weight: bold;
  margin-top: 22px;
  font-size: 26px;
  color: #00634d;
}

.client-summary-page-main .reports-title {
  color: #0e132f;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.3px;
}

/* .client-summary-page-main .report-card-title {
  color: #263238;
  font-size: 18px !important;
  font-weight: 700;
} */

.annual-pro-plan {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.2px;
  margin-bottom: 10px;
}

.renews-plan {
  font-size: 25px;
}

.client-summary-page-main .total-clients .pro-plan .air-new {
  color: #020202;
  font-size: 38px;
  font-weight: 500 !important;
  line-height: 34px;
  letter-spacing: -0.38px;
  margin-top: 15px;
}

.client-summary-page-main .share-number-open {
  border-radius: 50px;
  text-align: center;
  background: #d5f1de;
  padding: 6px;

  font-weight: bold;
  margin-top: 22px;
  font-size: 26px;
  color: #00634d;
}

.client-summary-page-main .reports-title {
  color: #0e132f;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: -0.3px;
}

.client-summary-page-main .report-card-title {
  color: #263238;
  font-size: 18px !important;
  font-weight: 700;
}

.client-summary-page-main .report-card .setting_text_color {
  font-size: 14px !important;
}
.client-summary-page-main .col-md-4.weekly-wrp {
  display: flex;
  justify-content: flex-end;
}

.client-summary-page-main .report-card .slecet-wrp select {
  font-size: 16px !important;
  width: 100% !important;
  color: #263238;
}

/* Edit summary Page end */

.client-dashboard-main-wrapper .net-worth-progress-side .linecartbottom canvas.barcart {
  height: 425px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
  margin-top: 22px;
}

.client-dashboard-main-wrapper .total-balances-card-wrp .big-head {
  font-weight: bold;
}

.client-dashboard-main-wrapper .total-balances-card-wrp .right h4 {
  font-size: 15px;
}

.client-dashboard-main-wrapper .income-exp-mnth h5,
.client-dashboard-main-wrapper .spend-budget h5,
.client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all h5,
.client-dashboard-main-wrapper .weekly-cahflowcart h5,
.client-dashboard-main-wrapper .net-worth-progress-side h5,
.client-dashboard-main-wrapper .account-balances-side h5 {
  font-size: 20px;
}

.client-dashboard-main-wrapper .recent-transaction-wrapper,
.client-dashboard-main-wrapper .weekly-cahflowcart {
  margin-bottom: 30px;
}

.your-clients-list-section-wrapper .group-backgroud-color .group-button {
  height: 100% !important;
}

.edit-client-details-page .back-to-dashboard a {
  color: #989898 !important;
  font-size: 16px;
}

.main-heading-title-group {
  font-size: 30px !important;
}

.error-message {
  color: red;
  font-size: 14px;
}

.select-csv-file-net {
  cursor: pointer;
}

/* .edit-client-details-page .edit-clients-buttons .button_white {
  padding: 10px 25px !important;
} */

.edit-client-details-page .error-message {
  color: red;
  font-size: 14px;
}

.your-clients-list-section-wrapper .plan-section-area .dark-yellow {
  border: 1px solid #dd7802;
  background: #dd7802;
  color: #fff !important;
}

.dark-yellow {
  border: 1px solid #dd7802;
  background: #dd7802;
  color: #fff !important;
}

.your-groups-main-front-wrapper .filter-clients-top-wrapper .group-backgroud-color {
  padding: 10px 10px !important;
}

.your-groups-main-front-wrapper .your-groups-page-table-list .table-image thead th:first-child {
  padding: 10px 10px 10px 25px !important;
  text-align: start;
  width: 22%;
}

/* .your-clients-list-section-wrapper .group-backgroud-color .group-btn {
  padding: 10px;
} */

.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image thead th:first-child {
  /* padding: 10px 10px 10px 25px !important; */
  text-align: start;
  /* width: 27%; */
}

.edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.clients-with-share-rqst-wrapper .table-horizontal .table-image .cursor-pointer td {
  padding: 15px 10px;
  text-align: center;
}

.groceries-edit-table .table-image thead th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 5px !important;
}

.groceries-edit-table .air-new {
  margin-left: 5px;
}

.groceries-edit-table select.planner {
  padding: 8px 25px;
  color: #000;
  background-size: 18px;
  background-position: right 30px center;
}

.grocery-edit-main .grocery-edit-recent-trans .sideblck {
  position: relative !important;
}
.grocery-edit-main .grocery-edit-recent-trans .sideblck .transaction-amt {
  position: absolute !important ;
  right: 3% !important;
}

.grocery-edit-main .grocery-edit-recent-trans .sideblck .status-btn {
  padding-right: 22% !important;
}

/* Net worth */

.net-worth-main .line-cart-net canvas.barcart {
  margin-top: 0;
  height: 330px !important;
}

.net-worth-main .setting_text_color {
  color: #565a6d !important;
  font-size: 15px;
}
.net-worth-main .line-cart-net .bar-cart-heading-wrp {
  position: absolute;
  top: 20px;
  left: 50px;
}
.net-worth-main .line-cart-net .bar-cart-heading-wrp h4 {
  font-weight: 700;
  font-size: 20px;
}
.net-worth-main .line-cart-net .bar-cart-heading-wrp h6 {
  font-size: 14px;
  color: #607d8b;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.net-worth-main .net-worth-line-cart-canvas {
  position: relative;
}

.net-worth-main .net-worth-line-cart-canvas .bar-cart-heading-wrp .top-heading-canvas-price {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px 0px 0px;
}
.net-worth-main .net-worth-line-cart-canvas .bar-cart-heading-wrp .top-heading-canvas-price h5 {
  font-size: 20px;
  color: #4ec376;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.44px;
}

.net-worth-main .back-portal-btn .comn-btn {
  font-size: 18px !important;
}
.net-worth-main .back-portal-btn .profile-dashboard .comn-btn img {
  width: 30px !important;
}
.net-worth-main .back-portal-btn .profile-dashboard .profile-img img {
  width: 50px;
}

.net-worth-main .med-new-font .new-green {
  color: #40af66;
}

.comn-info-btn {
  width: 318px;
  background-color: #469b88;
  border-radius: 15px;
  border: none;
  color: #fff;
  padding: 10px;
  font-size: 14px;
}

.net-worth-main .assets-wrp h5 {
  font-size: 28px !important;
  font-weight: 800 !important;
  line-height: 28px;
  color: #242424 !important;
}
.net-worth-main .liability-wrp h5 {
  font-size: 28px !important;
  font-weight: 800 !important;
  line-height: 28px;
  color: #242424 !important;
}
.profile-dashboard .comn-btn {
  /* width: 188px;
  padding: 16px 20px; */
}

.net-worth-main .assets-wrp .net-worth-table .table-image thead th {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
}

.net-worth-main .asset-wrp-second-side .add-asset-btn-first .comn-btn {
  font-weight: bold !important;
}

.net-worth-main .asset-wrp-second-side {
  margin-top: 70px;
  padding-bottom: 10px;
}
.net-worth-main .liability-wrp-second-side {
  margin-top: 65px;
  padding-bottom: 85px;
}

.net-worth-main .asset-wrp-second-side .saving-head h4,
.net-worth-main .liability-wrp-second-side .saving-head h4 {
  font-size: 16px !important;
}

.net-worth-main .assets-wrp .asset-wrp-second-side .light-green-new {
  color: #00aa84;
}

.net-worth-main .assets-wrp .asset-wrp-second-side .this-week {
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
}

.net-worth-main .liability-wrp .liability-wrp-second-side .this-week {
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
}
.net-worth-main .liability-wrp .liability-wrp-second-side .this-week span {
  font-weight: 500;
}

.net-worth-main .assets-wrp .asset-wrp-second-side .this-week span {
  font-weight: 500;
}

.net-worth-main .liability-wrp {
  margin-top: 80px;
}

.net-worth-main .liability-wrp h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.net-worth-main .liability-wrp .net-worth-table-second .table-image thead th {
  font-size: 20px;
  font-weight: 600;
}

.net-worth-main .liability-wrp .left-section .button-assets-text {
  color: #d04f4f;
}

.net-worth-main .big-head-new {
  font-size: 34px !important;
  font-weight: 700;
}

/* net worth end */

.financial-goals-main .back-to-portal-financial {
  align-items: baseline;
}

.your-clients-list-section-wrapper .top-heading-wrp-main .your-clients-title {
  font-size: 30px !important;
  font-weight: 700 !important;
}
/* .your-clients-list-section-wrapper .top-heading-wrp-main .setting_text_color {
  color: #565a6d !important;
} */

.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image .cursor-pointer h3 {
  font-weight: 500 !important;
  width: 100px;
}
.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image .cursor-pointer td {
  font-weight: 500 !important;
  text-align: center;
}

.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-content,
.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-text-color {
  font-size: 18px;
}

/* Dashboard innner ccss */

.client-dashboard-main-wrapper .weekly-cahflowcart .line-cart-net .bar-cart-heading-wrp {
  position: absolute;
  top: 65px;
  left: 35px;
}
.client-dashboard-main-wrapper .weekly-cahflowcart .line-cart-net .bar-cart-heading-wrp h4 {
  font-weight: 700;
  font-size: 20px;
}
.client-dashboard-main-wrapper .weekly-cahflowcart .line-cart-net .bar-cart-heading-wrp h6 {
  font-size: 14px;
  color: #607d8b;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.client-dashboard-main-wrapper .weekly-cahflowcart {
  position: relative;
}

.client-dashboard-main-wrapper .weekly-cahflowcart .bar-cart-heading-wrp .top-heading-canvas-price {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px 0px 0px;
}
.client-dashboard-main-wrapper .weekly-cahflowcart .bar-cart-heading-wrp .top-heading-canvas-price h5 {
  font-size: 20px;
  color: #4ec376;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.44px;
}

.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
  position: relative !important;
  padding: 23px 13px;
}
.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .merchnat-img {
  width: 230px;
}
.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h5 {
  font-size: 16px !important;
}
.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h6 {
  font-size: 14px;
}

.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .status-btn {
  width: 40%;
}

.client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .transaction-amt {
  position: absolute !important;
  right: 4% !important;
  font-weight: 700;
}

.net-worth-progress-side .bar-cart-heading-wrp {
  position: absolute;
  top: 60px;
  left: 40px;
}
.net-worth-progress-side .bar-cart-heading-wrp h4 {
  font-weight: 700;
  font-size: 20px;
}
.net-worth-progress-side .bar-cart-heading-wrp h6 {
  font-size: 14px;
  color: #607d8b;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.net-worth-progress-side {
  position: relative;
}

.net-worth-progress-side .bar-cart-heading-wrp .top-heading-canvas-price {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px 0px 0px;
}
.net-worth-progress-side .bar-cart-heading-wrp .top-heading-canvas-price h5 {
  font-size: 20px;
  color: #4ec376;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.44px;
}

.fw-bold-600 {
  font-weight: 600 !important;
}

/* -------------------------------New css end------------------------------------- */

.table-image thead th {
  padding: 10px 0px;
  color: #fff;
  font-size: 22px;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 700;
  text-align: center;
}
.table-content {
  color: #767676;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 20px;
  margin-left: 10px;
  font-weight: 500;
}
.table-image .air-image {
  display: flex;
  align-items: center;
}
.table-image .air-image button {
  margin-top: 0px;
  margin-left: 25px;
  color: #469b88;
  border: none;
}
table.table-image {
  width: 100%;
}
table {
  border-spacing: 0 10px;
  border-collapse: separate !important;
}
.table-image tbody tr {
  background-color: #ffff;
}
.table-image tbody th:first-child {
  padding-left: 20px;
  padding-top: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-bottom: 10px;
}
.table-image tbody td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 15px;
}
.table-image tbody tr:nth-child(n) {
  background-color: #ffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
}
.space-table {
  border-spacing: 0px 15px;
  border-collapse: separate;
}
button.active-btn {
  border: 1px solid #b7b8c1;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff;
  padding: 5px 15px;
}
.active-btn i {
  margin-right: 15px;
}
.active-btn i.fa-solid.fa-circle {
  color: #4ec376;
  font-size: 15px;
  line-height: 20px;
}

button.inactive-button {
  border: 1px solid #b7b8c1;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff;
  padding: 5px 15px;
}
.inactive-button i {
  margin-right: 15px;
}
.inactive-button i.fa-solid.fa-circle {
  color: #ff0a0a;
  font-size: 15px;
  line-height: 20px;
}
button.not-inactive-button {
  border: 1px solid #b7b8c1;
  border-radius: 10px;
  font-size: 13px;
  background-color: #fff;
  padding: 5px 11px;
}
.not-inactive-button i {
  margin-right: 10px;
}
.not-inactive-button i.fa-solid.fa-circle {
  color: #fa7500;
  font-size: 15px;
  line-height: 20px;
}
/* td a {
  margin-left: 20%;
} */
/* thead th:nth-child(6) {
  width: 25%;
} */
.text-merchant {
  font-size: 16px;
  line-height: 23px;
}
.account-box {
  font-size: 16px;
  line-height: 23px;
  display: inline-flex;
  align-items: center;
}

.account-balance {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 4px;
  margin-right: 15px;
}
input.search {
  background: #e1e1e1;
  border: none;
  padding: 12px 45px 12px 60px;
  border-radius: 10px;
  width: 100%;
}
.search-block {
  position: relative;
}
.search-block svg {
  position: absolute;
  top: 26%;
  left: 3%;
}
.row.group-wrp {
  align-items: center;
}
.group-button:nth-child(6n) {
  margin-left: 0px !important;
}
.group-backgroud-color .fw-bolder {
  margin-bottom: 15px;
}
.new-yellow {
  color: #fff !important;
  background-color: #e2c55c;
}
.green {
  color: #fff !important;
  background-color: #469b88;
}
.button-blck a:hover {
  color: #fff !important;
  background-color: #57469b;
}
.yellow-btn a:hover {
  color: #fff !important;
  background-color: #dd7802;
}
.green-btn a:hover {
  color: #fff !important;
  background-color: #469b88;
}
.navbar__link {
  color: #767676 !important;
}
a.navbar__link.active {
  background-color: #469b88;
  color: #fff !important;
}

#purple-active.active {
  background-color: #57469b !important;
  color: #fff !important;
}

#purple-active.active::before {
  background-color: #57469b !important;
}

.navbar__link.active::before {
  opacity: 1;
}
a.navbar__link.active svg path {
  stroke: #fff;
}

/*<------------------------------------------- Edit a Client Details ----------------------------> */

.client-button {
  margin-right: 0px !important;
}

.delete-btn {
  background-color: #c63838 !important;
}
.small-heading {
  font-size: 16px;
  line-height: 25px;
  color: #989898;
  margin-bottom: 0px;
}
.grey-color {
  color: #989898;
}
.small-heading i {
  margin-right: 10px;
}
.profile-dashboard .comn-btn img {
  width: 18px;
  margin-left: 15px;
}
.profile-dashboard {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.profile-dashboard select {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.14px;
}

.profile-dashboard:first-child {
  margin-left: 0px;
  color: #263238;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.2px;
}

/* <--------------------------------------- Active Page ------------------------------------------> */

.view-financials {
  padding: 10px;
  background-color: #469b88;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  padding-left: 15px;
}
.col-md-6.mt-3.financial {
  display: inline-flex;
  justify-content: end;
  align-items: center;
  padding-right: 45px;
}
.pro-plan {
  width: 100%;
}
.plan-date {
  font-size: 19px;
  background-color: #d5f1de;
  border-radius: 21px;
  padding: 5px 8px 0px 8px;
  margin-left: 8px;
  height: 40px;
}
.report-card {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  padding-right: 25px;
}
.report-text-color {
  color: #767676;
}
.col-md-4.weekly-wrp {
  text-align: right;
}
.slecet-wrp select {
  border: none;
  margin-right: 0px;
  width: 75%;
  vertical-align: middle;
  font-size: 13px;
  color: #767676;
}
.plan-wrp {
  justify-content: flex-end;
}
.group-table td a {
  /* margin-left: 35px; */
}
.group-table .form-control {
  width: 90% !important;
  display: inline-flex;
}
.comn-field {
  width: 295px !important;
}
.group-table .input-group {
  display: unset;
}
.amount-active i {
  margin-left: 10px;
}

/* <------------------------------------- Main SIdebar css --------------------------------->*/

.mainsidebar .app-sidebar {
  width: 95px;
  min-width: 95px;
  height: 100vh;
  position: unset;
  position: relative;
  flex: unset;
  padding: 30px 17px;
  left: -11px;
}
.mainsidebar .vertical-nav-menu a {
  width: unset;
  display: inline-flex;
  padding: 10px;
}
.mainsidebar .vertical-nav-menu a::before {
  display: none;
}
.mainsidebar .navbar__link.active {
  background-color: #469b884c;
  color: #fff !important;
}
.sidebarnew .mainsidebar a.navbar-brand {
  display: block;
}
.sidebarnew .mainsidebar .app-sidebar {
  width: 20%;
}
.sidebarnew .only-logo img {
  display: none;
}
.sidebarnew .vertical-nav-menu a:hover {
  background-color: green;
}
.mainsidebar a.navbar-brand {
  display: none;
}
.mainsidebar .scrollbar-sidebar {
  align-items: unset;
  justify-content: unset;
}
.mainsidebar .vertical-nav-menu li {
  text-align: center;
  margin: 20px 0px;
}
.mainsidebar .vertical-nav-menu svg {
  margin-right: 0px;
  width: 25px;
  height: 25px;
}
.main-wrapper .app-main__outer {
  padding-left: 25px;
  padding-top: 20px;
  overflow-x: hidden;
  padding-right: 30px;
}
.profile-dashboard h3 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.22px;
  margin-bottom: 0px;
}

/* <------------------------------------------ Detail Page ----------------------------------> */

.selecet-box select {
  border: none;
  margin-right: 0px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  width: 100% !important;
  /* margin-right: 33px !important; */
}
/* .main-wrapper .profile-dashboard img {
  width: 50px;
} */
/* .col-md-6.right-section {
  align-items: center;
} */
.profile-dashboard .comn-btn i {
  margin-right: 10px;
}
.total-balance-wrp {
  background-color: #001b49;
  padding: 20px;
  display: grid;
  border-radius: 15px;
  background-image: url('../images/icons/sidedesign.png');
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
  /* height: 110%; */
  z-index: 0;
  overflow: hidden;
  align-items: center;
}
.total-balance-wrp::before {
  content: '';
  position: absolute;
  background-color: #469b88;
  height: 93px;
  width: 90px;
  border-radius: 50%;
  bottom: -27px;
  left: -31px;
  z-index: -1;
}
.med-head {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.big-head {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  line-height: 50px;
}

/* <--------------------------------------------- Merchants Pages ----------------------------> */

.merchants-background-color {
  background-color: white;
  padding: 23px 25px;
  border-radius: 10px;
}
.search-merchant {
  position: relative !important;
  margin-top: 5px;
}
.search-merchant svg {
  position: absolute !important;
  top: 13% !important;
  right: 12% !important;
  padding: 3px;
}
.your-merchant-search svg {
  right: 2% !important;
}

.search-color-white {
  background-color: #fff !important;
  padding: 10px 15px 10px 15px !important;
}
.col-md-7.img-sec {
  vertical-align: middle;
  display: inline-flex;
  align-items: end;
}
.img {
  width: 35px;
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  justify-content: center;
}
.right h4 {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0px;
}
.col-md-5.right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.right svg {
  padding: 5px 0px;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
}
.flex {
  display: flex;
}
.merchant-box-parent {
  display: flex;
  width: 50%;
  justify-content: space-between;
  float: left;
  padding: 5px;
}
.merchant-box {
  display: flex;
  background-color: #fff;
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}
.big-head-new {
  font-size: 32px !important;
  line-height: 34px;
  font-weight: 500;
  /* text-transform: capitalize; */
  color: #0e132f;
  margin-bottom: 0px;
  letter-spacing: -0.32px;
  text-transform: capitalize !important;
}
.top-head .big-head-new {
  font-weight: normal;
}

.light-grey {
  color: #565a6d;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.14px;
}
.small-text-merchant {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  display: flex;
}
.number-merchant {
  color: #40af66;
  font-weight: 700;
  margin-right: 5px;
}

.space {
  margin-left: 5px;
}
.select-category {
  width: 55% !important;
}
.Un-categorised .select-category {
  width: 35% !important;
}
.top-wrp {
  display: flex;
  justify-content: space-between;
}
.main-wrapper .percentage {
  margin-left: 22%;
  color: #00634d;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.5px;
  letter-spacing: -0.12px;
}
.bar-cart h5 {
  margin-bottom: 0px;
  color: #242424;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22.4px;
}
canvas.barcart {
  background-color: #fff;
  border-radius: 18px;
  width: 100% !important;
  height: 400px !important;
  /* padding: 50px 25px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 20px;
}
.bar-cart .left,
.bar-cart .right {
  display: inline-flex;
}
select.form-select {
  border: none;
  text-align: left;
  height: 48px;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
  color: #000 !important;
  border: 1px solid #ced4da !important;
}

.img-box {
  height: 45px;
  width: 45px;
  display: inline-block;
}
.categorised {
  background-color: #469b88;
  border-radius: 10px;
  padding: 16px 41px 15px 40px;
}
.uncategorised {
  background-color: #e25454;
  border-radius: 10px;
  padding: 16px 41px 16px 40px;
}
.categorised-icon {
  background-color: #4fdbbc;
  width: 50px;
  padding: 10px 15px;
  border-radius: 50%;
  height: 50px;
  margin-left: 50px;
  margin-left: auto;
  margin-right: auto;
}
.category-text {
  font-size: 18px;
  line-height: 33px;
  font-weight: 400;
}

.uncategorised-icon {
  background-color: #fdb8b8;
  width: 50px;
  padding: 10px 15px;
  border-radius: 50%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}
.bar-cart select {
  background-color: unset;
}

/* <---------------------------------------- Merchants Details Page ------------------------------- >*/

.count-number {
  color: #40af66;
  font-size: 15px;
  font-weight: bold;
}
.Count-down-img {
  width: 10% !important;
}
.merchats-detail-box {
  margin-top: 95px !important;
  text-align: center;
}
.merchats-detail-select {
  width: 18% !important;
  margin-left: auto;
  margin-right: auto;
}
.merchats-select {
  border: none;
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  color: #000 !important;
  border: 1px solid #ced4da !important;
  text-align: center !important;
  margin-top: 10px;
}
.merchant-wrp img.count-down-img {
  width: 30%;
}
.your-spend-box {
  background-color: #fff;
  padding: 16px 41px 16px 36px;
  text-align: center;
  border-radius: 10px;
}
.this-week {
  font-size: 14px;
  color: #000;
  margin-top: 5px;
  font-weight: 600;
  line-height: 20px;
}
.new-table .air-image.new-img {
  display: flex !important;
}
.this-week-number {
  font-size: 16px;
  color: #e25454;
  margin-top: 5px;
  line-height: 23px;
  font-weight: bold;
}
select.form-select.merchats-select {
  background-color: #fff !important;
}
.heading-new {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0px;
}
.small-icons-camera {
  position: absolute;
  top: 0%;
  background-color: #469b88;
  right: 4%;
  padding: 10px 11px;
  display: flex;
  border: 6px solid #fff;
  border-radius: 50%;
}
.merchant-wrp {
  display: inline-flex;
  position: relative;
  justify-content: center;
}
.big-head-select select {
  font-size: 20px;
  line-height: 25px;
  height: 45px;
  border: none !important;
}

/* <------------------------------------------ Your Retirement Planner Page ------------------------------------------> */

.retirement-planner {
  background-color: #fff;
  padding: 8px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.planner {
  background-color: #efeded !important;
  padding: 10px 70px;
  border-radius: 10px;
  height: 35px;
  display: initial;
  align-items: center;
}
.budgetIncome {
  padding: 8px 0px;
  color: black;
  width: 175px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  border-radius: 30px;
  background-color: #c537c8 !important;
  color: white !important;
}
.budgetHowOften {
  padding: 8px 0px;
  color: black;
  width: 175px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  border-radius: 30px;
  background-color: #469b88 !important;
  color: white !important;
}
.expenseHowOften {
  padding: 8px 0px;
  color: black;
  width: 175px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  border-radius: 30px;
  background-color: #d04f4f !important;
  color: white !important;
}
.one-off-budget {
  width: 100% !important;
  padding: 20px;
  display: inline-flex;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.oneOffBudgetColour {
  padding: 8px 0px;
  color: black;
  width: 175px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  border-radius: 30px;
  color: white !important;
}
.retirement-wrp-table-side td.table-text-color {
  height: 60px;
  padding: 20px 68px !important;
}
.retirement-wrp-table-side .planner {
  padding: 10px 120px !important;
}

.add-account-asset-btn {
  padding: 10px 20px !important;
  font-weight: 600 !important;
}

.retirement-planner-wrp {
  background-color: #001b49;
  padding: 38px 30px;
  display: flex;
  position: relative;
  margin-left: 20px;
  border-radius: 15px;
  background-image: url('../images/icons/sidedesign.png');
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: top right;
  height: 100%;
  z-index: 0;
  flex-direction: column;
  overflow: hidden;
  align-items: flex-start;
}
/* .account-assets-total-amt .amt-now {
  align-items: center !important;
} */
.main-wrp-retirement {
  margin-right: 170px;
}
.retirement-planner-wrp:before {
  content: '';
  position: absolute;
  background-color: #469b88;
  height: 93px;
  width: 90px;
  border-radius: 50%;
  bottom: -33px;
  left: -45px;
  z-index: -1;
}
.retirement-bg {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;
}

.retirement-planner-text {
  font-size: 20px;
  line-height: 25px;
  font-weight: normal;
  color: #fff;
}
.retirement-planner-wrp h3 {
  font-size: 30px;
  line-height: 35px;
}
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}
.blue-new {
  color: #489fcd;
}
.rotate {
  background-color: rgb(78, 196, 118);
  transform: rotate(180deg);
  border-radius: 50%;
}
.rotate path {
  stroke: #fff;
}

.rotate rect {
  fill: transparent !important;
}
/* .kiwisaver-img {
  width: 65% !important;
} */
.acc-image {
  width: 40px;
}
.your-contribution-box {
  background-color: #fff;
  padding: 14px 22px 14px 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.your-contribution {
  display: flex;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;
}
.you-income {
  padding: 8px 30px !important;
  font-weight: 500 !important;
}
.you-income-text {
  font-size: 14px !important;
}
.big-width {
  width: 223px !important;
}

.veteran-pension-payment-first .big-width {
  width: 212px !important;
}

.sml-content {
  font-size: 10px !important;
  font-weight: 500;
  color: #000;
}

.second-line-contri {
  font-size: 16px;
  width: 231px;
  color: #fff;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
  margin-bottom: 45px;
}
.contribute-more-text {
  width: 171px;
  color: #fff;
  font-size: 20px;
  line-height: 22.5px;
  letter-spacing: -0.2px;
}

.text-250 {
  padding-left: 100px;
}
.your-account-Chevronleft-icon {
  position: absolute;
  top: 13px;
  right: 7px;
}
.groceries-main {
  display: contents;
}
.taxed-retirement .blue-new {
  padding-right: 25px;
}
.taxed-retirement .your-account-Chevronleft-icon {
  top: 9px;
}
/*< ------------------------ Groceries Edit css -------------->*/
.personalised h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0px;
}
.back-section {
  display: flex;
  background-color: #fff;
  padding: 15px 25px;
  border-radius: 8px;
  align-items: center;
}
.personalised {
  margin-right: 30px;
}
.color-option select.planner {
  background-color: #e0533d !important;
  color: #fff !important;
  font-weight: bold;
}
select.planner.dropdown {
  background-image: url('../images/icons/chevron-left.png');
  background-size: 14px;
  background-repeat: no-repeat;
}
.big-section-block .income-merchant {
  width: 100% !important;
}

.right-section h4 {
  color: #489fcd;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
/* .smal-trans {
  font-size: 18px !important;
  font-weight: 600 !important;
} */
.setting_text_color.content {
  line-height: 18px;
}
.blue-color {
  color: #489fcd;
}
.grocer-edit .sideblck {
  box-shadow: unset;
}
.income-merchant.col-lg-5.col-xl-5.col-sm-12.grocer-edit {
  box-shadow: unset;
}
/* < ----------------------------------------- Connect your Account Page ------------------------------------------ >*/

.bank-connection {
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  margin-top: 10px;
  /* display: inline-flex; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: space-between;
}
.bank-connection-img {
  display: inline-block;
  /* margin-right: 35px; */
}
.bank-connection-img img {
  width: 100%;
}
.investment-connection {
  background-color: #fff;
  padding: 20px;
  border-radius: 7px;
  margin-top: 10px;
  display: inline-flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.investment-connection-img {
  display: inline-block;
  margin-right: 20px;
}
.connect-your-accounts-main-wrapper
  .connect-your-account-bank-connection
  .live-connection-main-wrapper
  .investment-connection-img
  img {
  width: 90%;
}
.live-connection {
  font-size: 16px !important;
  font-weight: bold;
}
.akahu-box {
  background-color: #d8e8ed;
  padding: 25px 20px 20px 25px;
  border-radius: 15px;
  margin-top: 7px;
}
img.akahu-img {
  width: 85%;
}
.who-akahu {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.2px;
}

.re-sync-connection-section .comn-btn {
  padding: 15px 30px;
}
.setting_text_color-2 {
  color: #707070;
  font-size: 11px;
  font-weight: 400;
}

.live-connections-box {
  width: 50%;
  margin-top: 70px;
}
.icon-eclamation {
  position: absolute;
  background-color: #e0533d;
  display: inline-block;
  left: -40px;
  width: 26px;
  text-align: center;
  border-radius: 50%;
  height: 26px;
}
.arrow {
  position: relative;
}
i.fa-solid.fa-exclamation {
  color: #fff;
}
/* < ------------------------------------- Transactions Pages ------------------------------------- >*/

.transactions-table {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 6px 18px;
  border-radius: 15px;
  margin-top: 10px;
  align-items: baseline;
}
.your-transaction-main-container .transactions-table {
  padding: 10px 18px;
}
.transactions-text {
  font-size: 14px;
  font-weight: bold;
}
.pending {
  padding: 3px 21px 5px;
  font-size: 13px;
  display: inline-block;
  transition: 0.3s ease;
  background-color: rgba(226, 92, 92, 0.5);
  border-radius: 13px;
  color: #630000;
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 20px;
}
.done {
  padding: 3px 21px 5px;
  font-size: 13px;
  display: inline-block;
  transition: 0.3s ease;
  background-color: #0bba00;
  border-radius: 13px;
  color: white;
  text-align: center;
  border: 1px solid #e5e5e5;
  line-height: 20px;
}
.countdown-transaction {
  display: inline-block;
  width: 55px;
  height: 55px;
  padding: 4px;
}
.transactions-select {
  width: 125% !important;
  font-size: 13px !important;
}
.svg.trabs path {
  stroke: #000;
}
.your-transaction-main-container .pending {
  font-size: 12px;
}

.filter-group-wrapp {
  width: 30%;
}

.sml-blck {
  background-color: #dd7802;
  display: grid;
  justify-content: center;
  width: 47% !important;
  align-items: center;
  border-radius: 13px;
  flex-direction: column;
  color: #fff;
  padding: 13px 30px;
  text-align: center;
  margin-bottom: 10px;
  justify-items: center;
}

.sml-blck:nth-child(3n) {
  margin-left: 0px;
}
.sml-blck:nth-child(2n + 1) {
  margin-left: 0px;
}
.number {
  background-color: rgba(255, 255, 255, 75%);
  padding: 7px 7px;
  text-align: center;
  color: #000;
  font-weight: 600;
  width: 39px;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
}
.sml-blck h4 {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom: 0px;
  margin-top: 8px;
}
.sml-blck .number-heading {
  font-size: 23px;
  line-height: 30px;
}
.green {
  background-color: #469b88;
}
.blue-expenses {
  background-color: #377cc8;
}
.yellow-expenses {
  background-color: #eed868;
}
.pink {
  background-color: #e78c9d;
}
.sideblck {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  display: flex;
  border-radius: 8px;
  padding: 16px 13px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
.merchnat-img {
  width: 40px;
  display: flex;
  align-items: center;
}
/* .transaction-amt {
  margin-left: 13%;
} */
.left-arrow {
  background-color: #fff;
  padding: 11px 12px;
  border-radius: 50%;
}
.app-main__outer .header-row {
  align-items: center;
}
.left-arrow a {
  display: flex;
}
.merchant-box a {
  display: contents;
}
.name-merchant h6 {
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.3px;
  margin-bottom: 0px;
}
.transaction-amt h4 {
  color: #e25c5c;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.name-merchant.bar-chart {
  margin-left: 10px;
}
.cashflocart canvas.barcart {
  height: 380px !important;
  margin-top: 0px;
  padding: 0px !important;
}

.income-merchant {
  background-color: #fff;
  padding: 20px;
  display: inline-flex;
  border-radius: 13px;
  width: 414px !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.income-merchant:first-child {
  margin-left: 0px;
}
.income-merchant:nth-child(4n + 1) {
  margin-left: 0px;
}
.monthly-section {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.goal-section {
  display: inline-flex;
  flex-direction: row;
}
.inner-blck {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}
.spend-wrp {
  display: flex;
}
.spend-wrp .progress {
  margin-top: 10px;
}
.left-block.name-merchant {
  display: initial;
  text-align: right;
}
.spend-wrp .name-merchant:first-child {
  margin-left: 0px;
}

/*< -------------------------------------  Account Balances Page ------------------------------------- > */

.account-number {
  font-weight: bolder;
  color: #4ec376;
  font-size: 18px;
}
.new-light-green {
  color: #40af66;
}
.new-red-color {
  color: #ff0c0c;
}
.row.connects-wrp {
  align-items: center;
}
.account-border {
  border: 2px dashed #767676 !important;
}
.cash-account-box {
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 10px;
}
.account-input input {
  border: none;
  background-color: #efeded;
  border-radius: 10px;
  padding: 4px;
}
.add-account-button {
  text-align: center;
}
.add-account-button button {
  padding: 8px;
  background-color: #014479;
  border-radius: 10px;
  margin-top: 25px;
  color: white;
  display: inline-block;
  width: 150px;
  font-weight: bold;
  border: none;
}
.acount-text-color {
  color: #ff4f79;
}

.dashbord-merchant-box {
  width: 195px !important;
}

/* < ------------------------------------- Setting Page ------------------------------------- > */

.setting-your-details {
  background-color: #fff;
  padding: 25px 15px;
  border-radius: 12px;
}
.date-time-blck h4 {
  font-size: 18px;
  font-weight: 600;
  display: inline-flex;
}
.date-time-blck span {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}
.status-btn .percentage {
  margin-left: 0%;
}
.date-time-blck {
  margin-left: 45px;
}
.transaction-amt .light-green-transaction {
  color: #4ec376 !important;
}
.left-section {
  display: inline-grid;
  vertical-align: top;
}
.right-section {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.top {
  display: inline-flex;
}

.right-section .img {
  width: 50px;
  margin-left: 0px;
}
.saving-head h4 {
  font-size: 14px;
  color: #242424;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.blkc {
  background-color: #fff;
  padding: 15px 15px 1px 15px;
  border-radius: 15px 15px 0px 0px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}
.bottom-wrp {
  padding: 10px 15px 15px 15px;
  color: #fff;
  border-radius: 0px 0px 15px 15px;
  background-color: #022f4c;
}
.saving-block:first-child {
  margin-left: 0px;
}
.pink-block .saving-amt h3.number-heading {
  color: rgba(226, 92, 92, 0.9) !important;
}
.pink-block .bottom-wrp {
  background-color: #ef496f !important;
}
.purple-block .saving-amt h3.number-heading {
  color: #6d3d92;
}
.purple-block .bottom-wrp {
  background-color: #6d3d92;
}

.purple-block-kiwi-saver .saving-amt h3.number-heading {
  color: #6d3d92;
}

.purple-block-kiwi-saver .bottom-wrp {
  background-color: #6d3d92;
}

.saving-block:nth-child(5n) {
  margin-left: 0px;
}
.saving-acc-wrp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.edit-btn {
  display: inline-flex;
  padding: 11px 31px;
  border-radius: 13px;
  background-position: center;
  align-items: center;
  width: 60%;
  overflow: hidden;
  position: relative;
  background-color: #001b49 !important;
  justify-content: space-between;
}
.edit-btn:before {
  content: '';
  position: absolute;
  background-color: #47c1fe;
  height: 30px;
  width: 30px;
  left: -10px;
  border-radius: 50%;
  top: -12px;
}
.edit-btn:after {
  content: '';
  position: absolute;
  background-color: #dd7802;
  height: 30px;
  width: 30px;
  right: -10px;
  border-radius: 50%;
  bottom: -15px;
}
.edit-btn h4 {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.edit-btn p {
  margin-bottom: 0px;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
}
.row.header-wrp {
  justify-content: space-between;
  align-items: center;
}
.main-blck.mt-4 {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0px 0.8rem;
  justify-content: space-between;
}
.air-image .amount-active {
  margin-top: 0px;
  margin-left: 15px;
}

.topnav {
  display: none;
  position: relative;
}

/* ----------------------------------- Hide the links inside the navigation menu (except for logo/home) ---------------------------- */
.topnav #myLinks {
  display: none;
}

/* Style navigation menu links */
.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: black;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -45px;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active link (or home/logo) */
.bg-salmon {
  display: block !important;
}

/* Financial */
.financial-holiday {
  background-color: #e0533d;
  /* padding: 10px; */
  /* border-radius: 10px; */
}
.financial {
  padding: 20px 25px;
  border-radius: 10px;
}
.financial-text {
  font-size: 12px;
  color: #fff;
}
.financial-text-black {
  font-size: 12px;
  color: #fff;
}
.financial-text-black {
  font-size: 12px;
  color: #000;
}
.financial-icon path {
  stroke: white;
}
.financial-icon,
.financial-icon-black {
  padding-top: 8px;
}

.financial-progress-bar {
  background-color: #b51800 !important;
}
.icon-holiday {
  background-color: #ffa294;
  border-radius: 50%;
  font-size: 23px;
  padding: 11px 12px;
  width: 55px;
  height: 55px;
  text-align: center;
}
.financial-car {
  background-color: #eed868 !important;
  /* padding: 10px; */
  /* border-radius: 10px; */
}
.icon-car {
  background-color: #ffe794;
  /* border-radius: 50%; */
  /* font-size: 23px; */
  /* padding: 8px 14px; */
}
.icon {
  border-radius: 50%;
  font-size: 23px;
  width: 52px;
  height: 52px;
  text-align: center;
  padding: 6px 10px;
}
.financial-progress-bar-car {
  background-color: #c1ad45 !important;
}
.light-yellow-financial {
  background: rgba(255, 255, 255, 0.7);
}

.light-pink-financial {
  background: rgba(255, 255, 255, 0.7);
}

.light-blue-financial {
  background: rgba(255, 255, 255, 0.7);
}

.financial-laptop {
  background-color: #e78c9d;
  /* padding: 10px; */
  /* border-radius: 10px; */
}
.financial-progress-bar-laptop {
  background-color: #d9556e !important;
}
.icon-laptop {
  background-color: #ff9494;
  /* border-radius: 50%; */
  /* font-size: 23px; */
  /* padding: 8px 14px; */
}
.financial-House {
  background-color: #e78c9d;
  /* padding: 10px; */
  /* border-radius: 10px; */
}
.financial-house {
  background-color: #377cc8;
  /* padding: 10px; */
  /* border-radius: 10px; */
}
.financial-progress-bar-house {
  background-color: #00438d !important;
}
.icon-house {
  background-color: #94b2ff;
  /* border-radius: 50%; */
  /* font-size: 23px; */
  /* padding: 8px 14px; */
}
.edit-goal-box {
  background-color: white;
  padding: 10px 25px 10px 16px;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
  align-items: center;
}
.edit-goal-house {
  width: 128px;
  background-color: #efeded;
  font-size: 14px;
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  position: relative;
  height: 35px;
}

.edit-settings-house {
  background-color: #efeded;
  font-size: 14px;
  border-radius: 8px;
  font-weight: bold;
  height: 43px;
  display: inline-flex;
  align-items: center;
  padding: 12px 17px 7px 12px;
}

.change-password-btn {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  background: rgba(70, 155, 136, 1);
  width: 216px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}

.delete-btn {
  background: rgba(208, 79, 79, 1);
}

.header-h3 {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
  margin-top: 10px;
  display: inline-flex;
}

.header-p {
  margin-bottom: 0px !important;
  font-size: 30px;
}

/* Switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 29px;
}

.switch-plans {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 29px;
}
/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider styles */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(233, 233, 234, 1);
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked state styles */
input:checked + .slider {
  background-color: #469b88; /* Change the color when switch is ON */
}

input:checked + .slider:before {
  transform: translateX(18px); /* Move the slider to the right when switch is ON */
}

/* Round slider button */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.setting-logout-btn {
  width: 100px;
  height: 45px;
  color: rgba(213, 50, 50, 1);
  border: 2px solid rgba(213, 50, 50, 1);
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
}

.appearance-box {
  padding: 12px 11px 11px 28px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 22px;
}

.payment-margin {
  margin-left: 150px !important;
}

.payment-font {
  font-weight: 100;
  font-size: 13px;
  margin-top: 10px;
}

.payment-box {
  display: flex;
  background: url('../images/payment-bg.png');
  color: rgba(255, 255, 255, 1);
  background-size: cover;
  padding: 12px 12px 12px 12px;
  border-radius: 10px;
  /* align-items: center; */
  justify-content: space-between;
  width: 98%;
  box-shadow: 0px 1px 10px 0px rgba(255, 255, 255, 0.25);
}

.plan-billing {
  background: url('../images/payment-bg.png');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.standard-month {
  background: url('../images/standard-month.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.standard-month-current-plan {
  background: url('../images/standard-month-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.standard-year {
  background: url('../images/standard-year.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.standard-year-current-plan {
  background: url('../images/standard-year-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.pro-year {
  background: url('../images/pro-year.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.pro-year-current-plan {
  background: url('../images/pro-year-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.pro-month {
  background: url('../images/pro-month.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.pro-month-current-plan {
  background: url('../images/pro-month-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.free-trial {
  background: url('../images/free-trail.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.free-trial-current-paln {
  background: url('../images/free-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.no-current-plan {
  background: url('../images/no-current-plan.svg');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  width: 98% !important;
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}

.select-plan-billing-blue {
  background: url('../images/select-plan-blue.png');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.select-plan-billing-purple {
  background: url('../images/select-plan-purple.png');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.select-plan-billing-green {
  background: url('../images/select-plan-green.png');
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 12px 12px 12px 12px;

  display: inline-flex;
  border-radius: 13px;
  /* width: 98% !important; */
  /* margin-bottom: 19px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.plan-text {
  font-size: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.plan-description {
  font-family: 'Neurial Grotesk' !important;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 16px;
  margin: 6px 0px 2px 0px;
}

.plan-span {
  font-size: 15px;
  font-weight: 300;
}
.box-radis-25 {
  border-radius: 25px;
}

.change-plan {
  background: rgba(112, 158, 255, 1);
  width: 113px;
  height: 25px;
  border-radius: 30px;
  border: none;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
}
.cancel-plan-btn {
  background-color: rgba(208, 79, 79, 1);
  padding: 16px 82px !important;
}

.plan-text-sub {
  display: inline-block;
}

.cancel-btn {
  display: inline-flex;
  background-color: rgba(208, 79, 79, 1);
  padding: 4px 15px 4px 15px;
  margin-bottom: 0px;
  border-radius: 25px;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 1);
  margin-right: 0%;
  cursor: pointer;
}

.manage-btn {
  display: inline-flex;
  float: right;
  background-color: rgba(70, 155, 136, 1);
  padding: 4px 15px 4px 15px;
  margin-bottom: 0px;
  border-radius: 25px;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.billing {
  border-radius: 8px;
  background: var(--base-0, #fff);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.visa-logo-img {
  width: 80px;
  padding: 2px 0px 4px 10px;
}

.payment-method-text {
  font-size: 16px;
  line-height: 23px;
  color: rgba(78, 195, 118, 1);
}

.payment-method-number {
  font-size: 16px;
  line-height: 23px;
  color: rgba(0, 27, 73, 1);
  font-weight: 500;
}

.visa-logo-img img {
  width: 80px;
  height: 44px;
}

.card-logo-img {
  width: 31px;
  height: 23px;
  margin-right: 15px;
}

.btn-padding {
  padding: 0px 0px 0px 60px;
}

.version-padding {
  padding: 8px 0px 0px 71px;
}

.sky-blue-btn {
  background: rgba(4, 119, 181, 1);
}

.saving-outline-btn {
  color: rgba(4, 119, 181, 1) !important;
  border: 1px solid rgba(4, 119, 181, 1);
}

.saving-mrgbt {
  margin-bottom: 10px;
}

.label-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}
.full-width-transaction {
  width: 100% !important;
}
.btn-flex-start {
  justify-content: flex-start;
}
.saving-img {
  width: 40px;
  height: 38px;
  margin-left: 20px;
}

.edit-goal {
  position: absolute;
  top: 10px;
  right: 15px;
}
.edit-red {
  background-color: #c63838 !important;
  color: #fff;
  font-size: 16px;
  padding-top: 5px;
}
.edit-goal-inner-personalised .edit-goal-house .edit-goal {
  top: 12px;
}

.edit-goal-ami {
  transition: 0.3s ease !important;
}
.active-edit-goal {
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1);
  }
}

.personalised-color {
  background-color: #4d78c2;
  color: #fff;
}
.red-personalised path {
  stroke: #ffff;
}
.holiday-edit svg {
  padding-bottom: 5px;
}

svg.edit-goal.personalised-color path {
  stroke: #ffff;
  margin-top: 12px;
}

/* <--------------------------------edit Budget css ------------------------- > */

.top-head-section h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 0px;
}
.first-table-edit-cashflow thead th:first-child {
  padding-left: 0px !important;
}
.top-head-section p {
  font-size: 14px;
  line-height: 34px;
  margin-bottom: 0px;
}
table.table-image.new-table thead {
  background-color: transparent;
}
.new-table thead th {
  color: #000;
  text-align: center;
}
.new-table thead th:first-child {
  color: #000;
  text-align: left;
}
.new-table td.table-text-color {
  padding: 10px 0px;
  text-align: center;
}
.new-table .table-content {
  margin-left: 0px;
  font-weight: 700;
  color: #000;
}
.new-table tbody th:first-child {
  width: 15%;
}
.new-table .air-image {
  display: unset !important;
  margin-right: 0px;
}
select.planner {
  background-image: url('../images/icons/down-arrow.svg');
  height: 40px;
  width: 165px !important;
  padding: 7px 45px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: right 9px center;
}
button.new-btn {
  background-color: #469b88;
  border: none;
  padding: 16px 23px;
  border-radius: 15px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}
.new-btn svg {
  margin-left: 10px;
}
.total-income {
  background: rgba(70, 155, 136, 0.2);
  padding: 15px 15px;
  border-radius: 8px;
}
.content-blck {
  background-color: #fff;
  width: 335px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  display: grid;
}

/* <-----------------------Reoccuring css-------------------------> */
.reoccuring-wrp {
  margin-top: 120px !important;
}
.red-btn {
  background-color: #d04f4f !important;
}
.big-planner {
  width: 300px !important;
  display: inline-block;
  vertical-align: inherit;
}

.new-planner {
  width: 175px !important;
  display: inline-block;
  vertical-align: inherit;
}
/* <------------------------------------ Net worth --------------------------- > */

/* .button-assets {
  background-color: #e42e2e !important;
} */
.button-assets-text {
  color: #e42e2e;
}
.button-assets::before {
  background-color: #e42e2e !important;
}
.add-liability {
  background-color: #e42e2e !important;
}

.edit-cashflow-container-main .edit-cashflow-title-heading .big-head-new {
  font-size: 32px;
  font-weight: 700 !important;
  line-height: 34px;
  letter-spacing: -0.32px;
}
.edit-cashflow-container-main .edit-cashflow-title-heading .setting_text_color {
  font-size: 14px;
  color: #565a6d !important;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.14px;
}

.edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
  font-size: 16px !important;
  font-weight: bold !important;
}

.edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard .comn-btn img {
  width: 25px;
}

.edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard h3 {
  font-weight: 500;
}

.edit-cashflow-container-main .edit-cashflow-week-month-wrp .budget-tabs .nav-link {
  padding: 10px 130px !important;
  font-weight: bold;
}

/* .edit-cashflow-container-main .edit-cashflow-new-table-section .top-head-section h3 {
  font-size: 25px !important;
  font-weight: 800 !important;
} */

.edit-cashflow-container-main .edit-cashflow-new-table-section .top-head-section p {
  color: #242424 !important;
  line-height: 34px;
  letter-spacing: -0.14px;
}
/* <----------------------------- Edit Cash Flow ---------------------------------> */
.tabs-background-color {
  background-color: #fff;
  border: none !important;
  justify-content: space-between;
}
/* .income-type {
  background-color: #efeded;
  padding: 17px 69px;
  border-radius: 10px;
} */
.income-type-background {
  background-color: rgba(70, 155, 136, 0.5) !important;
}
.income-after-background {
  background-color: rgba(155, 70, 70, 0.5) !important;
}
.add-expense {
  background-color: #d04f4f;
}

.income-after td.table-text-color {
  padding: 20px 16px;
  text-align: center;
}
.income-after tbody th:first-child {
  padding-left: 20px;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-bottom: 10px;
}
.income-after tbody td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* <----------------- calender page ------------------> */
.cal-container {
  background-color: #fff;
  padding: 23px;
  border-radius: 8px;
}
.cal-inside {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 11px;
}
.cal-inside h5 {
  font-size: 12px;
  line-height: 20px;
  background-color: #469b88;
  padding: 4px 11px;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 0px;
  color: #fff;
}
.cal-inside p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 20px;
}
.calender-right .merchnat-img {
  display: inline-flex;
  align-items: center;
  width: 45%;
  padding: 9px 57px 9px 15px;
  background-color: #fff;
  margin-bottom: 18px;
  margin-right: 10px;
  border-radius: 8px;
}
.name-merchant h5 {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}
.calender-right .merchnat-img img {
  width: 36px;
  height: 36px;
}
.col-xl-7.calender-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.calender-right h6 {
  font-size: 12px;
  line-height: 20px;
  color: #e25c5c;
}
.cal-inside .text-merchant {
  margin-bottom: 0px;
}
.cal-inside .planner {
  padding: 15px 87px;
  height: 26px;
}
.row.cashflow-wrp {
  justify-content: space-between;
}
.select-wrp .planner {
  width: 109px !important;
  padding: 15px 33px;
  margin-right: 65px;
}
.big-select-wrp select.planner {
  padding: 15px 15px;
  margin-right: 14%;
  width: 126px !important;
}
.plus-img {
  background-color: #469b88;
  padding: 7px 8px;
  display: flex;
  border-radius: 7px;
  position: absolute;
  right: 0;
}
.acc-balance.merchnat-img {
  background-color: #fff;
  width: 19%;
  display: inline-flex;
  padding: 20px;
  border-radius: 7px;
  margin-right: 20px;
}
.acc-balance.merchnat-img:last-child {
  margin-right: 0px;
}
.merchnat-img img {
  width: 40px;
  border-radius: 25px;
}
.acc-balance .name-merchant.bar-chart {
  display: flex;
  margin-left: 0px;
  align-items: center;
}
.acc-balance h5.bold {
  margin-left: 15px;
}
/* <-------------- new css ---------------------->*/

.monthly-section .income-merchant {
  width: 350px !important;
}
.client-dashboard-main-wrapper .total-balance-wrp .img-sec {
  padding: 0px 60px;
}
.client-dashboard-main-wrapper .total-balance-wrp .img-sec .img {
  width: 45px;
}

.your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image .cursor-pointer td a {
  margin-left: 15%;
}

.inner-blck .merchant h6 {
  color: #707070;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.inner-blck .merchant h5 {
  color: #242424;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.income-merchant .light-pink-progress {
  border-radius: 7px;
  background: rgba(231, 140, 157, 0.3);
}
.income-merchant .light-pink-progress .light-pink {
  border-radius: 7px;
  background: #e78c9d;
}

.income-merchant .dark-blue {
  border-radius: 7px;
  background: rgba(55, 124, 200, 0.3);
}

.income-merchant .dark-blue .dark-blue-progress {
  background: #377cc8;
}

.income-merchant .light-yellow {
  background: rgba(238, 216, 104, 0.3);
}

.income-merchant .light-yellow .light-yellow-progress {
  border-radius: 7px;
  background: #eed868;
}

.income-merchant .light-green {
  border-radius: 7px;
  background: rgba(104, 238, 133, 0.4);
}

.income-merchant .light-green .light-green-progress {
  border-radius: 7px;
  background: #68ee85;
}

.income-merchant .light-pink {
  border-radius: 7px;
  background: rgba(238, 104, 225, 0.3);
}

.income-merchant .light-pink .light-pink-progress {
  border-radius: 7px;
  background: #ee68e1;
}

.income-merchant .red-second {
  border-radius: 7px;
  background: rgba(238, 104, 104, 0.3);
}

.income-merchant .red-second .red-second-progress {
  border-radius: 7px;
  background: #ee6868;
}

.see-all {
  color: #489fcd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
}

.bottom-wrp .saving-head h5 {
  color: #cfcfcf;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.bottom-wrp h4 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.dark-red-color {
  background: rgba(238, 104, 104, 0.3) !important;
}

.dark-red-color-progress {
  background: #e0533d !important;
}

.lightest-blue {
  background: rgba(55, 124, 200, 0.2) !important;
}
.lightest-blue-progress {
  background: #377cc8 !important;
}

.lightest-pink {
  background: rgba(231, 140, 157, 0.2) !important;
}

.lightest-pink-progress {
  background: #e78c9d !important;
}

.lightest-green {
  background: rgba(158, 231, 140, 0.2) !important;
}

.lightest-green-progress {
  background: #9ee78c !important;
}

.voilet-light {
  background: rgba(180, 140, 231, 0.2) !important;
}

.voilet-light-progress {
  background: #b48ce7 !important;
}

.light-brown-color {
  background: rgba(231, 167, 140, 0.2) !important;
}

.light-brown-color-progress {
  background: #e7a78c !important;
}

.phone-plan-green {
  background: rgba(140, 231, 155, 0.2) !important;
}

.phone-plan-green-progress {
  background: #8ce79b !important;
}

.navy-blue-color {
  background: rgba(4, 127, 189, 0.2) !important;
}

.navy-blue-color-progress {
  background: #047fbd !important;
}

.total-exp-budget-color {
  background: rgba(223, 92, 92, 0.2) !important;
}

.total-exp-budget-color-progress {
  background: #df5c5c !important;
}
.goal-1-progress-color {
  background: rgba(255, 255, 255, 0.4) !important;
}

/* Toggle hamburger */

.main-nav {
  display: none !important;
}

.menu-link {
  grid-column: 3/4;
}

.menu-link ul {
  height: 30rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: #001b49;
  text-decoration: underline;
  transition: 0.5s;
}

.hamburger-menu {
  display: none;
}

/* Toggle hamburger end */

/* <------------------- Calender css ----------------> */
span.rbc-toolbar-label {
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  font-family: 'Neurial Grotesk' !important;
}
.rbc-row.rbc-month-header {
  background-color: #fff;
  border-radius: 7px;
}
.rbc-month-view {
  border: unset !important;
}
.rbc-header {
  padding: 10px 15px !important;
  font-size: 20px !important;
  line-height: 25px !important;
  border-left: 0px !important;
}
.connect-bank-wrp .rbc-header {
  padding: 0px !important;
}
.investment-connection-img:last-child {
  margin-right: 0px;
}

/* <------------------------ Login css ------------------------>*/
.login-section {
  height: 100vh;
  display: flex;
  justify-content: center;
}
.login-section .btn i {
  margin-left: 0px;
}
.form-wrp a.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.form-wrp .form-outline p {
  color: red;
  position: absolute;
}
.form-wrp .comn-btn {
  padding: 16px 61px;
}
.btn.btn-floating:hover {
  background-color: #469b88;
  color: #fff;
}
.form-wrp {
  background-color: #fff;
  padding: 35px 40px;
  border-radius: 23px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.formwrp {
  position: relative;
}
.formwrp i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* <-------------------------------------------------------- Media qure ------------------------------------------------- >*/
@media only screen and (max-width: 1599px) {
  .table-wrp.mt-3.add-new-group-table-wrp .row {
    overflow-x: scroll;
  }
  table.group-table {
    width: 125% !important;
  }
}
@media only screen and (max-width: 1400px) {
  /* .income-merchant {
    width: 287px !important;
  } */
  .main-wrp-retirement {
    margin-right: 10px !important;
  }
  a.group-button.ms-3 {
    margin-left: 10px !important;
  }
  a.group-button.ms-3:last-child {
    margin-left: 10px !important;
  }
  .profile-img {
    margin-left: 20px;
    /* width: 40px; */
  }
  .edit-btn {
    width: 100%;
  }
  /* table.table-image {
    width: 130% !important;
  } */

  .edit-cashflow-container-main .edit-cashflow-new-table-section .first-table-edit-cashflow .new-table .table-content,
  .edit-cashflow-container-main .edit-cashflow-new-table-section .second-table-edit-cashflow .new-table .table-content {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1366px) {
  /* .income-merchant {
    width: 271px;
  } */
  button.active-btn {
    font-size: 13px;
    padding: 5px 8px;
  }
  .table-text-color {
    font-size: 14px;
    color: #6d6d6d !important;
  }
  /* table.table-image {
    overflow-x: scroll;
    width: 950px !important;
  } */
  .table-horizontal {
    overflow-x: scroll;
    width: 100%;
  }
  .dashboard-wrp .col-md-12 {
    margin-top: 25px;
  }
  .total-top-group {
    margin-bottom: 25px;
  }

  .fw-bold.heading-main.mt-3 {
    margin-top: 0px !important;
  }
  .dashboard-wrp .col-md-12:first-child {
    margin-top: 0px;
  }
  .nav-link {
    padding: 7px 44px !important;
  }
  .setting_background_color .profile-img {
    margin-left: 0px;
    width: 60px;
  }
  .profile-dashboard {
    margin-left: 15px;
    align-items: center;
  }
  .setting-img img {
    border-radius: 50%;
    height: unset;
    width: 40px;
  }
  .profile-dashboard h3 {
    /* color: #000; */
    font-size: 18px;
    /* font-style: normal; */
    /* font-weight: 500; */
    line-height: 34px;
    /* letter-spacing: -0.22px; */
  }

  /* .setting_background_color {
    padding: 10px 15px 10px 15px;
  } */
  .setting-your-advisor-text {
    margin-left: 15px;
  }
  .col-md-6.col-sm-12.col-lg-6.categories-block {
    width: 100% !important;
  }
  .edit-cashflow-scroll {
    overflow-x: scroll;
    width: 100%;
  }

  .monthly-section .income-merchant {
    width: 48% !important;
  }

  .bar-cart h5 {
    font-size: 14px;
  }
  .right-section select {
    font-size: 13px;
  }

  /* .saving-head h4 {
    font-size: 11px;
  } */
  /* .saving-amt .number-heading {
    font-size: 18px;
  } */

  /* .blkc .right-section .img {
    width: 35px;
  } */

  canvas.barcart {
    height: 400px !important;
  }

  .date-time-blck {
    margin-left: 80px;
  }
  .date-time-blck h4 {
    font-size: 13px;
  }
  .bottom-wrp .saving-head h5 {
    font-size: 11px;
  }
  .bottom-wrp h4 {
    font-size: 16px;
  }

  .edit-goal-table {
    width: 100% !important;
  }
  .edit-goal-budget-wrapper {
    width: 100% !important;
    overflow-x: scroll !important;
  }
}

@media only screen and (max-width: 1365px) {
  .heading {
    font-size: 20px;
  }
  .table-image thead th {
    font-size: 17px;
  }
  /* .comn-btn {
    padding: 15px 13px;
    margin-left: 20px;
  } */
  .app-main__outer {
    padding: 0px 15px 135px 270px;
  }
  table.group-table {
    width: 160% !important;
  }
  /* .purple-block {
    margin-top: 1rem !important;
  } */
  .col-md-12.col-lg-12.col-xl-9.col-sm-12.bar-cart {
    margin-top: 20px;
  }
  .col-md-12.bar-cart.col-xl-3.col-sm-12.col-lg-12 {
    margin-top: 20px;
  }
  .edit-btn h4 {
    font-size: 15px;
    line-height: 23px;
  }
  .fs-3.fw-bold.top-head {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  thead th:nth-child(6) {
    width: unset;
  }
  .big-head-new {
    font-size: 25px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 1199px) {
  table.table-image {
    width: 190% !important;
  }
  .cancel-plan-btn {
    background-color: rgba(208, 79, 79, 1);
    padding: 16px 82px !important;
  }
  .button {
    margin-right: 0px;
  }
  .your-groups-main-front-wrapper .your-groups-page-table-list {
    overflow-x: scroll !important;
  }

  .client-group-detail-page .margin-nu .number-heading {
    font-size: 32px !important;
  }

  .client-group-detail-page .retirement-planner-text {
    padding: 15px 0px;
  }
  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-content,
  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-text-color {
    font-size: 16px !important;
  }

  .main-front-dashboard-container .total-clients .text-client {
    line-height: 30px;
    font-size: 14px;
  }

  .modal-popup-btn-wrp {
    display: flex;
    justify-content: center;
  }

  .modal-background {
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 1025px) {
  .total-clients {
    padding: 14px;
  }
  .cancel-plan-btn {
    background-color: rgba(208, 79, 79, 1);
    padding: 16px 82px !important;
  }
  .col-lg-4 {
    width: 25% !important;
  }

  .big-head-new {
    font-size: 30px;
  }

  .bar-cart h5 {
    font-size: 12px;
  }
  .right-section select {
    font-size: 10px;
  }

  canvas.barcart {
    height: 365px !important;
  }
  .main-blck.mt-4 {
    padding: 0px 0.2rem;
  }

  .sideblck {
    padding: 12px 8px;
  }

  .transaction-amt h4 {
    font-size: 12px;
  }
  .date-time-blck {
    margin-left: 80px;
  }

  .group-details-page .table-image thead th {
    padding: 10px 10px !important;
  }

  .merchnat-img {
    width: 30px;
  }
  .date-time-blck h4 {
    font-size: 11px;
  }
  .date-time-blck span {
    font-size: 11px;
  }

  .main-wrapper .percentage {
    font-size: 11px;
    line-height: 18.5px;
  }

  .profile-dashboard:first-child {
    font-size: 17px;
  }

  .med-head {
    font-size: 20px;
  }

  .big-head {
    font-size: 40px;
  }

  .margin-nu {
    margin-top: 60px;
  }

  .margin-nu .number-heading {
    font-size: 21px;
  }
  .text-client {
    font-size: 13px;
  }

  .sml-blck {
    padding: 8px 25px;
  }
  .group-summary-page-main .table-image thead th:first-child {
    width: 33% !important;
    padding-left: 24px !important;
  }

  .main-front-dashboard-container .air-new-zealand-tab {
    margin-top: 10px !important;
  }
  .sml-blck .number {
    font-size: 14px;
  }
  .sml-blck .number-heading {
    font-size: 18px;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 15px 20px !important;
  }
  .grocery-edit-main .edit-your-budget .edit-btn h4 {
    font-size: 16px;
    font-weight: bolder;
  }
  .grocer-edit-bar-heading h5 {
    font-size: 18px !important;
  }
  .group-details-page td a {
    margin-left: 3% !important;
  }

  .grocery-edit-main .back-to-portal-grocery .profile-dashboard h3 {
    font-weight: 500 !important;
  }
  .client-dashboard-main-wrapper .top-5-expenses-wrapper .sml-blck {
    padding: 8px 13px;
  }

  .grocery-edit-nav .back-to-portal-grocery {
    width: 45% !important;
  }

  .grocery-edit-nav .back-to-portal-grocery .profile-dashboard h3 {
    font-size: 20px !important;
  }

  .grocery-edit-nav .back-to-portal-grocery .setting_background_color .comn-btn {
    font-size: 17px !important;
    padding: 12px 10px !important;
  }

  .grocery-edit-recent-trans .date-time-blck h4,
  span {
    font-size: 16px;
  }

  .grocery-edit-recent-trans .transaction-amt h4 {
    font-size: 16px;
  }

  .group-details-page {
    overflow-x: scroll !important;
  }

  .edit-group-title-wrapper {
    display: inline !important;
  }

  .group-details-page .table-image {
    width: 130% !important;
  }

  .group-summary-page-main .search-wrapper {
    width: 50% !important;
  }

  .group-summary-page-main .total-clients {
    padding: 15px 25px;
  }

  .edit-group-page-main .upload-csv-file .profile-img img {
    width: 40px;
  }

  .table-edit-group-page .table-image {
    width: 100%;
  }
  .table-edit-group-page {
    overflow-x: scroll !important;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image thead th:first-child {
    width: 30% !important;
  }
  .clients-with-share-rqst-wrapper .table-content,
  .clients-with-share-rqst-wrapper .table-text-color {
    font-size: 15px !important;
  }

  /* Your account balances css */

  .back-portal-account-balances .setting_background_color {
    padding: 15px 9px 15px 9px !important;
  }
  .back-portal-account-balances .setting_background_color .comn-btn {
    padding: 15px 5px !important;
  }
  .back-portal-account-balances .profile-dashboard h3 {
    font-size: 18px;
  }

  .your-account-balances-container-main .add-new-account-wrappee .edit-btn {
    width: 80%;
  }

  .your-account-balances-container-main .account-number-dark {
    font-size: 14px !important;
  }

  .your-account-balances-container-main .top-heading-account {
    width: 100% !important;
  }
  .your-account-balances-container-main .add-new-account-wrappee {
    width: 45% !important;
    margin-top: 10px;
  }

  .your-account-balances-container-main .back-portal-account-balances {
    width: 45% !important;
  }

  .your-account-balances-container-main .text-merchant {
    font-size: 14px !important;
  }
  .your-account-balances-container-main .number-account {
    font-size: 12px !important;
  }
  .your-account-balances-container-main .account-number-dark-red {
    font-size: 14px !important;
  }

  /* Your account balances css end*/

  .client-summary-page-main .report-card .slecet-wrp select {
    font-size: 12px !important;
  }

  .financial-cards-wrp-div {
    width: 100% !important;
  }
  /* merchants details page */

  .merchant-details-wrapp .top-heading-merchant {
    width: 100% !important;
  }
  .merchant-details-wrapp .back-to-merchants-wrp .edit-btn {
    width: 100% !important;
  }
  .merchant-details-wrapp .back-portal-merchant-detail {
    width: 45% !important;
  }
  .merchant-details-wrapp .your-spend-box {
    padding: 16px 4px 16px 1px !important;
  }
  .merchant-details-wrapp .merchant-detail-table .text-merchant {
    font-size: 14px !important;
  }
  .merchant-details-wrapp .merchant-detail-table .transactions-text {
    font-size: 13px !important;
  }

  /*Merchants details page end  */

  /* Your transaction page */

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color {
    padding: 12px 10px 12px 10px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn {
    font-size: 16px !important;
    padding: 12px 12px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn i {
    margin-right: 10px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .profile-dashboard h3 {
    font-size: 17px !important;
    font-weight: 500 !important;
  }
  /* Your transaction page end */

  /* Grocery edit page css */

  .grocery-edit-main .grocery-edit-nav .main-title-heading {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .grocery-edit-main .back-to-portal-grocery .setting_background_color {
    padding: 12px 12px 12px 10px !important ;
  }

  /* Your financial goals pages */

  .financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
    padding: 10px 43px !important;
  }

  .financial-goals-main .back-to-portal-financial-goals {
    width: 45% !important;
  }

  .financial-goals-main .back-btn-financial .edit-btn {
    padding: 15px 40px !important;
  }

  .financial-goals-main .back-btn-financial .edit-btn h4 {
    font-size: 18px !important;
  }
  /* Your financial goals pages end */

  /* ----Edit your budget page------- */
  .edit-goal-budget-wrapper .table-image {
    width: 120% !important;
  }
  .back-to-budget-summ {
    font-size: 14px !important;
  }

  .personalise-edit {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .edit-btn {
    padding: 11px 15px;
  }

  .edit-your-budget-main-wrapper .back-to-budget-summ {
    font-size: 17px !important;
  }

  .edit-your-budget-heading-wrp .setting_text_color {
    line-height: 26px;
  }

  .edit-your-budget-main-wrapper .edit-btn {
    width: 100% !important;
    padding: 11px 11px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    font-size: 14px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .profile-dashboard h3 {
    font-size: 17px !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color {
    padding: 15px 15px 15px 15px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 10px !important;
    font-size: 13px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper .new-table tbody th:first-child {
    width: 12% !important;
  }

  .edit-your-budget-main-wrapper .first-table-edit-budget {
    overflow-x: scroll !important;
  }
  .edit-your-budget-main-wrapper .first-table-edit-budget .table-horizontal .table-image {
    width: 150% !important;
  }
  .edit-your-budget-main-wrapper .second-table-edit-budget {
    overflow-x: scroll !important;
  }
  .edit-your-budget-main-wrapper .second-table-edit-budget .table-horizontal .table-image {
    width: 120% !important;
  }

  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper {
    overflow-x: scroll !important;
  }

  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper .regular-account-transfer-inner {
    width: 150% !important;
  }

  /* ----Edit your budget page end */

  /* edit summary page css */

  .client-summary-page-main .total-clients {
    padding: 25px 20px !important;
  }

  .client-summary-page-main .total-clients .air-new-zealand {
    font-size: 24px !important;
  }

  .client-summary-page-main .total-clients .pro-plan .air-new {
    font-size: 38px !important;
  }

  .client-summary-page-main .total-clients .comn-btn {
    font-size: 16px !important;
    padding: 10px 10px;
  }

  .client-summary-page-main .top-wrapper-client-summary .main-heading-profile-img {
    width: 100% !important;
  }

  .client-summary-page-main .financial .view-financials {
    padding-right: 13px;
    font-size: 15px;
  }

  .client-summary-page-main .first-report-card-wrp,
  .client-summary-page-main .report-card-main-wrp {
    width: 50%;
  }
  .renews-plan {
    font-size: 25px !important;
  }
  .plan-date {
    padding: 5px 8px 0px 8px !important;
    height: 40px !important;
  }

  /* Edit summary page css end */

  /* Dashboard inner page new css */

  .client-dashboard-main-wrapper .name-merchant h6 {
    font-size: 13px;
  }

  .client-dashboard-main-wrapper .income-exp-mnth h5 {
    font-size: 14px !important;
  }
  .client-dashboard-main-wrapper .income-exp-mnth .right-section select {
    font-size: 11px !important;
  }

  .client-dashboard-main-wrapper .top-5-expenses-wrapper .right-section select,
  .top-5-merchants-wrapper .right-section select {
    font-size: 11px;
  }

  .client-dashboard-main-wrapper .total-balance-wrp .img-sec {
    padding: 0px 45px;
  }
  .client-dashboard-main-wrapper .spend-budget h5 {
    font-size: 20px !important;
  }
  .client-dashboard-main-wrapper .spend-budget select {
    font-size: 15px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all h5,
  .client-dashboard-main-wrapper .weekly-cahflowcart h5,
  .client-dashboard-main-wrapper .net-worth-progress-side h5,
  .client-dashboard-main-wrapper .account-balances-side .title-heading h5 {
    font-size: 20px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h5 {
    font-size: 17px;
  }

  .client-dashboard-main-wrapper .account-balances-side .blkc h4 {
    font-size: 12px !important;
  }
  .client-dashboard-main-wrapper .account-balances-side .saving-amt .number-heading {
    font-size: 20px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .date-time-blck h4 {
    font-size: 16px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
    padding: 14px 14px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .merchnat-img {
    width: 178px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .merchnat-img img {
    width: 50px !important;
    height: 50px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .status-btn .percentage {
    padding: 10px 20px !important;
    font-size: 13px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .transaction-amt h4 {
    font-size: 16px !important;
  }

  .client-dashboard-main-wrapper .net-worth-progress-side {
    margin-bottom: 10px;
  }

  .client-dashboard-main-wrapper .weekly-cahflowcart {
    width: 100%;
  }

  /* New cssss */

  .financial-goals-main .main-heading-financial {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .edit-btn-financial {
    width: 45% !important;
  }

  .financial-goals-main .back-btn-financial {
    width: 30% !important;
  }
  .financial-goals-main .back-btn-financial .edit-btn {
    padding: 11px 34px;
  }

  .financial-goals-main .back-to-portal-financial {
    width: 45% !important;
    justify-content: flex-start;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 10px 10px 10px 12px !important;
  }
  .financial-goals-main .back-to-portal-financial .comn-btn {
    padding: 15px 10px;
  }

  .add-new-goal-wrpp {
    width: 75% !important;
  }

  /* Retirement planner css 1026 to 1200 */

  .retirement-plan-main .account-assets-wrp {
    width: 70%;
  }

  .sml-content span {
    font-size: 10px !important ;
  }

  .retirement-plan-main .first-table-edit-cashflow table.table-image {
    width: 100% !important;
  }
  .retirement-wrp-table-side .planner {
    padding: 10px 110px !important;
  }
  .retirement-plan-main .total-amt-now-box {
    width: 30% !important;
  }
  .account-assets-total-amt .retirement-planner-wrp {
    margin-left: 0px;
  }

  .retirement-plan-main .account-assets-wrp .box-wrp-15 {
    padding: 15px 7px 15px 7px !important;
  }

  .retirement-plan-main .account-assets-wrp .last-house-wrp {
    padding: 12px 7px 12px 7px !important;
  }

  .retirement-wrp-table-side td.table-text-color {
    height: 60px;
    padding: 20px 10px !important;
  }
  .retirement-plan-main .retirement-wrp-table-side tbody th:first-child {
    width: 15% !important;
  }

  .retirement-plan-main .account-assets-wrp .account-assets-column {
    width: 50%;
  }

  .account-assets-inner .account-assets-mini-title span {
    font-size: 11px !important;
  }

  .retirement-plan-main .total-amt-now-box .account-assets-total-amt {
    width: 100% !important;
  }

  .retirement-plan-main .your-contribution-wrapper .contribution-inner-left-side {
    width: 50%;
    margin: 0px;
  }

  .contribution-right-side {
    width: 50% !important;
  }

  .contribution-right-side .retirement-planner-wrp {
    margin-left: 0px;
  }

  .retirement-plan-main .retirement-section-2 .retirement-inner-left-side {
    width: 50%;
    margin: 0px;
  }
  .retirement-plan-main .retirement-section-2 {
    width: 68% !important;
  }

  .retirement-plan-main .retirement-section-2 .retirement-inner-left-side .everyday-account {
    margin-left: 0px;
  }

  .retirement-section-2-right-wrp .retirement-planner-wrp {
    margin-left: 0px;
    padding: 30px !important;
  }

  .retirement-plan-main .contribution-right-side .left-section {
    width: 80% !important;
  }
  .retirement-section-2-right-wrp .retirement-planner-text {
    font-size: 15px !important;
  }

  .retirement-plan-main .retirement-section-2-right-side {
    width: 100% !important;
  }
  .retirement-plan-main .retirement-section-2-right-wrp {
    width: 32% !important;
  }

  .retirement-plan-main .veteran-pension-payment-first {
    width: 68% !important;
  }
  .retirement-plan-main .veteran-pension-payment-second {
    width: 68%;
    margin-top: 20px;
  }

  .retirement-plan-main .veteran-pension-payment-second .left-section {
    width: 100%;
  }

  /* Edit cashflow css */

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
    font-size: 14px;
  }

  .edit-cashflow-container-main .edit-cashflow-title-heading .setting_text_color {
    font-size: 14px;
    line-height: 25px !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn {
    width: 100% !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .comn-btn {
    font-size: 13px !important;
    padding: 12px 8px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard .comn-btn img {
    width: 18px;
    margin-left: 0px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard h3 {
    font-size: 14px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color {
    padding: 12px 5px 12px 5px;
  }

  .edit-cashflow-container-main .edit-cashflow-week-month-wrp .budget-tabs .nav-link {
    padding: 10px 90px !important;
    font-weight: bold;
  }
  /* Net worth progress */

  .net-worth-main .total-bal-main-first-wrp {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .net-worth-main .net-worth-line-cart-canvas {
    width: 100% !important;
  }

  .net-worth-main .left-section .full-breakdown-arrow svg {
    height: 35px;
    width: 35px;
    margin-left: 2px;
  }

  .net-worth-main .left-section .full-breakdown-arrow h4 {
    font-size: 17px !important;
  }
  .net-worth-main .assets-wrp .asset-wrp-second-side .light-green-new {
    font-size: 26px;
  }
  .net-worth-main .assets-wrp .asset-wrp-second-side .this-week {
    font-size: 14px;
    font-weight: 500;
  }

  .net-worth-main .net-worth-table .table-horizontal {
    width: 850px !important;
  }

  .net-worth-main .net-worth-table-second .table-horizontal {
    width: 850px !important;
  }

  .net-worth-main .total-bal-main-first-wrp .big-head {
    font-size: 50px !important;
  }

  /* Your account balances */

  .your-account-balances-container-main .acccount-balances-wrappper-left-side,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side {
    width: 33% !important;
  }
  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .text-merchant,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .text-merchant {
    font-size: 13px;
  }

  .your-account-balances-container-main .your-account-balance-wrp-logo-img {
    height: 35px;
    width: 35px;
  }

  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .account-number,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .account-number {
    font-size: 14px;
  }

  .your-account-balances-container-main .your-account-bal-add-cash {
    width: 33% !important;
  }

  .your-account-balances-container-main .your-account-bal-add-cash .cash-account-box {
    width: 300px;
  }

  /* Connect your acccount css */

  .connect-your-accounts-main-wrapper .re-sync-connection-section {
    width: 33% !important;
    margin-top: 15px;
  }

  .connect-your-acccount-title {
    width: 100% !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal {
    width: 45% !important;
    margin-top: 15px;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    padding: 15px !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn i {
    font-size: 18px;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-back-portal
    .setting_background_color
    .profile-dashboard
    h3 {
    font-size: 18px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .bank-connection .bank-connection-img img {
    width: 90% !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .add-an-investment-conn-wrapper
    .investment-connection
    .investment-connection-img
    img {
    width: 90% !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .live-connection-main-wrapper {
    width: 75% !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .investment-connection-img
    img {
    width: 90%;
  }
  .akahu-box {
    padding: 12px 15px 13px 15px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 15px 15px 15px 15px !important;
  }

  .connect-your-accounts-main-wrapper .connect-account-akahu-box-wrp {
    width: 40% !important;
    margin-left: 0 !important;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .top-heading-wrp-main {
    width: 50% !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 45% !important;
  }

  .group-summary-page-table .table-text-color,
  .group-summary-page-table .table-content {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .cancel-plan-btn {
    background-color: rgba(208, 79, 79, 1);
    padding: 16px 82px !important;
  }
  .table-transaction {
    display: flex !important;
    justify-content: space-between !important;
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 15px !important;
    width: 1041px !important;
    margin-top: 10px !important;
    align-items: baseline !important;
  }
  .nav-link {
    padding: 7px 41px !important;
  }
  /* .comn-btn {
    padding: 15px 10px;
    margin-left: 14px;
  } */
  .text-jeremy {
    font-size: 32px !important;
  }

  .retirement-bg {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    border-radius: 15px;
    margin-top: 10px;
    align-items: baseline;
  }
  table.new-table {
    overflow-x: scroll;
    width: 1275px !important;
  }
  .new-table tbody th:first-child {
    width: 25%;
  }

  .dashboard-wrp .total-clients {
    padding: 13px 15px;
  }

  .total-clients {
    padding: 13px 9px;
  }
  .group-details-page .table-image thead th {
    padding: 10px 10px !important;
  }
  .group-details-page td a {
    margin-left: 3% !important;
  }

  .edit-group-page-main .upload-csv-file .profile-img img {
    width: 40px;
  }
  .table-edit-group-page .table-image {
    width: 100%;
  }
  .table-edit-group-page {
    overflow-x: scroll !important;
  }

  .edit-client-details-page .edit-client-last-side .edit-clients-buttons .comn-btn {
    padding: 12px 12px !important;
  }
  .edit-client-details-page .edit-client-last-side .edit-clients-buttons {
    padding: 0px 5px 0px 0px !important ;
  }

  .edit-client-details-page .edit-client-last-side .edit-clients-buttons .comn-btn i {
    font-size: 18px;
  }
  .edit-your-budget-main-wrapper .edit-btn {
    padding: 11px 12px !important;
  }

  .edit-group-title-wrapper {
    display: inline !important;
  }
  .text-14px {
    font-size: 14px !important;
  }

  .profile-dashboard:first-child {
    font-size: 16px;
  }
  .margin-nu {
    margin-top: 50px;
  }

  .margin-nu .number-heading {
    font-size: 20px;
  }
  .main-front-dashboard-container .text-jeremy {
    font-size: 32px !important;
  }

  .main-front-dashboard-container .air-new-zealand-tab {
    margin-top: 10px !important;
  }
  .companies-list-wrapper .air-image .amount-active {
    margin-left: 15%;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 30px;
    text-align: center;
    font-size: 16px !important;
  }
  .companies-list-wrapper thead th {
    font-size: 18px;
  }

  .text-client {
    font-size: 12px;
  }
  .med-head {
    font-size: 18px;
  }
  .big-head {
    font-size: 36px;
  }

  .col-md-7.img-sec img {
    width: 30px;
  }
  .right svg {
    width: 25px;
    height: 25px;
  }

  canvas.barcart {
    height: 400px !important;
  }
  .sml-blck {
    padding: 6px 25px;
  }
  .sml-blck h4 {
    font-size: 13px;
  }

  .sml-blck .number-heading {
    font-size: 20px;
  }
  .spend-budget h5 {
    font-size: 14px;
  }

  .group-details-page {
    overflow-x: scroll !important;
  }

  .group-details-page .table-image {
    width: 130% !important;
  }

  .group-summary-page-main .search-wrapper {
    width: 50% !important;
  }

  .group-summary-page-main .table-image thead th:first-child {
    width: 33% !important;
    padding-left: 24px !important;
  }

  .group-summary-page-main .total-clients {
    padding: 15px 25px;
  }

  .group-details-page td a {
    margin-left: 3% !important;
  }
  .spend-budget .right-section select {
    font-size: 13px;
  }

  .spend-budget {
    margin-bottom: 10px;
  }
  .cashflocart h5 {
    font-size: 14px;
  }

  .spend-budget .see-all {
    font-size: 13px;
  }

  .bar-cart h5 {
    font-size: 12px;
  }

  .income-exp-mnth .right-section select {
    font-size: 15px;
  }

  .top-expenses h5 {
    font-size: 14px;
  }
  .top-expenses .right-section select {
    font-size: 13px;
  }

  .right-section select {
    font-size: 9px;
  }
  .see-all {
    font-size: 10px;
  }

  /* .recent-see-all {
    margin-top: 20px;
  } */

  .financial-cards-wrp-div {
    width: 100% !important;
  }

  .edit-goal-inner {
    padding: 10px 25px 10px 16px !important;
  }

  .edit-goal-inner-personalised {
    padding: 10px 25px 10px 16px !important;
  }

  .col-md-4.col-lg-4.col-sm-12.col-xl-3.purple-block:nth-child(4) {
    margin-top: 15px;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image thead th:first-child {
    width: 30% !important;
  }
  .clients-with-share-rqst-wrapper .table-content,
  .clients-with-share-rqst-wrapper .table-text-color {
    font-size: 15px !important;
  }

  .see-all {
    font-size: 13px;
  }

  .net-worth-main .left-section .med-head {
    font-size: 16px;
  }

  .net-worth-main .left-section .big-head {
    font-size: 34px;
  }

  .net-worth-main .left-section .med-new-font {
    font-size: 14px;
  }

  .net-worth-main .left-section .full-breakdown-arrow {
    padding: 0px 0px 0px 5px;
  }

  .net-worth-main .left-section {
    margin-bottom: 20px;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side {
    width: 25%;
    margin-bottom: 0;
  }

  .net-worth-main .liability-wrp .left-section {
    width: 25%;
    margin-bottom: 0;
  }

  /* Your account balances css */

  .your-account-balances-container-main .add-new-account-wrappee .edit-btn {
    width: 100% !important;
    padding: 11px 20px !important;
    margin-top: 10px;
  }

  .your-account-balances-container-main .account-number-dark {
    font-size: 14px !important;
  }

  .your-account-balances-container-main .top-heading-account {
    width: 100% !important;
  }
  .your-account-balances-container-main .back-portal-account-balances {
    width: 50% !important;
  }

  /* Your account balances css end */

  /* merchants details page */

  .merchant-details-wrapp .top-heading-merchant {
    width: 100% !important;
  }
  .merchant-details-wrapp .back-to-merchants-wrp .edit-btn {
    width: 100% !important;
  }
  .merchant-details-wrapp .back-portal-merchant-detail {
    width: 45% !important;
  }
  .merchant-details-wrapp .your-spend-box {
    padding: 7px 2px 13px 1px !important;
  }
  .merchant-details-wrapp .merchant-detail-table .text-merchant {
    font-size: 16px !important;
  }
  .merchant-details-wrapp .merchant-detail-table .transactions-text {
    font-size: 14px !important;
  }

  .merchant-details-wrapp .back-portal-merchant-detail .setting_background_color .comn-btn {
    padding: 12px 7px !important;
  }

  /*Merchants details page end  */

  /* Your financial goals pages */

  .financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
    padding: 10px 42px !important;
  }

  .financial-goals-main .back-to-portal-financial-goals {
    width: 45% !important;
  }
  .financial-goals-main .back-to-portal-financial-goals .setting_background_color .comn-btn {
    padding: 15px 8px !important;
  }

  .financial-goals-main .back-btn-financial .edit-btn {
    padding: 15px 35px !important;
  }
  .financial-goals-main .back-btn-financial .edit-btn h4 {
    font-size: 18px !important;
  }
  /* Your financial goals pages end */

  /* Your transaction page */

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color {
    padding: 12px 10px 12px 10px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn {
    font-size: 16px !important;
    padding: 12px 12px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn i {
    margin-right: 10px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .profile-dashboard h3 {
    font-size: 17px !important;
    font-weight: 500 !important;
  }
  /* Your transaction page end */

  /* edit summary page css */

  .client-summary-page-main .total-clients {
    padding: 25px 20px !important;
  }

  .client-summary-page-main .total-clients .air-new-zealand {
    font-size: 24px !important;
  }

  .client-summary-page-main .total-clients .pro-plan .air-new {
    font-size: 38px !important;
  }

  .client-summary-page-main .total-clients .comn-btn {
    font-size: 16px !important;
    padding: 10px 10px;
  }

  .client-summary-page-main .top-wrapper-client-summary .main-heading-profile-img {
    width: 100% !important;
  }

  .client-summary-page-main .financial {
    padding-right: 10px !important;
  }

  .client-summary-page-main .financial .view-financials {
    padding-right: 10px !important;
  }

  .client-summary-page-main .first-report-card-wrp,
  .client-summary-page-main .report-card-main-wrp {
    width: 100%;
  }
  .client-summary-page-main .report-card-title {
    font-size: 18px !important;
  }
  .renews-plan {
    font-size: 25px !important;
  }
  .plan-date {
    padding: 5px 8px 0px 8px !important;
    height: 40px !important;
  }

  /* Edit summary page css end */

  /* Grocery edit page css */

  .grocery-edit-main .grocery-edit-nav .main-title-heading {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .grocery-edit-main .back-to-portal-grocery .setting_background_color {
    padding: 12px 12px 12px 10px !important ;
  }
  .grocery-edit-main .back-to-portal-grocery .comn-btn {
    font-size: 16px !important;
  }

  .grocery-edit-main .back-to-portal-grocery .profile-dashboard h3 {
    font-size: 17px !important;
    font-weight: 600 !important;
  }
  .grocery-edit-nav .back-to-portal-grocery {
    width: 45% !important;
  }

  .grocery-edit-main .groceries-edit-table .new-table tbody th:first-child {
    width: 12% !important;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 15px 13px !important;
  }
  .grocery-edit-main .edit-your-budget .edit-btn h4 {
    font-size: 16px !important;
    font-weight: bolder;
  }
  .grocer-edit-bar-heading h5 {
    font-size: 18px !important;
  }

  .grocery-edit-main .back-to-portal-grocery .profile-dashboard h3 {
    font-weight: 500 !important;
  }
  .client-dashboard-main-wrapper .top-5-expenses-wrapper .sml-blck {
    padding: 8px 13px;
  }

  .grocery-edit-nav .back-to-portal-grocery {
    width: 45% !important;
  }

  .grocery-edit-nav .back-to-portal-grocery .profile-dashboard h3 {
    font-size: 20px !important;
  }

  .grocery-edit-nav .back-to-portal-grocery .setting_background_color .comn-btn {
    font-size: 17px !important;
    padding: 12px 10px !important;
  }

  .grocery-edit-recent-trans .date-time-blck h4,
  span {
    font-size: 16px !important;
  }

  .grocery-edit-recent-trans .transaction-amt h4 {
    font-size: 16px !important;
  }

  /* Net worth progress */

  .net-worth-main .total-bal-main-first-wrp {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .net-worth-main .net-worth-line-cart-canvas {
    width: 100% !important;
  }

  .net-worth-main .left-section .full-breakdown-arrow svg {
    height: 35px;
    width: 35px;
    margin-left: 2px;
  }

  .net-worth-main .total-bal-main-first-wrp .big-head {
    font-size: 50px !important;
  }
  .net-worth-main .asset-wrp-second-side .saving-head h4,
  .net-worth-main .liability-wrp-second-side .saving-head h4 {
    font-size: 14px !important;
    padding-top: 10px;
  }
  .net-worth-main .left-section .full-breakdown-arrow h4 {
    font-size: 17px !important;
  }
  .net-worth-main .assets-wrp .asset-wrp-second-side .light-green-new {
    font-size: 28px !important;
  }

  .net-worth-main .liability-wrp-second-side .button-assets-text {
    font-size: 28px !important;
  }
  .net-worth-main .assets-wrp .asset-wrp-second-side .this-week {
    font-size: 15px;
    font-weight: bold;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side .this-week span,
  .net-worth-main .liability-wrp .liability-wrp-second-side .this-week span {
    font-weight: 500 !important;
  }

  .net-worth-main .net-worth-table .table-horizontal {
    width: 850px !important;
  }

  .net-worth-main .net-worth-table-second .table-horizontal {
    width: 850px !important;
  }
  /* New cssss */

  .financial-goals-main .main-heading-financial {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .edit-btn-financial {
    width: 40% !important;
  }

  .financial-goals-main .back-btn-financial {
    width: 30% !important;
  }

  .financial-goals-main .back-to-portal-financial {
    width: 50% !important;
    justify-content: flex-start;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 10px 10px 10px 12px !important;
  }

  /* Retirement planner css upto 1024 */

  .retirement-plan-main .account-assets-wrp {
    width: 50%;
  }

  .retirement-plan-main .first-table-edit-cashflow table.table-image {
    width: 100% !important;
  }
  .retirement-wrp-table-side .planner {
    padding: 10px 110px !important;
  }
  .retirement-wrp-table-side td.table-text-color {
    height: 60px;
    padding: 20px 7px !important;
  }
  .retirement-plan-main .retirement-wrp-table-side tbody th:first-child {
    width: 15% !important;
  }

  .retirement-plan-main .account-assets-wrp .account-assets-column {
    width: 100%;
  }
  .retirement-plan-main .account-assets-wrp .account-assets-column:first-child {
    margin-bottom: 18px;
  }
  .account-assets-inner .account-assets-mini-title span {
    font-size: 12px !important;
  }

  .house-wrp-retirement .merchant-box {
    padding-left: 25px;
  }
  .your-contribution-wrapper .house-wrp-retirement {
    width: 100% !important;
  }

  .retirement-plan-main .total-amt-now-box .account-assets-total-amt {
    width: 80% !important;
  }

  .retirement-plan-main .total-amt-now-box {
    width: 50%;
  }

  .retirement-plan-main .account-assets-wrp .account-assets-column .saving-title {
    margin-left: 23px !important;
  }

  /* Contribution wrapper */

  .retirement-plan-main .your-contribution-wrapper {
    width: 50%;
  }

  .retirement-plan-main .your-contribution-wrapper .contribution-inner-left-side {
    width: 100%;
    margin: 5px;
  }
  .retirement-plan-main .contribution-right-side {
    width: 50%;
  }

  .retirement-plan-main .contribution-right-side .left-section {
    width: 80% !important;
  }

  /* Contribution wrapper End */

  /* Retirement section  */
  .retirement-plan-main .retirement-section-2 {
    width: 50%;
  }

  .retirement-plan-main .retirement-section-2 .retirement-inner-left-side .everyday-account {
    margin-left: 8px;
  }

  .retirement-plan-main .retirement-section-2-right-wrp {
    width: 50% !important;
  }

  .retirement-plan-main .retirement-section-2 .retirement-inner-left-side {
    width: 100% !important ;
  }

  .retirement-plan-main .retirement-section-2-right-wrp .retirement-section-2-right-side {
    width: 100% !important;
  }

  /* Retirement section end */

  .retirement-plan-main .veteran-pension-payment-first {
    width: 50% !important;
  }
  .retirement-plan-main .veteran-pension-payment-second {
    width: 50%;
  }

  .veteran-pension-payment-second .taxed-retirement {
    margin-left: 20px;
  }
  .veteran-pension-payment-second .retirement-planner-wrp {
    margin-left: 20px !important;
  }

  .retirement-plan-main .veteran-pension-payment-second .left-section {
    width: 100%;
  }

  /* Edit cashflow css */

  .edit-your-budget-main-wrapper .edit-btn {
    padding: 11px 15px;
  }

  .edit-your-budget-main-wrapper .back-to-budget-summ {
    font-size: 16px !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard .comn-btn img {
    width: 20px;
    margin-left: 8px;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color {
    padding: 15px 4px 14px 2px !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
    font-size: 14px;
  }

  .edit-cashflow-container-main .edit-cashflow-title-heading .setting_text_color {
    font-size: 14px;
    line-height: 25px !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn {
    width: 100% !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .comn-btn {
    font-size: 13px !important;
    padding: 12px 8px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard .comn-btn img {
    width: 18px;
    margin-left: 0px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard h3 {
    font-size: 14px !important;
  }
  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color {
    padding: 12px 5px 12px 5px;
  }

  .edit-cashflow-container-main .edit-cashflow-week-month-wrp .budget-tabs .nav-link {
    padding: 10px 90px !important;
    font-weight: bold;
  }

  /* Your account balances css */

  .your-account-balances-container-main .acccount-balances-wrappper-left-side,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side {
    width: 50% !important;
  }

  .your-account-balances-container-main .your-account-bal-add-cash {
    margin-top: 30px;
    width: 50%;
  }

  /* Connect your acccount css */

  .connect-your-accounts-main-wrapper .connect-your-acccount-title {
    width: 100%;
  }

  .connect-your-accounts-main-wrapper .re-sync-connection-section {
    width: 33% !important;
    margin-top: 15px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal {
    width: 45% !important;
    margin-top: 15px !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 13px 12px 13px 12px !important;
    align-items: baseline;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    font-size: 15px;
    padding: 15px !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn i {
    font-size: 22px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-back-portal
    .setting_background_color
    .profile-dashboard
    h3 {
    font-size: 18px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection {
    width: 100% !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .bank-connection .bank-connection-img img {
    width: 100% !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .add-an-investment-conn-wrapper
    .investment-connection
    .investment-connection-img
    img {
    width: 100% !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .live-connection-main-wrapper {
    width: 100% !important;
  }

  .connect-your-accounts-main-wrapper .connect-account-akahu-box-wrp {
    width: 100% !important;
    margin-left: 0 !important;
  }

  /* Your Merchants Css */

  .your-merchants-main-wrapper .your-merchants-back-to-portal {
    width: 50%;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp {
    margin-bottom: 20px;
  }

  /* Dashboard inner page csss */

  .client-dashboard-main-wrapper .income-exp-mnth {
    width: 100% !important;
    margin-bottom: 30px;
  }

  .client-dashboard-main-wrapper .income-exp-mnth .recent-see-all h5 {
    font-size: 17px;
  }

  .client-dashboard-main-wrapper .top-5-expenses-wrapper,
  .client-dashboard-main-wrapper .top-5-merchants-wrapper {
    width: 50%;
  }
  .client-dashboard-main-wrapper .top-5-expenses-wrapper h5,
  .client-dashboard-main-wrapper .top-5-merchants-wrapper h5 {
    font-size: 17px;
  }

  .client-dashboard-main-wrapper .top-5-merchants-wrapper .sideblck {
    padding: 12px 13px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .client-dashboard-main-wrapper .spend-budget h5 {
    font-size: 20px !important;
  }
  .client-dashboard-main-wrapper .spend-budget .right-section select {
    font-size: 15px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all h5 {
    font-size: 20px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all .see-all {
    font-size: 15px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck h5 {
    font-size: 16px;
  }
  .client-dashboard-main-wrapper .weekly-cahflowcart {
    width: 100% !important;
  }

  .client-dashboard-main-wrapper .income-exp-mnth h5,
  .client-dashboard-main-wrapper .weekly-cahflowcart h5,
  .client-dashboard-main-wrapper .net-worth-progress-side h5,
  .client-dashboard-main-wrapper .account-balances-side .title-heading h5 {
    font-size: 20px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .status-btn .percentage {
    padding: 6px 20px !important;
    font-size: 13px !important;
  }

  .client-dashboard-main-wrapper .account-balances-side .blkc .saving-head h4 {
    font-size: 13px;
  }

  .client-dashboard-main-wrapper .account-balances-side .blkc .saving-amt .number-heading {
    font-size: 24px;
  }

  .client-dashboard-main-wrapper .account-balances-side .blkc .right-section .img {
    width: 40px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .date-time-blck h4 {
    font-size: 16px !important;
  }

  /* ----Edit your budget page------- */

  .edit-your-budget-main-wrapper .edit-your-budget-heading-wrp {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .edit-your-budget-main-wrapper .edit-btn {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    font-size: 15px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .profile-dashboard h3 {
    font-size: 17px !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color {
    padding: 15px 15px 15px 15px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 10px !important;
    font-size: 13px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .first-table-edit-budget {
    overflow-x: scroll !important;
  }
  .edit-your-budget-main-wrapper .first-table-edit-budget .table-horizontal .table-image {
    width: 150% !important;
  }
  .edit-your-budget-main-wrapper .second-table-edit-budget {
    overflow-x: scroll !important;
  }
  .edit-your-budget-main-wrapper .second-table-edit-budget .table-horizontal .table-image {
    width: 120% !important;
  }

  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper {
    overflow-x: scroll !important;
  }

  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper .regular-account-transfer-inner {
    width: 150% !important;
  }

  .edit-your-budget-main-wrapper .new-table tbody th:first-child {
    width: 10% !important;
  }

  /* ----Edit your budget page end */

  /* your details and setting */

  .main-page-details-settings .your-payment-methods-wrp .comn-btn {
    padding: 12px 6px;
  }

  .main-page-details-settings .profile-img img {
    width: 40px;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 50% !important;
  }

  .group-summary-page-table .table-text-color,
  .group-summary-page-table .table-content {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 979px) {
  /* .comn-btn {
    padding: 8px 10px;
    margin-left: 20px;
  } */
  .cancel-plan-btn {
    background-color: rgba(208, 79, 79, 1);
    padding: 16px 82px !important;
  }
  .setting_text_color {
    margin-bottom: 10px;
  }
  .profile-img {
    margin-left: 25px;
  }
  .header-wrp .profile-img {
    margin-left: 0;
  }

  .app-sidebar {
    width: 183px;
    padding: 20px 20px;
    min-width: 186px;
    left: unset;
  }
  .vertical-nav-menu a {
    display: inline-flex;
    padding: 13px 15px;
  }
  .col-12.table-horizontal {
    overflow-x: auto;
  }
  .share-req {
    margin-top: 20px;
  }
  .profile-img img {
    width: 50px;
  }
  .topnav {
    display: block;
  }
  .header-wrp {
    padding: 30px 0px 62px 0px;
  }
  .topnav a.icon {
    display: inline-flex;
    position: absolute;
    left: 0;
    top: -26%;
    right: unset;
    background-color: unset;
  }
  .col-md-6.right-section {
    align-items: unset;
  }
  .profile-img {
    display: inline-table;
  }
  /* .comn-btn { */
  /* margin-top: 8px; */
  /* font-size: 12px; */
  /* padding: 10px 10px; */
  /* } */
  .app-sidebar.sidebar-show {
    display: none;
  }
  .app-main__outer {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 135px;
  }
  .bg-salmon {
    position: relative;
    display: block !important;
    margin-top: 50px;
  }
  .bg-salmon .topnav a.icon {
    top: -2%;
  }
  .button-grp,
  .super-advice {
    display: none;
  }
  .dashboard-wrp .col-md-12 {
    margin-top: 30px;
  }
  .dashboard-wrp .col-md-12:first-child {
    margin-top: 0px;
  }
  .search-spacing {
    margin-top: 13px !important;
  }
  .profile-img img {
    width: 37px;
  }
  .header-wrp {
    padding: 15px 0px 45px 0px;
  }
  .nav-link {
    padding: 7px 46px !important;
  }
  .button {
    justify-content: start;
    margin-top: 25px;
  }

  .row.main-header-div {
    width: 50%;
    display: inline-flex;
    flex-wrap: nowrap;
    /* align-items: flex-start; */
    align-content: flex-end;
  }

  .modal-popup-btn-wrp {
    display: flex;
    justify-content: center;
  }

  /* ---------------------------Css from 768 min-width to 978 max-width--------------- */

  .big-head-new {
    font-size: 26px !important;
  }
  .big-head-new .text-jeremy {
    font-size: 24px;
  }

  .top-head .light-grey {
    font-size: 12px;
  }

  .setting_background_color {
    padding: 15px 15px 20px 15px;
  }

  .profile-dashboard h3 {
    font-size: 13px;
  }
  /* .main-wrapper .profile-dashboard img {
    width: 35px;
  } */
  .profile-dashboard .comn-btn img {
    width: 23px;
  }
  .col-md-4.col-lg-4.col-sm-12.col-xl-3.purple-block:nth-child(4) {
    margin-top: 15px;
  }

  .med-head {
    font-size: 13px;
  }
  .big-head {
    font-size: 25px;
  }

  .right h4 {
    font-size: 10px;
  }
  .right svg {
    width: 22px;
    height: 22px;
    margin-left: 2px;
  }

  /* .total-clients {
    padding: 10px 3px;
  } */
  .top-wrp {
    display: flex;
    justify-content: unset;
  }
  /* .total-clients .profile-dashboard:first-child {
    font-size: 14px;
  }

  .total-clients .profile-dashboard {
    margin: 0;
  } */

  .right-section select {
    font-size: 8px;
    padding: 5px 15px;
  }
  .margin-nu {
    margin-top: 35px;
    margin-left: 10px;
  }
  .margin-nu .number-heading {
    font-size: 17px;
  }
  .main-wrapper .percentage {
    width: 45px;
    font-size: 10px;
    margin-left: 10%;
    line-height: 16.5px;
  }
  .text-client {
    font-size: 11px;
    line-height: 20px;
  }
  .bar-cart canvas.barcart {
    height: 380px !important;
    margin-bottom: 25px;
  }

  .cashflocart .left {
    margin-top: 20px;
  }

  .cashflocart canvas.barcart {
    height: 380px !important;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .sideblck {
    padding: 10px 15px;
  }

  .sideblck .name-merchant h5 {
    font-size: 16px;
  }
  .bar-cart h5 {
    font-size: 14px;
  }

  .income-exp-mnth h5 {
    font-size: 14px;
  }

  .income-exp-mnth .right-section select {
    font-size: 13px;
  }

  .top-expenses h5 {
    font-size: 14px;
  }

  .top-expenses .right-section select {
    font-size: 13px !important;
  }

  .spend-budget h5 {
    font-size: 14px !important;
  }

  .spend-budget .see-all {
    font-size: 13px;
  }

  .spend-budget .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction h5 {
    font-size: 14px !important;
  }

  .recent-transaction .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction .see-all {
    font-size: 13px !important;
  }

  .bar-cart .right-section select {
    font-size: 12px;
  }

  /* .total-clients .right-section select {
    padding: 5px 13px;
    background-position: right -1px center;
  } */
  .sml-blck h4 {
    font-size: 12px;
  }
  .sml-blck .number-heading {
    font-size: 18px;
  }

  /* .right-section {
    align-items: center;
    justify-content: flex-end;
  } */
  .see-all {
    font-size: 12px;
  }
  .date-time-blck h4 {
    font-size: 13px;
  }
  .transaction-amt h4 {
    font-size: 13px;
  }

  .status-btn .percentage {
    width: 85px;
  }

  /* New csss for net-worth */

  .net-worth-main .back-portal-btn .comn-btn {
    padding: 12px 6px;
  }

  .net-worth-main .left-section .med-head {
    font-size: 18px;
  }
  .net-worth-main .left-section .big-head {
    font-size: 36px;
  }
  .net-worth-main .left-section .med-new-font {
    font-size: 16px;
  }
  .net-worth-main .left-section .full-breakdown-arrow {
    padding: 0px 10px 0px 0px;
  }

  .net-worth-main .left-section .full-breakdown-arrow h4 {
    font-size: 14px;
  }

  .net-worth-main .left-section .full-breakdown-arrow h4 svg {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }

  .net-worth-main .back-portal-btn .comn-btn {
    font-size: 13px !important;
  }
  .net-worth-main .back-portal-btn .profile-dashboard .comn-btn img {
    width: 20px !important;
  }
  .net-worth-main .back-portal-btn .profile-dashboard .profile-img img {
    width: 40px;
  }
  .net-worth-main .back-portal-btn .profile-dashboard h3 {
    font-size: 14px !important;
  }

  .net-worth-main .assets-wrp .asset-wrp-first-side {
    width: 100%;
    margin-bottom: 20px;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side {
    width: 100%;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side .saving-head h4 {
    font-size: 16px;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side .saving-head {
    padding: 20px 70px 40px 70px;
  }

  .net-worth-main .assets-wrp .asset-wrp-second-side .light-green-new {
    font-size: 35px;
  }
  .net-worth-main .assets-wrp .asset-wrp-second-side.this-week {
    font-size: 15px;
    font-weight: 500;
  }

  .net-worth-main .add-asset-btn-first .comn-btn {
    font-size: 14px;
  }

  /* liability wrp */

  .net-worth-main .liability-wrp .liability-wrp-first-side {
    width: 100%;
    margin-bottom: 20px;
  }

  .net-worth-main .liability-wrp .liability-wrp-second-side {
    width: 100%;
  }

  .net-worth-main .liability-wrp .liability-wrp-second-side .saving-head h4 {
    font-size: 16px;
  }

  .net-worth-main .liability-wrp .liability-wrp-second-side .saving-head {
    padding: 20px 70px 40px 70px;
  }

  .net-worth-main .liability-wrp .liability-wrp-second-side .light-green-new {
    font-size: 35px;
  }
  .net-worth-main .liability-wrp .left-section .this-week {
    font-size: 15px;
    font-weight: 500;
  }

  .net-worth-main .add-liability-btn-first .comn-btn {
    font-size: 14px;
  }

  /* New cssss your financial goals */

  .financial-goals-main .main-heading-financial {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .edit-btn-financial {
    width: 45% !important;
  }

  .financial-goals-main .back-btn-financial {
    width: 40% !important;
  }

  .financial-goals-main .back-to-portal-financial {
    width: 50% !important;
    justify-content: flex-start;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 3px 3px 10px 5px !important;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 13px;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn i {
    margin-left: 2px;
    font-size: 20px;
  }

  /* Css for Retirement planner */

  .retirement-plan-main .back-portal-retirement .comn-btn {
    padding: 8px 5px;
  }

  .retirement-plan-main .your-contribution-wrapper {
    width: 100%;
  }
  .retirement-plan-main .contribution-right-side {
    width: 100%;
  }
  .retirement-plan-main .contribution-right-side .left-section {
    width: 100% !important;
  }

  .retirement-plan-main .retirement-section-2-right-wrp .retirement-section-2-right-side {
    width: 100% !important;
  }
  .retirement-plan-main .veteran-pension-payment-second {
    width: 50%;
  }

  .retirement-plan-main .veteran-pension-payment-second .your-account-Chevronleft-icon {
    right: 2px;
  }
  .retirement-plan-main .contribution-right-side .big-head {
    font-size: 40px;
  }

  /* Edit cashflow css */

  .edit-cashflow-container-main .edit-cashflow-title-heading {
    width: 100%;
  }

  .edit-cashflow-container-main .view-calendar-cashflow {
    width: 50%;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow {
    width: 50%;
    margin-top: 10px;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color {
    padding: 7px 7px 12px 2px !important;
  }

  /* Your transaction */

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn {
    padding: 12px 6px !important;
  }

  .your-transaction-main-container .filter-transaction-wrapper .for-speed-range-section .mt-3,
  .your-transaction-main-container .filter-transaction-wrapper .for-date-range-section .mt-3 {
    margin-top: 3px !important;
  }

  /* Your account balances css */

  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .text-merchant,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .text-merchant {
    font-size: 14px;
  }

  .your-account-balances-container-main .your-account-balance-wrp-logo-img {
    height: 35px;
    width: 35px;
  }

  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .account-number,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .account-number {
    font-size: 14px;
  }

  .your-account-balances-container-main .your-account-bal-add-cash {
    margin-top: 30px;
    width: 60%;
  }

  .your-account-balances-container-main .back-portal-account-balances .setting_background_color .comn-btn {
    padding: 12px 6px !important;
  }
  .your-account-balances-container-main .acccount-balances-wrappper-left-side .account-border {
    padding: 5px 9px 7px 5px;
  }

  .your-setting-page-container .your-setting-page-back-portal .setting_background_color .comn-btn {
    padding: 12px 5px;
  }

  /* Connect your acccounts  css*/

  .connect-your-accounts-main-wrapper .re-sync-connection-section {
    width: 35% !important;
  }
  .connect-your-accounts-main-wrapper .re-sync-connection-section .comn-btn {
    font-size: 14px !important;
    padding: 13px 8px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal {
    width: 50% !important;
    margin-top: 10px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 3px 7px 10px 7px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    font-size: 12px;
    padding: 10px 6px !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-back-portal
    .setting_background_color
    .profile-dashboard
    h3 {
    font-size: 13px !important;
  }

  /* Your Merchants Css */

  .your-merchants-main-wrapper .your-merchants-back-to-portal {
    width: 53% !important;
  }

  /* Dashboard inner page css */

  .client-dashboard-main-wrapper .total-balances-card-wrp {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .client-dashboard-main-wrapper .total-balances-card-wrp .img-sec img {
    width: 40px;
    /* margin-left: 80px; */
  }

  .client-dashboard-main-wrapper .total-balances-card-wrp .med-head {
    font-size: 15px !important;
  }
  .client-dashboard-main-wrapper .total-balances-card-wrp .big-head {
    font-size: 35px !important;
  }

  .client-dashboard-main-wrapper .total-balances-card-wrp .total-balance-wrp .right h4 {
    font-size: 14px !important;
  }
  .client-dashboard-main-wrapper .total-balances-card-wrp .total-balance-wrp .right svg {
    width: 30px !important;
    height: 30px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp {
    width: 50% !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .profile-dashboard:first-child {
    font-size: 20px !important;
    margin-left: 10px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .margin-nu .number-heading {
    font-size: 26px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .margin-nu .percentage {
    font-size: 12px !important;
    line-height: 23px !important;
    padding: 3px 15px !important;
    margin-left: 22% !important;
    width: 85px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .text-client {
    font-size: 13px !important;
    margin-left: 10px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .top-wrp {
    display: flex !important;
    justify-content: space-between !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .top-wrp .right-section select {
    font-size: 13px !important;
  }

  .client-dashboard-main-wrapper .dashboard-inner-back-to-portal .setting_background_color .comn-btn {
    padding: 12px 6px !important;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container .top-jeremy-heading .text-jeremy {
    font-size: 28px;
  }

  .main-front-dashboard-container .dashboard-wrp .total-clients .first-heading-total {
    font-size: 17px;
    margin-left: 10px;
  }
  .main-front-dashboard-container .dashboard-wrp .total-clients .margin-nu .number-heading {
    font-size: 28px;
  }
  .main-front-dashboard-container .dashboard-wrp .total-clients .margin-nu .percentage {
    margin-left: 50px;
  }
  .main-front-dashboard-container .dashboard-wrp .total-clients .text-client {
    margin-left: 10px;
  }

  /* Edit budget css page */

  .edit-your-budget-main-wrapper .edit-your-budget-heading-wrp {
    margin-bottom: 0;
  }

  .edit-your-budget-main-wrapper .edit-budget-view-summary {
    width: 45% !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp {
    margin-top: 20px;
    width: 50% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color {
    padding: 7px 7px 11px 7px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 4px !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 40%;
    margin-left: 9%;
  }

  /* your details and setting */

  .main-page-details-settings .your-payment-methods-wrp .comn-btn {
    padding: 12px 6px;
  }

  .main-page-details-settings .profile-img img {
    width: 40px;
  }

  .main-page-details-settings .profile-img {
    margin-left: 0px !important;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .top-heading-wrp-main {
    width: 50% !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 45% !important;
  }
}
@media only screen and (max-width: 767px) {
  select.form-select.input_border {
    margin-top: 24px;
  }
  .col-md-6.col-sm-12.mb-5.plan-details {
    margin-bottom: 15px !important;
  }
  .nav-link {
    padding: 7px 105px !important;
  }
  ul#pills-tab {
    margin-top: 20px;
  }
  .col-md-5.mt-4.last-advisor {
    padding-bottom: 70px;
  }
  .blkc {
    width: 100%;
  }
  /* .cmn-blck {
    margin-top: 15px !important;
  } */

  .income-expenses .col-sm-12 {
    width: 50%;
    margin-top: 30px;
  }

  .total-clients {
    padding: 15px 20px;
    width: 100%;
  }

  .total-clients .top-wrp {
    display: flex;
    justify-content: space-between;
  }
  .total-clients .profile-dashboard:first-child {
    font-size: 20px;
  }
  .total-clients .right-section select {
    font-size: 12px;
  }
  .margin-nu .number-heading {
    font-size: 25px;
  }
  .main-wrapper .percentage {
    width: 85px;
    font-size: 13px;
    margin-left: 20%;
    line-height: 20.5px;
  }
  .bar-cart h5 {
    font-size: 16px;
  }
  .bar-cart .right-section select {
    font-size: 13px;
  }
  .recent-transaction h5 {
    font-size: 14px !important;
  }

  .recent-transaction .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction .see-all {
    font-size: 13px !important;
  }

  /* .col-md-6:nth-child(2) {
    width: 50%;
    flex: 0 0 auto;
    margin-top: 10px;
  }
  .col-md-6:nth-child(3) {
    width: 50%;
    flex: 0 0 auto;
    margin-top: 10px;
  } */

  .bar-cart canvas.barcart {
    height: 320px !important;
  }
  .col-md-6.col-lg-3.bar-cart.top-expenses {
    width: 50%;
    flex: 0 0 auto;
  }
  .income-exp-mnth .right-section select {
    font-size: 13px;
  }
  .col-md-12.bar-cart.spend-budget {
    display: flex;
    /* align-content: center; */
    justify-content: space-between;
    align-items: flex-start;
  }

  .right-section {
    justify-content: flex-start;
    align-items: center;
  }

  .recent-see-all {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .spend-budget h5 {
    font-size: 14px;
  }

  .spend-budget .right-section select {
    font-size: 13px;
  }

  .sml-blck .number-heading {
    font-size: 20px;
  }

  .med-head {
    font-size: 18px;
  }

  .big-head {
    font-size: 35px;
  }
  .col-md-7.img-sec img {
    width: 40px;
  }
  .right h4 {
    font-size: 14px;
  }
  .right svg {
    width: 26px;
    height: 26px;
    margin-left: 2px;
  }

  .app-sidebar__inner .topnav {
    display: block !important;
  }
  .topnav a.icon {
    display: inline-flex;
    position: absolute;
    left: 0;
    top: -26%;
    right: unset;
    background-color: unset;
  }

  .bg-salmon {
    display: block !important;
  }

  .net-worth-main .back-portal-btn .comn-btn {
    padding: 12px 12px;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 13px;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 3px 3px 10px 3px !important;
  }

  .financial-goals-main .edit-btn-financial .edit-btn .right svg {
    height: 30px;
    width: 30px;
  }

  .financial-goals-main .back-btn-financial .edit-btn .right svg {
    height: 30px;
    width: 30px;
  }

  /* Edit cashflow css */

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color {
    padding: 7px 3px 12px 0px !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color .comn-btn {
    font-size: 13px !important;
    padding: 13px 5px !important;
  }

  /* your transaction css */

  .your-transaction-main-container .filter-transaction-wrapper .for-speed-range-section .mt-3,
  .your-transaction-main-container .filter-transaction-wrapper .for-date-range-section .mt-3 {
    margin-top: 20px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color .comn-btn {
    padding: 12px 12px !important;
    font-size: 14px;
  }

  .your-account-balances-container-main .acccount-balances-wrappper-left-side .account-border {
    padding: 5px 9px 7px 5px;
  }

  /*your  setting page */
  .your-setting-page-container .your-setting-page-back-portal .setting_background_color .comn-btn {
    padding: 12px 12px;
    font-size: 14px;
  }

  /* connect your accounts */

  .connect-your-accounts-main-wrapper .re-sync-connection-section {
    width: 40% !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal {
    width: 60% !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .bank-connection .bank-connection-img img {
    width: 80% !important;
  }

  /* Dashboard inner page css new */

  .client-dashboard-main-wrapper .total-balances-card-wrp {
    margin-bottom: 0;
  }

  .client-dashboard-main-wrapper .account-balances-side .saving-acc-wrp .cmn-blck {
    margin-bottom: 20px;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container
    .clients-with-share-rqst-wrapper
    .table-horizontal
    .table-image
    .cursor-pointer
    .amount-active {
    padding: 1px 7px;
    margin-left: 15px;
  }

  /* Edit budget css */

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 3px !important;
    font-size: 12px !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 23%;
    margin-left: 7%;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .expenses-transfers-section {
    padding: 0px 20px;
    width: 35%;
  }

  /* main Page details and setting */
  .main-page-details-settings .your-contact-details-side .profile-image-side {
    width: 80% !important;
  }
  .main-page-details-settings .your-contact-details-side .profile-image-side .input-group {
    width: 100% !important;
  }

  .main-page-details-settings .your-contact-details-side .profile-logo-side {
    width: 10%;
  }

  .main-page-details-settings .your-contact-details-side .profile-logo-side .profile-img {
    margin-left: 30px !important;
  }
  .main-page-details-settings .your-contact-details-side .profile-div-wrp {
    display: flex;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .top-heading-wrp-main {
    width: 100% !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 60% !important;
  }
}

@media only screen and (max-width: 649px) {
  .col-md-6.col-sm-12.mb-5.plan-details {
    margin-bottom: 15px !important;
  }
  .nav-link {
    padding: 7px 105px !important;
  }
  ul#pills-tab {
    margin-top: 20px;
  }
  .col-md-5.mt-4.last-advisor {
    padding-bottom: 70px;
  }
  .blkc {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  .bar-cart canvas.barcart {
    height: 350px !important;
  }
  /* .cmn-blck {
    margin-top: 1rem !important;
  } */
}
@media only screen and (max-width: 649px) {
  .nav-link {
    padding: 7px 65px !important;
  }

  .income-exp-mnth h5 {
    font-size: 16px !important;
  }

  .top-expenses h5 {
    font-size: 15px !important;
  }
  .top-expenses .right-section select {
    font-size: 13px;
  }

  .recent-transaction h5 {
    font-size: 16px !important;
  }

  .recent-transaction .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction .see-all {
    font-size: 13px !important;
  }

  .spend-budget h5 {
    font-size: 16px !important;
  }

  .spend-budget .right-section select {
    font-size: 13px !important;
  }

  .bar-cart canvas.barcart {
    height: 300px !important;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 11px;
    padding: 12px 5px;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn i {
    margin-left: 3px;
    font-size: 18px;
  }

  /* New css retirement planner */

  .retirement-plan-main .table-horizontal .retirement-bg {
    width: 650px;
  }

  .retirement-plan-main .account-assets-wrp {
    width: 100%;
  }
  .retirement-plan-main .total-amt-now-box {
    width: 100%;
  }
  .retirement-plan-main .total-amt-now-box .account-assets-total-amt {
    width: 100% !important;
  }

  .retirement-plan-main .retirement-section-2 {
    width: 100%;
    margin-bottom: 20px;
  }
  .retirement-plan-main .retirement-section-2-right-wrp {
    width: 100% !important;
  }
  .retirement-plan-main .retirement-section-2-right-wrp .retirement-planner-wrp {
    text-align: center;
  }

  .retirement-plan-main .veteran-pension-payment-first {
    width: 100% !important;
  }
  .retirement-plan-main .veteran-pension-payment-second {
    width: 100%;
  }

  .retirement-plan-main .veteran-pension-payment-second .taxed-retirement .w-25 {
    width: 15% !important;
  }

  /* edit cashflow css */

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color .comn-btn {
    font-size: 12px !important;
    padding: 13px 3px !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard .comn-btn img {
    width: 18px;
    margin-left: 1px;
  }

  /* Your account balances csss */

  .your-account-balances-container-main .acccount-balances-wrappper-left-side,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side {
    width: 100% !important;
  }
  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .text-merchant,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .text-merchant {
    font-size: 16px;
  }
  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box .account-number,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box .account-number {
    font-size: 16px;
  }
  .your-account-balances-container-main .your-account-balance-wrp-logo-img {
    height: 40px;
    width: 40px;
  }

  /* Your merchants page css */

  .your-merchants-main-wrapper .your-merchants-back-to-portal {
    width: 60% !important;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image {
    width: 170% !important;
  }
  .main-front-dashboard-container
    .clients-with-share-rqst-wrapper
    .table-horizontal
    .table-image
    .cursor-pointer
    .amount-active {
    padding: 3px 10px;
    margin-left: 25px;
  }

  .main-front-dashboard-container .sub-heading-top {
    font-size: 14px;
  }

  /* Edit your budget css */

  .edit-your-budget-main-wrapper .edit-budget-view-summary {
    width: 60% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp {
    width: 60% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 12px !important;
    font-size: 13px !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 20%;
    margin-left: 6%;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .top-heading-wrp-main {
    width: 100% !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 70% !important;
  }
}
@media only screen and (max-width: 599px) {
  /* .col-md-6.right-section {
    align-items: unset;
    width: 44%;
  } */
  .col-md-6.col-sm-4.left {
    width: 45%;
  }

  .header-wrp .col-md-6.col-sm-4.right-section {
    width: 25%;
  }
  .header-wrp {
    padding: 20px 0px 45px 0px;
  }
  .merchant-box {
    display: block !important;
  }
  .select-category {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .right-section {
    justify-content: flex-start;
  }
  /* .col-md-6.right-section {
    align-items: unset;
    width: 16%;
  } */

  .med-head {
    font-size: 15px;
  }

  .big-head {
    font-size: 35px;
  }

  .col-md-12.main-blck {
    margin-top: 1rem !important;
  }
  .col-md-12.col-lg-12.col-sm-12.col-xl-12.monthly-section {
    margin-top: 1rem;
  }

  .col-md-6.col-lg-3.bar-cart.top-expenses {
    width: 48%;
  }
  .sideblck {
    margin-top: 15px;
  }

  .blkc {
    margin-top: 15px;
  }

  .sideblck .name-merchant h5 {
    font-size: 14px !important;
  }

  .sideblck .name-merchant h6 {
    font-size: 11px !important;
  }

  .sideblck .date-time-blck h4 {
    font-size: 11px;
  }
  .sideblck .date-time-blck span {
    font-size: 11px;
  }

  .total-clients .percentage {
    width: 60px !important;
    font-size: 11px !important;
  }

  .total-clients .profile-dashboard:first-child {
    font-size: 18px;
  }
  .total-clients .right-section select {
    font-size: 11px;
  }

  .income-exp-mnth h5 {
    font-size: 16px !important;
  }

  .top-expenses h5 {
    font-size: 14px !important;
  }
  .top-expenses .right-section select {
    font-size: 10px !important;
    padding: 5px 16px;
  }
  /* .bar-cart canvas.barcart{
    height: 320px  !important;
  } */

  .recent-transaction h5 {
    font-size: 16px !important;
  }

  .recent-transaction .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction .see-all {
    font-size: 13px !important;
  }

  .spend-budget h5 {
    font-size: 16px !important;
  }

  .spend-budget .right-section select {
    font-size: 13px !important;
  }

  .sideblck .percentage {
    margin-left: 7px;
  }

  .col-md-6.mb-4.left {
    margin-bottom: 0px !important;
  }
  .main-wrapper .app-main__outer {
    padding-left: 0px;
    padding-right: 0px;
  }

  /* new css financial goals */
  .financial-goals-main .edit-btn-financial {
    width: 56% !important;
    margin-bottom: 20px;
  }
  .financial-goals-main .back-btn-financial {
    width: 52% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .back-to-portal-financial {
    width: 60% !important;
    justify-content: flex-start;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 5px 3px 12px 5px !important;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn i {
    margin-left: 0px;
    font-size: 18px;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 12px;
    padding: 12px 10px;
  }

  /* Retirement planner css */

  .retirement-plan-main .account-assets-wrp .merchant-box,
  .retirement-plan-main .your-contribution-wrapper .merchant-box,
  .retirement-plan-main .retirement-section-2 .merchant-box,
  .retirement-plan-main .veteran-pension-payment-first .merchant-box {
    display: flex !important;
  }

  .retirement-plan-main .your-contribution-wrapper .contribution-inner-left-side .merchant-box .saving-account-title {
    margin-left: 20px;
  }

  /* Edit cashflow css */

  .edit-cashflow-container-main .view-calendar-cashflow {
    width: 100%;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow {
    width: 100%;
    margin-top: 10px;
  }

  /* Your account balances css */

  .your-account-balances-container-main .acccount-balances-wrappper-left-side .merchant-box,
  .your-account-balances-container-main .acccount-balances-wrappper-right-side .merchant-box {
    font-size: 16px;
    display: flex !important;
  }

  .your-account-balances-container-main .back-portal-account-balances .setting_background_color .comn-btn {
    padding: 12px 12px !important;
    font-size: 14px;
  }

  .your-account-balances-container-main .your-account-bal-add-cash {
    width: 100%;
  }

  /* Connect your accounts */

  .connect-your-accounts-main-wrapper .re-sync-connection-section {
    width: 100% !important;
  }

  .connect-your-accounts-main-wrapper .re-sync-connection-section .comn-btn {
    font-size: 16px !important;
    padding: 13px 12px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal {
    width: 100% !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 5px 15px 12px 15px !important;
  }
  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    font-size: 14px;
    padding: 12px 12px !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-back-portal
    .setting_background_color
    .profile-dashboard
    h3 {
    font-size: 16px !important;
  }

  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .merchant-box {
    display: flex !important;
  }

  /* Your merchants page css */

  .your-merchants-main-wrapper .your-merchants-back-to-portal {
    width: 100% !important;
  }

  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .comn-btn,
  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .profile-dashboard h3 {
    font-size: 15px !important;
  }

  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .comn-btn i {
    margin-right: 10px;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box {
    display: flex !important;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .select-category {
    width: 50% !important;
  }

  /* Dashboard inener page css */

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck h5 {
    font-size: 14px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
    padding: 10px 6px !important;
  }

  .client-dashboard-main-wrapper .weekly-cahflowcart canvas.barcart {
    margin-top: 15px !important;
  }

  /* Dashboard inner page css */
  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .profile-dashboard:first-child {
    margin-left: 0px !important;
    font-size: 16px !important;
  }
  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .margin-nu .number-heading {
    font-size: 24px !important;
  }
  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .margin-nu {
    margin-left: 0px !important;
  }
  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .text-client {
    margin-left: 0px !important;
  }

  .client-dashboard-main-wrapper .top-5-expenses-wrapper h5,
  .client-dashboard-main-wrapper .top-5-merchants-wrapper h5 {
    font-size: 12px !important;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image {
    width: 220% !important;
  }

  .main-front-dashboard-container .top-jeremy-heading .text-jeremy {
    font-size: 26px;
  }

  /* Edit your budget css */

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp {
    width: 70% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 18%;
    margin-left: 4%;
  }

  /* Main front clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image {
    width: 200% !important;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 100% !important;
  }
  /* Your groups */

  .your-groups-main-front-wrapper .your-groups-page-table-list .table-image {
    width: 200% !important;
  }
}
@media only screen and (max-width: 479px) {
  .nav-link {
    padding: 7px 48px !important;
  }
  .nav {
    flex-direction: column;
    justify-content: center;
  }
  .nav-link {
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }
  .button {
    margin-right: 0px;
  }
  .bank-connection {
    display: block;
  }
  .bank-connection-img {
    display: inline-block;
    margin: 15px;
  }
  .investment-connection-img {
    display: inline-block;
    margin: 15px;
  }
  .investment-connection {
    display: flex;
  }
  .col-md-6.col-sm-4.left {
    width: 50%;
  }

  .bar-cart canvas.barcart {
    height: 260px !important;
  }
  /* .col-md-6.right-section {
    align-items: unset;
    width: 19%;
  } */

  /* Mobile view css */

  .income-exp-mnth h5 {
    font-size: 16px !important;
  }

  .income-exp-mnth .right-section select {
    font-size: 13px !important;
  }

  .top-expenses h5 {
    font-size: 16px !important;
  }
  .top-expenses .right-section select {
    font-size: 13px !important;
    padding: 5px 17px !important;
  }

  .recent-transaction h5 {
    font-size: 16px !important;
  }

  .recent-transaction .right-section select {
    font-size: 13px !important;
  }

  .recent-transaction .see-all {
    font-size: 13px !important;
  }

  .spend-budget h5 {
    font-size: 16px !important;
  }

  .spend-budget .right-section select {
    font-size: 13px !important;
  }

  .big-head-new {
    font-size: 24px;
  }
  .big-head-new .text-jeremy {
    font-size: 24px;
  }

  .top-head .light-grey {
    font-size: 13px !important;
  }

  /* .right-section .setting_background_color {
    display: inline-flex;
    width: 300px;
  } */

  .right-section .setting_background_color .profile-dashboard:first-child .comn-btn {
    font-size: 14px;
  }

  .income-expenses .col-sm-12 {
    width: 100%;
    flex: 0 0 auto;
  }

  .total-clients .profile-dashboard:first-child {
    font-size: 20px;
  }
  .total-clients .right-section select {
    font-size: 11px;
  }
  .margin-nu .number-heading {
    font-size: 28px;
  }
  .text-client {
    font-size: 12px;
  }
  .col-md-6.col-lg-3.bar-cart.top-expenses {
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  .total-clients .percentage {
    width: 85px;
    margin-left: 35%;
    font-size: 14px;
  }

  /* .see-all {
    font-size: 13px;
  } */
  /*
  .sideblck .name-merchant h5 {
    font-size: 11px;
  }
  .sideblck .date-time-blck h4 {
    font-size: 9px;
  }
  .sideblck .date-time-blck span {
    font-size: 9px;
  } */
  /* .sideblck .percentage {
    margin-left: 1px;
    width: 50px;
    font-size: 8px;
  } */

  /* .sideblck {
    padding: 10px 7px;
  } */
  /* .transaction-amt h4 {
    font-size: 11px;
  } */
  .sml-blck h4 {
    font-size: 13px;
  }
  .sml-blck .number-heading {
    font-size: 22px;
  }

  .bar-cart canvas.barcart {
    height: 240px !important;
  }

  /* financial goals */

  /* new css financial goals */
  .financial-goals-main .edit-btn-financial {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .back-btn-financial {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .financial-goals-main .back-to-portal-financial {
    width: 100% !important;
    justify-content: flex-start;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 8px 11px 12px 12px !important;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn i {
    margin-left: 0px;
    font-size: 20px;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 14px;
    padding: 12px 20px;
  }

  .retirement-plan-main .veteran-pension-payment-second .taxed-retirement .w-25 {
    width: 20% !important;
  }

  /* Connect your accounts */

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .bank-connection .bank-connection-img img {
    width: 100% !important;
  }

  /* Your merchants page css */

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .select-category {
    width: 33% !important;
  }

  /* Dashboard inner page css */

  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp {
    width: 100% !important;
  }

  .client-dashboard-main-wrapper .income-exp-mnth {
    margin-bottom: 0px !important;
  }

  .client-dashboard-main-wrapper .top-5-expenses-wrapper h5,
  .client-dashboard-main-wrapper .top-5-merchants-wrapper h5 {
    font-size: 16px !important;
  }

  .client-dashboard-main-wrapper .top-5-merchants-wrapper .transaction-amt h4 {
    font-size: 15px !important;
  }
  .client-dashboard-main-wrapper .top-5-merchants-wrapper .sideblck .name-merchant h6 {
    font-size: 13px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
    width: 600px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper {
    overflow-x: scroll !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .date-time-blck {
    margin-left: 50px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .date-time-blck h4 {
    font-size: 13px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .transaction-amt h4 {
    font-size: 15px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h6 {
    font-size: 13px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck h5 {
    font-size: 15px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all {
    width: 580px !important;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image {
    width: 280% !important;
  }

  .main-front-dashboard-container .top-jeremy-heading .text-jeremy {
    font-size: 25px;
  }

  /* Edit your budget css */

  .edit-your-budget-main-wrapper .edit-budget-view-summary {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 14%;
    margin-left: 2%;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .expenses-transfers-section {
    padding: 0px 8px;
  }

  .edit-your-budget-main-wrapper .reoccuring-wrp {
    margin-top: 50px !important;
  }

  /* Main front clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image {
    width: 270% !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  /* Your groups */

  .your-groups-main-front-wrapper .your-groups-page-table-list .table-image {
    width: 260% !important;
  }
}
@media only screen and (max-width: 374px) {
  .app-main__outer {
    overflow-x: hidden;
  }
  .button {
    flex-direction: column !important;
  }
  .comn-btn {
    margin-left: 1px !important;
  }

  .bar-cart canvas.barcart {
    height: 220px !important;
  }

  .setting_background_color {
    padding: 10px 5px 10px 15px !important;
  }
  .button_white {
    background-color: #fdfdfd;
    width: 100% !important;
    height: 37px !important;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    color: #999999 !important;
  }
  .button {
    margin-top: 10px !important;
  }
  .col-md-6.right-section {
    align-items: unset;
  }
  .right-section .setting_background_color .profile-dashboard:first-child .comn-btn {
    font-size: 13px;
  }
  .col-md-7.img-sec img {
    width: 30px;
  }

  .right h4 {
    font-size: 10px;
  }
  .right svg {
    width: 22px;
    height: 22px;
    margin-left: 2px;
  }

  .income-exp-mnth h5 {
    font-size: 15px !important;
  }

  .income-exp-mnth .right-section select {
    font-size: 12px !important;
  }

  .top-expenses h5 {
    font-size: 15px !important;
  }
  .top-expenses .right-section select {
    font-size: 12px !important;
    padding: 5px 20px;
  }

  .recent-transaction h5 {
    font-size: 15px !important;
  }

  .recent-transaction .right-section select {
    font-size: 12px !important;
  }

  .recent-transaction .see-all {
    font-size: 12px !important;
  }

  .spend-budget h5 {
    font-size: 15px !important;
  }

  .spend-budget .right-section select {
    font-size: 12px !important;
  }

  .net-worth-main .left-section .med-new-font {
    font-size: 14px;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 14px;
    padding: 10px 14px;
  }
  .retirement-plan-main .veteran-pension-payment-second .taxed-retirement .w-25 {
    width: 25% !important;
  }

  /*your  setting page */
  .your-setting-page-container .your-setting-page-back-portal .setting_background_color .comn-btn {
    padding: 12px 5px;
    font-size: 12px;
  }

  /* Connect your accounts */

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    padding: 12px 4px !important;
    font-size: 12px !important;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-back-portal
    .setting_background_color
    .profile-dashboard
    h3 {
    font-size: 13px !important;
  }

  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .merchant-box
    img {
    width: 50px !important;
    height: 50px !important;
    margin-top: 10px;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .merchant-box
    .setting_text_color,
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .merchant-box
    .account-number {
    font-size: 13px;
  }

  /* Your merchants page css */

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .img-box {
    width: 35px;
    height: 35px;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box {
    padding: 12px 6px 12px 6px;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .select-category .form-select {
    font-size: 14px;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .text-merchant {
    font-size: 13px;
  }

  .your-merchants-main-wrapper .merchants-inner-wrp .merchant-box .small-text-merchant {
    font-size: 11px;
  }

  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .comn-btn,
  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .profile-dashboard h3 {
    font-size: 13px !important;
  }

  .your-merchants-main-wrapper .your-merchants-back-to-portal .setting_background_color .comn-btn {
    padding: 15px 5px !important;
  }

  /* dashboard inner page css */

  .client-dashboard-main-wrapper .total-balances-card-wrp .img-sec img {
    /* margin-left: 5px !important; */
    width: 30px !important;
  }

  .client-dashboard-main-wrapper .total-balances-card-wrp .total-balance-wrp .right h4 {
    font-size: 12px !important;
  }
  .client-dashboard-main-wrapper .dashboard-inner-income-expenses-wrp .total-clients .profile-dashboard:first-child {
    margin-left: 0px !important;
    font-size: 18px !important;
  }

  /* Main front dashboard page css */

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image {
    width: 330% !important;
  }

  .main-front-dashboard-container .top-jeremy-heading .text-jeremy {
    font-size: 24px;
  }

  /* Edit your budget page */
  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp {
    margin-bottom: 20px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 3px !important;
    font-size: 11px !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section .total-inc-first {
    width: 12%;
    margin-left: 1%;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper .total-income-block-section h4 {
    font-size: 15px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .expenses-transfers-section {
    padding: 0px 6px !important;
  }

  /* Main front clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image {
    width: 300% !important;
  }

  /* Your groups */
  .your-groups-main-front-wrapper .your-groups-page-table-list {
    overflow-x: scroll !important;
  }

  .your-groups-main-front-wrapper .your-groups-page-table-list .table-image {
    width: 300% !important;
  }
}
@media only screen and (max-width: 1375px) and (min-width: 1200px) {
  .dashboard-wrp .col-md-12 {
    margin-top: 0px;
  }
  .total-clients {
    padding: 13px;
  }
  .total-clients .comn-btn {
    margin-left: 0px;
    margin-top: 25px;
    padding: 11px 10px;
    font-size: 11px;
  }
  .nav-link {
    padding: 7px 66px !important;
  }

  .acc-num-head {
    font-size: 28px !important;
  }

  /* Connect your accounts */

  .investment-connection-img {
    margin-right: 15px;
  }

  /* Connect your accounts end */

  .main-front-dashboard-container .amount-active,
  .amount-inactive,
  .amount-activated {
    font-size: 11px !important;
  }

  .group-summary-page-main .table-image thead th:first-child {
    width: 32% !important;
    padding-left: 25px !important;
  }

  .group-summary-page-table td a {
    margin-left: 5% !important;
  }

  .main-front-dashboard-container .air-new-zealand-tab {
    margin-top: 10px !important;
  }
  .group-details-page .table-image thead th {
    padding: 10px 10px !important;
  }

  .monthly-section .income-merchant {
    width: 48% !important;
  }
  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 7px !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-content,
  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-text-color {
    font-size: 15px !important;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper td a {
    margin-left: 2% !important;
  }

  .edit-group-page-main .upload-csv-file .profile-img img {
    width: 50px;
  }

  .edit-group-page-main .client-summary-page-main .client-name {
    font-size: 28px;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 11px 15px;
  }
  .grocery-edit-main .edit-your-budget .edit-btn h4 {
    font-size: 17px;
    font-weight: bolder;
  }
  .grocery-edit-main .back-to-portal-grocery .profile-dashboard h3 {
    font-weight: 500 !important;
  }

  .main-page-details-settings .your-payment-methods-wrp .nav-link {
    padding: 7px 65px !important;
  }

  .profile-dashboard .profile-img {
    margin-right: 15px !important;
  }
  /* Merchants details */

  .merchant-details-wrapp .back-to-merchants-wrp .edit-btn {
    width: 75% !important;
  }
  .merchant-details-wrapp .back-portal-merchant-detail .setting_background_color {
    padding: 15px 5px 15px 6px !important;
  }
  .merchant-details-wrapp .back-portal-merchant-detail .setting_background_color .comn-btn {
    padding: 12px 7px;
  }

  .merchant-details-wrapp .back-portal-merchant-detail .profile-dashboard h3 {
    font-size: 18px !important;
  }

  .merchant-details-wrapp .your-spend-box {
    padding: 16px 11px 16px 10px !important;
  }

  .client-dashboard-main-wrapper .top-5-expenses-wrapper .sml-blck {
    padding: 13px 13px;
  }
  .client-dashboard-main-wrapper .top-5-merchants-wrapper .sideblck {
    padding: 16px 6px;
  }

  /* Merchants details end*/

  .main-front-dashboard-container .total-clients .comn-btn {
    padding: 11px 4px !important;
    font-size: 13px !important;
  }

  .your-account-balances-container-main .back-portal-account-balances .profile-dashboard h3 {
    font-size: 15px;
  }

  /* Your account balances css */

  .back-portal-account-balances .setting_background_color {
    padding: 15px 9px 15px 9px !important;
  }
  .back-portal-account-balances .setting_background_color .comn-btn {
    padding: 12px 5px !important;
  }
  .back-portal-account-balances .profile-dashboard h3 {
    font-size: 18px;
  }

  .your-account-balances-container-main .add-new-account-wrappee .edit-btn {
    width: 80%;
  }

  .your-account-balances-container-main .account-number-dark {
    font-size: 16px !important;
  }

  /* Your account balances css end*/

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image thead th:first-child {
    width: 35%;
  }

  .your-groups-main-front-wrapper .search-block input.search {
    width: 100%;
  }

  /* Edit summary page css */

  .client-summary-page-main .total-clients .air-new .air-image img {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
  }
  .client-summary-page-main .total-clients .air-new-zealand {
    font-size: 17px !important;
  }
  .client-summary-page-main .total-clients .pro-plan .air-new {
    font-size: 32px !important;
  }
  .annual-pro-plan {
    font-size: 18px;
  }
  .renews-plan {
    font-size: 18px !important;
  }
  .plan-date {
    font-size: 17px !important;
  }
  .client-summary-page-main .share-number-open {
    font-size: 20px;
  }

  .client-summary-page-main .report-card-title {
    font-size: 17px !important;
  }
  .client-summary-page-main .report-card .setting_text_color {
    font-size: 14px !important;
  }

  .client-summary-page-main .first-report-card .title-dashboard {
    width: 85%;
  }

  .client-summary-page-main .report-card-inner {
    width: 47%;
  }
  .client-summary-page-main .report-card .slecet-wrp select {
    font-size: 13px;
  }

  .first-report-card-wrp {
    width: 50%;
  }
  .report-card-main-wrp {
    width: 50%;
  }
  .client-summary-page-main .weekly-wrp {
    width: 44% !important;
    display: flex;
    justify-content: flex-end;
  }

  .edit-goal-budget-wrapper .table-image {
    width: 105% !important;
  }
  .personalise-edit {
    width: 95% !important;
  }

  /* Financial goal add  */

  .financial-goals-main .back-to-portal-financial-goals .setting_background_color {
    padding: 15px 6px 15px 6px !important;
  }
  .financial-goals-main .back-btn-financial {
    margin-top: 16px !important;
  }

  .financial-goals-main .back-to-portal-financial-goals .setting_background_color .comn-btn {
    padding: 15px 5px !important;
    font-size: 14px !important;
  }
  .financial-goals-main .back-btn-financial .edit-btn {
    padding: 15px 40px !important;
  }
  .financial-goals-main .back-btn-financial .edit-btn h4 {
    font-size: 18px !important;
  }

  .financial-goals-main .back-to-portal-financial-goals .profile-dashboard h3 {
    font-size: 16px !important;
    font-weight: 500;
  }

  .financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
    padding: 10px 38px !important;
  }

  .add-new-goal-wrpp {
    width: 60% !important;
  }

  .financial-cards-wrp-div {
    width: 90% !important;
  }

  /* Financial goal add  end */

  /* Your transaction page */

  .your-transaction-main-container .your-trans-back-to-portal .setting_background_color {
    padding: 12px 10px 12px 10px !important;
  }

  .your-transaction-main-container .your-trans-back-to-portal .profile-dashboard h3 {
    font-weight: 500 !important;
  }
  /* Your transaction page end */

  /* ----Edit your budget page------- */

  .grocery-edit-main .edit-your-budget .edit-btn {
    width: 100% !important;
  }

  .edit-your-budget-main-wrapper .edit-btn {
    width: 90% !important;
  }
  .edit-your-budget-main-wrapper .edit-btn h4 {
    font-size: 17px;
  }

  .edit-your-budget-main-wrapper .first-table-edit-budget {
    overflow-x: scroll !important;
  }
  .edit-your-budget-main-wrapper .first-table-edit-budget .table-horizontal .table-image {
    width: 120% !important;
  }
  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper {
    overflow-x: scroll !important;
  }

  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper .regular-account-transfer-inner {
    width: 120% !important;
  }
  .edit-your-budget-main-wrapper .edit-your-budget-total-income-wrapper {
    width: 100% !important;
  }
  .edit-your-budget-main-wrapper .regular-account-transfer-wrapper .edit-your-budget-total-income-expenses-wrapper {
    width: 100% !important;
  }

  .edit-your-budget-back-portal-wrp .profile-img img {
    width: 50px;
    margin-left: 15px;
  }

  .edit-your-budget-back-portal-wrp .profile-dashboard h3 {
    font-size: 15px !important;
  }

  /* ----Edit your budget page end */

  /* Edit summary page css end */

  .client-dashboard-main-wrapper .net-worth-progress-side .linecartbottom canvas.barcart {
    height: 315px !important;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 15px 0px 15px 12px !important;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color .profile-dashboard h3 {
    font-size: 15px;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color .comn-btn {
    font-size: 13px;
    padding: 15px 5px;
  }

  /* Net worth progress */

  .net-worth-main .total-bal-main-first-wrp {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .net-worth-main .net-worth-line-cart-canvas {
    width: 100% !important;
  }

  /* Retirement plan csss */

  .retirement-plan-main .account-assets-wrp .account-assets-inner .account-assets-mini-title {
    font-size: 11px !important;
  }

  .retirement-plan-main .account-assets-wrp {
    width: 70% !important;
  }

  .retirement-plan-main .total-amt-now-box {
    width: 30% !important;
  }

  .retirement-plan-main .your-contribution-wrapper {
    width: 70%;
  }
  .retirement-plan-main .contribute-more {
    width: 30%;
  }
  .text-250 {
    padding-left: 50px !important;
  }

  .your-contribution-wrapper .your-contribution-box {
    padding: 14px 13px 14px 10px !important;
  }

  .retirement-plan-main .retirement-section-2 {
    width: 70% !important;
  }
  .retirement-plan-main .retirement-section-2-right-wrp {
    width: 30% !important;
  }

  .retirement-plan-main .contribute-more .retirement-planner-wrp .second-line {
    font-size: 14px;
  }
  .retirement-plan-main .contribute-more .retirement-planner-wrp .big-head {
    font-size: 40px;
  }

  .retirement-plan-main .retirement-section-2 .saving-account-box .merchant-box {
    padding: 12px 6px 12px 8px;
  }

  .retirement-plan-main .veteran-pension-payment-second .taxed-retirement .your-account-Chevronleft-icon {
    right: 1px;
  }

  .retirement-plan-main .retirement-section-2-right-side {
    width: 100% !important;
  }
  .retirement-section-2-right-wrp .retirement-planner-text {
    font-size: 16px !important;
    margin-top: 5px;
  }

  .retirement-wrp-table-side td.table-text-color {
    height: 60px;
    padding: 20px 25px !important;
  }

  .retirement-plan-main .veteran-pension-payment-first {
    width: 35% !important;
  }

  .veteran-pension-payment-first .big-width {
    width: 160px !important;
  }

  .retirement-plan-main .veteran-pension-payment-second {
    width: 65% !important;
  }
  .nz-super-option-wrp .planner {
    width: 160px !important;
  }
  /* Edit cashflow */

  .edit-cashflow-container-main .back-portal-edit-cashflow .setting_background_color .comn-btn {
    font-size: 15px !important;
    padding: 12px 6px !important;
  }

  .edit-cashflow-container-main .back-portal-edit-cashflow .profile-dashboard h3 {
    font-size: 16px !important;
  }

  .edit-cashflow-container-main .edit-cashflow-new-table-section .first-table-edit-cashflow .table-image thead th,
  .edit-cashflow-container-main .edit-cashflow-new-table-section .second-table-edit-cashflow .table-image thead th {
    font-size: 18px !important;
  }

  .edit-cashflow-container-main .edit-cashflow-title-heading .setting_text_color {
    font-size: 14px;
    line-height: 25px !important;
  }

  .edit-cashflow-container-main .edit-cashflow-new-table-section .first-table-edit-cashflow .new-table .table-content,
  .edit-cashflow-container-main .edit-cashflow-new-table-section .second-table-edit-cashflow .new-table .table-content {
    font-size: 15px !important;
  }

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn {
    width: 90% !important;
  }
  /* Connect your acccounts */

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 10px 7px 10px 12px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    padding: 15px 4px !important;
    font-size: 14px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color h3 {
    font-size: 16px !important;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .bank-connection .bank-connection-img img {
    width: 90%;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .add-an-investment-conn-wrapper
    .investment-connection
    .investment-connection-img
    img {
    width: 90%;
  }
  .connect-your-accounts-main-wrapper
    .connect-your-account-bank-connection
    .live-connection-main-wrapper
    .investment-connection-img
    img {
    width: 90%;
  }
  .akahu-box {
    padding: 15px 17px 17px 15px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-bank-connection .live-connection-main-wrapper {
    width: 65% !important;
  }

  .lcs-inner {
    margin-left: 10px !important;
  }
  /* Dashboard inner new css */

  .client-dashboard-main-wrapper .income-exp-mnth h5 {
    font-size: 16px !important;
  }
  .client-dashboard-main-wrapper .income-exp-mnth select {
    font-size: 14px !important;
  }

  .client-dashboard-main-wrapper .spend-budget h5 {
    font-size: 20px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .recent-see-all h5,
  .client-dashboard-main-wrapper .weekly-cahflowcart h5,
  .client-dashboard-main-wrapper .net-worth-progress-side h5,
  .client-dashboard-main-wrapper .account-balances-side .title-heading h5 {
    font-size: 20px;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .merchnat-img {
    width: 60px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
    padding: 16px 13px !important;
  }
  /* Main Front dashboard csss */

  .main-front-dashboard-container
    .clients-with-share-rqst-wrapper
    .table-horizontal
    .table-image
    .cursor-pointer
    .amount-active {
    padding: 1px 7px;
    margin-left: 15px;
  }

  /* Edit your budget css */

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color .comn-btn {
    padding: 12px 4px !important;
    font-size: 14px;
  }

  /* Your clients list page */

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper {
    overflow-x: scroll !important;
  }
  .your-clients-list-section-wrapper .top-heading-wrp-main {
    width: 50% !important;
  }

  .your-clients-list-section-wrapper .search-spacing {
    width: 37% !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .space-table td a {
    margin-left: 5% !important;
  }

  /* Your client lost page end */

  /* Your clients list page */
  .your-groups-main-front-wrapper .your-groups-page-table-list .space-table td a {
    margin-left: 5% !important;
  }

  .your-groups-page-table-list .table-content {
    font-size: 16px !important;
  }

  /* Your clients list page  end*/

  .edit-client-details-page .edit-client-last-side .edit-clients-buttons .comn-btn {
    padding: 12px 12px !important;
  }

  .edit-client-details-page .edit-client-last-side .edit-clients-buttons .comn-btn i {
    /* padding: 12px 3px !important; */
    font-size: 16px;
  }

  .client-summary-page-main .total-clients .comn-btn {
    font-size: 13px !important;
    padding: 10px 5px !important;
  }

  /* Group summary page main css */

  .details-wrapper-summary {
    width: 50%;
  }

  .group-summary-page-main .amount-inactive,
  .amount-activated {
    font-size: 12px !important;
  }
  .client-group-detail-page .total-clients {
    padding: 15px 28px;
  }
  .client-group-detail-page .total-clients .comn-btn {
    font-size: 15px;
  }

  .group-summary-page-table .table-image .air-image button {
    margin-left: 17px;
  }

  .group-summary-page-main input.search {
    width: 100%;
  }

  .group-summary-page-table .table-text-color,
  .group-summary-page-table .table-content {
    font-size: 14px !important;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image thead th:first-child {
    width: 28% !important;
  }

  .main-front-dashboard-container .total-clients .text-client {
    line-height: 0px;
    font-size: 14px;
  }
  .clients-with-share-rqst-wrapper .table-content,
  .clients-with-share-rqst-wrapper .table-text-color {
    font-size: 14px !important;
  }

  /* Your financial goals */

  .financial-goals-main .back-to-portal-financial .profile-img img {
    width: 50px;
  }
}
@media only screen and (max-width: 1700px) {
  .main-page-details-settings .your-payment-methods-wrp .nav-link {
    padding: 7px 150px !important;
  }
}
@media only screen and (max-width: 1900px) and (min-width: 1800px) {
  .nav-link {
    padding: 7px 149px !important;
  }
  .saving-block {
    width: 289px !important;
  }
  .income-merchant {
    width: 389px !important;
  }

  .net-worth-main .left-section .balance-wrp-heading h2 {
    font-weight: 600 !important;
    font-size: 55px;
  }
}
@media only screen and (max-width: 1670px) and (min-width: 1300px) {
  .nav-link {
    padding: 7px 86px !important;
  }

  .monthly-section .income-merchant {
    width: 48% !important;
  }

  /* .blkc {
    padding: 15px 10px 1px 10px;
  }

  .blkc .saving-head h4 {
    font-size: 13px;
  }

  .blkc .number-heading {
    font-size: 22px;
  } */

  /* .blkc .right-section .img {
    width: 40px;
  } */
}
@media only screen and (max-width: 1800px) and (min-width: 1600px) {
  /* .income-merchant{
    width: 337px !important;
  } */
  .right-section .img {
    width: 50px;
    margin-left: 33px;
  }
  .main-page-details-settings .your-payment-methods-wrp .nav-link {
    padding: 7px 135px !important;
  }
  .saving-acc-wrp {
    justify-content: unset !important;
  }
  .saving-block {
    width: 250px !important;
  }
  .new-table thead th:first-child {
    width: 15%;
  }

  /* .comn-btn {
    padding: 15px 20px;
  } */
  .profile-dashboard h3 {
    font-size: 20px;
  }

  .blkc {
    padding: 15px 10px 1px 10px;
  }

  .row.top-nav .setting_background_color {
    padding: 15px 15px 15px 15px;
  }

  .right-top-blck .title {
    font-size: 22px;
  }

  .right-top-blck .per-head {
    font-size: 18px;
  }

  /* new css */
  /* .budget-summary-main .pie-blck-circular{
    width:200px !important;
  } */

  .edit-your-budget-main-wrapper .edit-btn {
    padding: 11px 18px;
    width: 65%;
  }
  .edit-your-budget-main-wrapper .profile-dashboard .comn-btn {
    padding: 15px 20px;
  }

  /* Your account balances css */

  .back-portal-account-balances .setting_background_color {
    padding: 15px 20px 15px 20px;
  }

  .your-account-balances-container-main .back-portal-account-balances .comn-btn {
    padding: 15px 20px !important;
  }

  .your-account-balances-container-main .add-new-account-wrappee .edit-btn {
    width: 70%;
  }

  /* Your account balances css end*/

  .client-summary-page-main .share-status-wrp .comn-btn {
    padding: 11px 15px;
  }

  /* Merchant detail page */

  .merchant-details-wrapp .back-portal-merchant-detail .setting_background_color {
    padding: 15px 25px 15px 13px;
  }

  /* Merchant detail page end */

  /* Financial gaol page css */

  .financial-goals-main .back-to-portal-financial-goals .setting_background_color {
    padding: 15px 24px 15px 10px;
  }
  /* End */

  /* Net worth main new css */

  .net-worth-main .big-head-new {
    font-size: 32px !important;
    font-weight: 800 !important;
  }

  .net-worth-main .back-portal-btn .comn-btn {
    font-size: 18px !important;
  }
  .net-worth-main .back-portal-btn .profile-dashboard .comn-btn img {
    width: 26px !important;
  }
  .net-worth-main .back-portal-btn .profile-img img {
    width: 59px !important;
  }

  .net-worth-main .left-section .balance-wrp-heading h2 {
    font-weight: 600 !important;
  }

  .net-worth-main .left-section .balance-wrp-sub-heading {
    margin-top: 0px !important;
  }
  .net-worth-main .left-section .balance-wrp-sub-heading .med-new-font {
    font-size: 22px !important;
    line-height: 145% !important;
    font-weight: 500px !important;
  }
  .net-worth-main .left-section .full-breakdown-arrow h4 {
    font-size: 16px !important;
  }

  .net-worth-main .left-section .full-breakdown-arrow svg {
    width: 35px !important;
    height: 35px !important;
  }
  /* Net worth css end */

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 50%;
  }

  .financial-goals-main .back-to-portal-financial {
    align-items: baseline;
  }

  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 15px 10px 15px 30px;
  }

  /* Edit cashflow page */
  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn h4 {
    font-size: 15px !important;
  }

  /* Connect your acccount css */

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    padding: 15px 12px;
  }

  /* Your Merchants Css */

  .your-merchants-main-wrapper .categories-block .categorised,
  .your-merchants-main-wrapper .categories-block .uncategorised {
    padding: 16px 25px 15px 20px;
  }
}

@media only screen and (max-width: 1599px) {
  .edit-btn {
    width: 100%;
  }
  /* .comn-btn {
    margin-left: 15px;
    padding: 12px 12px;
  } */
  /* .profile-img {
    margin-left: 15px;
  } */

  .back-to-portal-financial .profile-img {
    margin-right: 20px;
  } /* .right-section {
    align-items: center;
  } */

  .row.top-nav .setting_background_color {
    padding: 15px 15px 15px 15px;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper td a {
    margin-left: 10%;
  }

  /* .income-exp-mnth canvas.barcart{
     height: 400px !important;
  } */
  .cashflocart canvas.barcart {
    height: 360px !important;
    margin-top: 0px;
  }
  .linecartbottom canvas.barcart {
    height: 400px !important;
  }

  .group-details-page td a {
    margin-left: 13%;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image .cursor-pointer td a {
    margin-left: 6%;
  }
  /* Your account balances css */

  .back-portal-account-balances .setting_background_color {
    padding: 15px 10px 15px 10px;
  }

  .your-account-balances-container-main .add-new-account-wrappee .edit-btn {
    width: 80%;
  }

  /* Your account balances css end*/

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 11px 15px;
  }

  /* Merchants details */

  .merchant-details-wrapp .back-to-merchants-wrp .edit-btn {
    width: 70%;
  }
  .merchant-details-wrapp .back-portal-merchant-detail .setting_background_color {
    padding: 15px 13px 15px 10px;
  }

  .merchant-details-wrapp .back-portal-merchant-detail .profile-dashboard h3 {
    font-size: 20px;
  }

  .merchant-details-wrapp .your-spend-box {
    padding: 16px 19px 16px 25px;
  }

  .top-heading-merchant .setting_text_color {
    line-height: 28px;
  }

  /* Merchants details end*/

  /* New css for 1600 */

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 55%;
  }

  /* Financial goal add  */

  .financial-goals-main .back-to-portal-financial-goals .setting_background_color {
    padding: 15px 10px 15px 10px;
  }

  .financial-goals-main .back-to-portal-financial-goals .setting_background_color .comn-btn {
    padding: 12px 8px;
  }
  .financial-goals-main .back-btn-financial .edit-btn {
    padding: 15px 50px;
  }

  .financial-goals-main .back-to-portal-financial-goals .profile-dashboard h3 {
    font-size: 18px;
  }

  .financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
    padding: 10px 50px;
  }

  /* Financial goal add  end */

  /* Grocery edit */

  .grocery-edit-main .back-to-portal-grocery .setting_background_color {
    padding: 12px 11px 11px 7px;
  }
  .grocery-edit-main .edit-your-budget .edit-btn {
    width: 100% !important;
  }

  /* Grocery edit end*/

  /* ----Edit your budget page------- */

  .edit-your-budget-main-wrapper .edit-btn {
    width: 80%;
    padding: 11px 18px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .profile-dashboard h3 {
    font-size: 20px;
  }

  /* ----Edit your budget page end */

  /* -----------------Edit summary page --------------------- */

  .client-summary-page-main .total-clients .air-new-zealand {
    font-size: 20px;
  }

  .client-summary-page-main .total-clients {
    padding: 13px 10px;
  }
  .client-summary-page-main .total-clients .pro-plan .air-new {
    font-size: 34px;
  }

  .renews-plan {
    font-size: 22px;
  }

  .plan-date {
    padding: 2px 9px 8px 8px;
    height: 30px;
  }

  .client-summary-page-main .total-clients .comn-btn {
    font-size: 13px;
    padding: 10px 10px;
  }

  /* ----------------------Client dashboard main----------------- */

  .client-dashboard-main-wrapper .net-worth-progress-side {
    width: 100% !important;
    margin-bottom: 30px;
  }

  .client-dashboard-main-wrapper .account-balances-side {
    width: 100% !important;
  }

  .client-dashboard-main-wrapper .account-balances-side .cmn-blck h4 {
    font-size: 15px;
  }

  .client-dashboard-main-wrapper .account-balances-side .see-all {
    font-size: 16px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck {
    padding: 21px 13px;
  }

  .client-dashboard-main-wrapper .net-worth-progress-side .linecartbottom canvas.barcart {
    height: 450px !important;
  }

  .net-worth-main .net-worth-table {
    overflow-x: scroll;
  }

  .net-worth-main .net-worth-table .table-horizontal {
    width: 1150px;
  }

  .net-worth-main .net-worth-table-second {
    overflow-x: scroll;
  }

  .net-worth-main .net-worth-table-second .table-horizontal {
    width: 1150px;
  }

  .financial-goals-main .back-to-portal-financial {
    align-items: baseline;
  }
  .financial-goals-main .back-to-portal-financial .setting_background_color {
    padding: 15px 10px 15px 7px;
  }

  .financial-goals-main .back-btn-financial {
    width: 25%;
  }

  /* Edit cashflow */

  .edit-cashflow-container-main .view-calendar-cashflow .edit-btn {
    width: 75%;
  }

  .edit-cashflow-container-main .table-horizontal {
    overflow-x: scroll !important;
  }

  /* Retirement planner */

  .account-assets-inner .account-assets-mini-title {
    font-size: 11px !important;
  }

  .retirement-plan-main .your-contribution-wrapper .sharesies-big-wrapper .your-contribution-box .edit-goal-house {
    width: 50%;
  }

  /* Edit cahsflow css */

  .edit-cashflow-container-main .edit-cashflow-new-table-section .first-table-edit-cashflow .table-image thead th {
    font-size: 18px;
  }
  .edit-cashflow-container-main .edit-cashflow-new-table-section .second-table-edit-cashflow .table-image thead th {
    font-size: 18px;
  }

  /* Connect your account css */

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color {
    padding: 15px 15px 15px 15px;
  }

  .connect-your-accounts-main-wrapper .connect-your-account-back-portal .setting_background_color .comn-btn {
    padding: 15px 4px;
  }

  /* Your Merchants Css */

  .your-merchants-main-wrapper .categories-block .categorised,
  .your-merchants-main-wrapper .categories-block .uncategorised {
    padding: 16px 10px 15px 3px;
  }

  /* Edit your budget */

  .edit-your-budget-main-wrapper .edit-your-budget-total-income-expenses-wrapper .expenses-transfers-section {
    padding: 0px 20px;
  }

  .edit-your-budget-main-wrapper .edit-your-budget-back-portal-wrp .setting_background_color {
    padding: 12px 15px 11px 7px;
  }

  /* Net worth  page */
  .net-worth-main .total-bal-main-first-wrp .med-head {
    font-size: 22px !important;
  }
  .net-worth-main .left-section .balance-wrp-sub-heading .med-new-font {
    font-size: 22px !important;
    line-height: 145% !important;
    font-weight: 500px !important;
  }
  .net-worth-main .left-section .full-breakdown-arrow h4 {
    font-size: 16px !important;
  }

  .net-worth-main .total-bal-main-first-wrp .big-head {
    font-weight: 600 !important;
  }

  /* Your account balances */

  .your-account-balances-container-main .back-portal-account-balances .comn-btn {
    padding: 15px 12px;
  }
  .your-account-balances-container-main .back-portal-account-balances .profile-img img {
    width: 45px;
  }
}

.popup-top-heading {
  font-size: 28px !important;
}

.popup-top-info-heading {
  font-size: 22px !important;
}

.popup-width {
  width: 380px !important;
}

.popup-width-color {
  width: 620px !important;
}

.popup-content-width {
  width: 290px !important;
}

.popup-content-info-width {
  width: 290px !important;
  font-size: 14px;
  color: #8a8a8a;
}

.popup-color-content-width {
  width: 530px !important;
}

.popup-padding-zero {
  padding: 0px;
}

.popoup-color {
  width: 165px;
  height: 40px;
  border-radius: 40px;
  margin-bottom: 0px;
  font-size: 17px;
  color: #fff;
}

.red-color {
  background-color: #e0533d;
  color: #fff;
}

.dark-green-color {
  background-color: #469b88;
  color: #fff;
}

.yellow-color {
  background-color: #fae848;
  color: #fff;
}

.blue-color {
  background-color: #3760c8;
  color: #fff;
}

.purple-color-color {
  background-color: #c537c8;
  color: #fff;
}

.orange-color {
  background-color: #c8a837;
  color: #fff;
}

.pink-color {
  background-color: #ee30e6;
  color: #fff;
}

.light-blue-color {
  background-color: #00aaff;
  color: #fff;
}

.lime-green-color {
  background-color: #9ee78c;
  color: #fff;
}

.dark-red-color {
  background-color: #9b4646;
  color: #fff;
}

.green-color {
  background-color: #44c869;
  color: #fff;
}

.dark-blue-color {
  background-color: #15178c;
  color: #fff;
}

.progress-red-color {
  background-color: #f02304;
  color: #fff;
}

.progress-dark-green-color {
  background-color: #05fcc7;
  color: #fff;
}

.progress-yellow-color {
  background-color: #ffe600;
  color: #fff;
}

.progress-blue-color {
  background-color: #0840cc;
  color: #fff;
}

.progress-purple-color-color {
  background-color: #900592;
  color: #fff;
}

.progress-orange-color {
  background-color: #ffcc00;
  color: #fff;
}

.progress-pink-color {
  background-color: #ee00ff;
  color: #fff;
}

.progress-light-blue-color {
  background-color: #0055ff;
  color: #fff;
}

.progress-lime-green-color {
  background-color: #31f300;
  color: #fff;
}

.progress-dark-red-color {
  background-color: #e90000;
  color: #fff;
}

.progress-green-color {
  background-color: #00f445;
  color: #fff;
}

.progress-dark-blue-color {
  background-color: #0004ff;
  color: #fff;
}

.button-blck-color {
  display: inline-flex;
  margin-right: 10px;
  color: #fff;
}

.btn-hover a:hover {
  color: #fff !important;
}

.btn-no {
  background-color: #ffffff;
  color: rgba(179, 179, 179, 1);
  border: 1px solid rgba(216, 216, 216, 1);
}

@media only screen and (max-width: 760px) {
  .mainsidebar .app-sidebar {
    display: none !important;
  }
  .live-connections-box {
    width: 100% !important;
  }

  /* Toggle hamburger */

  .main-nav {
    width: 100%;
    display: contents !important;
    height: 100%;
    position: absolute;
    display: grid;
    grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
  }
  .hamburger-menu {
    display: block;
    position: absolute;
    right: 10px;
    font-size: 30px;
  }

  .menu-link {
    display: none;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 30px;
  }

  .mobile-menu-link {
    background-color: white;
    height: 100%;
    width: 30%;
    position: absolute;
    right: 0;
    display: grid;
    grid-column: 2/5;
    align-items: center;
    transition: all 2s linear;
    transform-origin: top;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .mobile-menu-link ul {
    height: 90%;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    list-style: none;
    margin-top: 20px;
  }

  .mobile-menu-link ul li a {
    width: unset;
    display: inline-flex;
    padding: 10px;

    border-radius: 13px !important;
  }

  .mobile-menu-link ul li a:hover,
  .mobile-menu-link ul li a:active {
    background-color: #469b884c !important;
    color: #fff !important;
  }

  /* Toggle hamburger End */
}

@media only screen and (max-width: 1550px) and (min-width: 1376px) {
  .client-summary-page-main .report-card .slecet-wrp select {
    font-size: 12px !important;
    width: 100% !important;
  }
  .client-summary-page-main .report-card-title {
    font-size: 16px !important;
  }
  .client-summary-page-main .report-card .setting_text_color {
    font-size: 12px !important;
  }

  .client-group-detail-page .total-clients {
    padding: 10px 14px !important;
  }
  .client-group-detail-page .total-clients .amt-spent {
    font-size: 18px;
    padding-top: 10px;
  }
  .client-group-detail-page .selecet-box {
    padding-top: 5px;
  }
  .client-group-detail-page .text-client {
    line-height: 22px;
  }
  .group-summary-page-main .table-image thead th:first-child {
    width: 28% !important;
  }
  .group-summary-page-table .table-text-color,
  .group-summary-page-table .table-content {
    font-size: 18px !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-content,
  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-text-color {
    font-size: 16px !important;
  }

  .your-clients-list-section-wrapper .your-clients-list-table-wrapper .table-image thead th:first-child {
    width: 30%;
  }

  .your-groups-page-table-list .table-content,
  .your-groups-page-table-list .table-text-color {
    font-size: 17px !important;
  }

  .main-front-dashboard-container .clients-with-share-rqst-wrapper .table-horizontal .table-image thead th:first-child {
    width: 30% !important;
  }
  .clients-with-share-rqst-wrapper .table-content,
  .clients-with-share-rqst-wrapper .table-text-color {
    font-size: 16px !important;
  }
  .main-front-dashboard-container .total-clients .comn-btn {
    padding: 11px 0px !important;
  }

  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .merchnat-img {
    width: 160px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h5 {
    font-size: 15px !important;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .name-merchant h6 {
    font-size: 14px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .date-time-blck {
    margin-left: 0px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .date-time-blck h4 {
    font-size: 15px;
  }
  .client-dashboard-main-wrapper .recent-transaction-wrapper .sideblck .date-time-blck span {
    font-size: 15px;
  }

  .edit-your-budget-main-wrapper .edit-goal-budget-wrapper {
    width: 110%;
  }

  .edit-your-budget-back-portal-wrp .comn-btn {
    padding: 15px 12px !important;
  }
  .edit-your-budget-back-portal-wrp .profile-img img {
    width: 50px;
  }

  .top-5-expenses-wrapper .sml-blck .number-heading {
    font-size: 20px !important;
  }

  .main-page-details-settings .your-payment-methods-wrp .nav-link {
    padding: 7px 85px !important ;
  }

  .financial-goals-main .edit-btn-financial {
    width: 30%;
  }
  .financial-goals-main .back-to-portal-financial .comn-btn {
    padding: 15px 12px;
  }

  .financial-goals-main .back-to-portal-financial .profile-dashboard h3 {
    font-size: 18px;
  }

  .profile-dashboard .profile-img {
    margin-right: 15px !important;
  }
  .financial-goals-main .togger-wrp-financial .budget-tabs .nav-link {
    padding: 10px 50px !important;
  }

  .financial-cards-wrp-div {
    width: 75% !important;
  }

  .veteran-pension-payment-first .big-width {
    width: 200px !important;
  }
  .nz-super-option-wrp .planner {
    width: 200px !important;
  }

  .retirement-wrp-table-side td.table-text-color {
    height: 60px;
    padding: 20px 40px !important;
  }
  .companies-list-wrapper .air-image .amount-active {
    margin-left: 15%;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 10px;
    text-align: center;
    font-size: 16px;
  }

  .super-btn input.search {
    padding: 12px 45px 12px 40px;
  }

  .add-goal {
    border: none;
  }
  .edit-goal-select {
    width: 75% !important;
  }
  .edit-goal-color {
    width: 0%;
    margin: auto;
  }
  .shadow {
    box-shadow: 0 0.5rem 1rem #e7e3e31a !important;
  }
}

.plan-box-mobile-view-pro-account {
  width: 100%;
  background: url('../../assests/images/pro-top-bg.png') top right no-repeat,
    url('../../assests/images/pro-bottom-bg.png') bottom left no-repeat, rgba(86, 215, 122, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.plan-box-mobile-view-standard-account {
  width: 100%;
  background: url('../../assests/images/standard-top-bg.png') top right no-repeat,
    url('../../assests/images/standard-bottom-bg.png') bottom left no-repeat, rgba(20, 91, 255, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-free-account {
  width: 100%;
  background: url('../../assests/images/free-top-bg.png') top right no-repeat,
    url('../../assests/images/free-bottom-bg.png') bottom left no-repeat, rgba(137, 20, 255, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-14-day-trail-account {
  width: 100%;
  background: url('../../assests/images/top-14-day-free-plan.png') top right no-repeat,
    url('../../assests/images/bottom-14-day-free-plan.png') bottom left no-repeat, rgba(70, 155, 136, 1);
  background-size: 24%, 40%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-ealry-account {
  width: 100%;
  background: url('../../assests/images/Early-bottom.png') top right no-repeat,
    url('../../assests/images/Early-top.png') bottom left no-repeat, rgba(205, 86, 215, 1);
  background-size: 24%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-freemium-account {
  width: 100%;
  background: url('../../assests/images/freemium-top.png') top right no-repeat,
    url('../../assests/images/freemium-bottom.png') bottom left no-repeat, rgba(67, 185, 246, 1);
  background-size: 24%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-no-plan-account {
  width: 100%;
  background: url('../../assests/images/no-plan-top.png') top right no-repeat,
    url('../../assests/images/no-plan-bottom.png') bottom left no-repeat, rgba(215, 86, 86, 1);
  background-size: 24%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.link-logout {
  color: #469b88;
  font-family: Neurialgrotesk, sans-serif;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 34px !important;
  letter-spacing: -0.01em !important;
  cursor: pointer;
}
.link-logout:hover {
  color: #469b88 !important;
}
