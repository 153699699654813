.setting-tab li .nav-link {
  background-color: unset !important;
  font-size: 20px;
  padding: 10px 70px !important;
  font-weight: 500;
}
.setting-wrp .nav {
  background-color: unset !important;
  border: none !important;
}
.setting-wrp .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: unset !important;
  color: #000 !important;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid #000;
  border-radius: 0px;
}
.col-xl-6.setting-wrp {
  margin-top: 3rem;
}
.main-section-left {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}

.main-2fa-left {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.heading h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.heading-2fa h3 {
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #240f51;
  font-family: Neurialgrotesk, sans-serif;
}

.setting_2fa_text_color {
  color: rgba(144, 144, 144, 1);
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  margin-bottom: 0px;
  letter-spacing: 0.86px;
  text-align: center;
  padding: 0px 30px 0px 30px;
}

.verify-btn {
  padding: 16px 90px !important;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.form-control-lg-email {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 5px 30px 0px #0000001a;
}

.email-box-padding {
  padding: 0px 20px 0px 20px;
}

.all-set-box-padding {
  padding: 0px 5px 0px 5px;
}

.field-form {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.field-form label {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
}
input.input-form {
  height: 54px;
  padding: 14px 20px;
  margin-top: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
}
.profile-img-setting img {
  width: 25%;
}
.profile-img-setting {
  display: flex;
  position: relative;
  justify-content: flex-start;
}
.col-xl-6.profile-wrp {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.camera img {
  width: 100%;
}
.user-settings {
  height: 45px;
  padding: 14px 20px;
  margin-top: 10px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
}
.camera {
  position: absolute;
  left: 15%;
  background-color: #469b88;
  padding: 11px 12px;
  top: 12%;
  text-align: center;
  border-radius: 50%;
  display: flex;
  border: 4px solid #fff;
}

.half-width {
  width: 50% !important;
}

.setting-padding-security {
  padding: 2px 11px 11px 0px !important;
}

.profile-img-margin {
  margin-left: 42px;
  margin-top: 38px;
  margin-bottom: 16px;
}

.verification-code-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.goback-btn {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  background: rgba(208, 79, 79, 1);
  width: 314px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.verification-code-box {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

@media only screen and (max-width: 1699px) {
  .setting-tab li .nav-link {
    padding: 10px 45px !important;
  }
}
@media only screen and (max-width: 1399px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }
}
@media only screen and (max-width: 1199px) {
  .setting-tab li .nav-link {
    padding: 10px 50px !important;
  }
  .profile-img-setting img {
    width: 100%;
  }
  .camera {
    left: 60%;
    top: 15%;
  }
  .setting-wrp {
    width: 75%;
  }
}
@media only screen and (max-width: 979px) {
  .setting-tab li .nav-link {
    padding: 10px 45px !important;
  }
  .setting-page .tab-pane {
    flex-direction: column;
  }
  .profile-img-setting {
    display: flex;
    position: relative;
    justify-content: center;
  }
  .profile-img-setting img {
    width: 35%;
  }
  .camera img {
    width: 100%;
  }
  .camera {
    left: 55%;
    top: 15%;
  }
}
@media only screen and (max-width: 649px) {
  .setting-tab li .nav-link {
    padding: 10px 30px !important;
  }
  .setting-wrp .nav {
    flex-direction: column;
  }
  .setting-tab li .nav-link {
    padding: 10px 120px !important;
    width: 100%;
    margin-bottom: 15px;
  }
  .done-img {
    width: 250px;
  }
}
@media only screen and (max-width: 479px) {
  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .done-img {
    width: 290px;
  }
  .verify-btn {
    padding: 16px 57px !important;
  }
}

@media only screen and (max-width: 390px) {
  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 23px;
    line-height: 30px;
  }
  .done-img {
    width: 264px;
  }
  .verify-btn {
    padding: 16px 42px !important;
  }
}

@media only screen and (max-width: 375px) {
  /* .profile-wrp .profile-img-setting img {
        width: 50%;
    } */
  .heading-2fa h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .done-img {
    width: 251px;
  }
  .verify-btn {
    padding: 16px 35px !important;
  }
}
