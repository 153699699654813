/* --------------Header ----------------- */

.navbar-brand img {
  width: 40px;
  display: inline-block;
}
.navbar-brand {
  display: inline-block;
  white-space: nowrap;
}
.header-wrp-super-admin .col-md-4 {
  display: inline-flex;
  justify-content: right;
}
.header-wrp-super-admin .left {
  justify-content: left;
}
.comn-btn-super i {
  margin-left: 10px;
  font-size: 20px;
  vertical-align: middle;
}
img {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.header-wrp-super-admin {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 15px 0px;
  margin-bottom: 15px;
}
.inline {
  display: inline-flex;
}
.heading-super-admin {
  margin-bottom: 0px;
  vertical-align: middle;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}

.button-grp-super {
  display: flex;
}

.comn-btn-super {
  margin-left: 15px;
  border: none;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: normal;
  transition: 0.3s ease;
  border-radius: 10px;
  color: #fff !important;
  background-color: #469b88;
  line-height: 20px;
}
a.comn-btn-super:hover {
  background-color: #e2c55c !important;
}
.plus-icon-style {
  font-weight: 500 !important;
}

.profile-img-super {
  margin-left: 35px;
}

.profile-img-super img {
  border-radius: 50%;
  width: 59px;
}

.super-btn {
  padding-left: 35px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.company-list-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
  padding: 15px 30px;
  text-align: center;
  font-size: 16px;
}

.view-plans-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.view-plans-btn {
  margin-left: 0px;
  border: none;
  padding: 11px 20px;
  font-size: 15px;
  width: 120px;
  font-weight: normal;
  transition: 0.3s ease;
  border-radius: 10px;
  color: #fff !important;
  background-color: #469b88;
  line-height: 20px;
}

.no-data-found-td {
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  font-size: 20px;
  background-color: #fff;
  color: #6d6d6d !important;
  font-weight: 600;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top-left-radius: 19px;
  border-bottom-left-radius: 19px;
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
}

.companies-list-wrapper .table-image {
  position: relative;
}

.companies-list-wrapper .table-image thead th:last-child {
  padding-right: 80px !important;
}

.main-page-details-settings .profile-image-side {
  display: flex;
  align-items: center;
}

.all-plans-list-wrapper .table-image .cursor-pointer td {
  padding: 12px 12px !important;
}

.phone-input-class{
  position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
}
.profile-dashboard-image svg {
  width: 61px;
  display: inline-flex;
  border-radius: 100px;
  background: #F0F3F1;
  width: 61px;
  height: 61px;
  flex-shrink: 0;
}
.profile-dashboard-image img {
  width: 100px;
  display: inline-flex;
}
.profile-count-number {
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
}
.select-profile-days-wrp select {
  border: none;
  margin-right: 0px;
  vertical-align: middle;
  font-size: 13px;
  color: #767676;
}
.user-location-table{
  width: 100%;
}
.user-location-table table, th, td {
  border-bottom:1px solid #B7B8C1;
  padding: 0px 0px 20px 0px;
}
/* .user-location-table th:last-child, td:last-child {
  border-bottom: none;
} */
/* ---------------------Media query--------------------------------- */

@media only screen and (max-width: 1650px) and (min-width: 1551px) {
  .companies-list-wrapper .table-image thead th {
    font-size: 18px !important;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    font-size: 16px;
    text-align: center;
    padding: 15px 25px;
  }
  /* .view-plans-btn {
    padding: 15px 23px;
    margin-left: 8px;
  } */
}

@media only screen and (max-width: 1550px) and (min-width: 1376px) {
  .comn-btn-super {
    padding: 15px 10px !important;
  }
  .companies-list-wrapper .table-image thead th {
    font-size: 18px !important;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    font-size: 16px;
    padding: 15px 5px;
  }
}

@media only screen and (max-width: 1366px) {
  .table-horizontal {
    overflow-x: scroll;
    width: 100%;
  }
}

@media only screen and (max-width: 1375px) and (min-width: 1200px) {
  .top-head-wrp-company {
    width: 50%;
  }
  .search-wrp-company {
    width: 75%;
  }
  .super-btn {
    padding-left: 0px;
  }
  .comn-btn-super {
    padding: 15px 6px;
    margin-left: 9px;
  }

  .companies-list-wrapper table.table-image {
    width: 120%;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 10px;
    text-align: center;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1025px) {
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 10px;
    text-align: center;
    font-size: 16px !important;
  }
  .companies-list-wrapper thead th {
    font-size: 18px;
  }
  table.table-image {
    width: 150% !important;
  }
  .top-head-wrp-company {
    width: 100%;
  }
  .super-btn {
    justify-content: start;
    padding-left: 2px;
    margin-top: 10px;
    width: 75%;
  }
  .search-wrp-company {
    width: 100%;
  }

  .button-grp-super {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  table.table-image {
    width: 190% !important;
  }
  .top-head-wrp-company {
    width: 100%;
  }
  .super-btn {
    justify-content: start;
    padding-left: 2px;
    margin-top: 10px;
    width: 75%;
  }
  .search-wrp-company {
    width: 100%;
  }
  table.table-image {
    width: 150% !important;
  }
  .companies-list-wrapper .table-horizontal .table-image .cursor-pointer td {
    padding: 15px 10px;
    font-size: 16px;
  }
  .button-grp-super {
    width: 70%;
  }
}
