.plans-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 450px;
}

.changes-plans-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 758px !important;
  max-width: 450px;
}

.select-new-plans-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 535px !important;
  max-width: 450px;
}
.plans-div-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.select-plan-billing-wrapper {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.select-plan-billing-wrapper-div {
  border: 1px solid #ccc;
  padding: 20px;
  width: 301px;
  height: 491px;
  border-radius: 10px;
}
.logo-plan {
  text-align: center;
}
.plan-box {
  border: 1px solid rgb(204, 204, 204);
  padding: 20px;
  width: 301px;
  height: 491px;
  border-radius: 10px;
}

.checkbox-plan {
  position: fixed;
  overflow: hidden;
}

.checkbox__input {
  position: absolute;
  top: -100px;
  left: -100px;
}

.checkbox__inner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 1);
  margin-top: 6px;
}

.checkbox__input:checked + .checkbox__inner {
  border-color: #52c6c4;
  background-color: #52c6c4;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='10px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 14px 10px;
  border: 1px solid #fff;
}

.plan-box-mobile-view-free {
  width: 100%;
  background: url('../../assests/images/free-top-bg.png') top right no-repeat,
    url('../../assests/images/free-bottom-bg.png') bottom left no-repeat, rgba(137, 20, 255, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-standard {
  width: 100%;
  background: url('../../assests/images/standard-top-bg.png') top right no-repeat,
    url('../../assests/images/standard-bottom-bg.png') bottom left no-repeat, rgba(20, 91, 255, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}

.plan-box-mobile-view-pro {
  width: 100%;
  background: url('../../assests/images/pro-top-bg.png') top right no-repeat,
    url('../../assests/images/pro-bottom-bg.png') bottom left no-repeat, rgba(86, 215, 122, 1);
  background-size: 30%, 35%;
  border-radius: 10px;
  padding: 17px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
}
.promo-banner {
  width: 100%;
  background: #C0F7CF;
  color: #247a3c;
  font-weight: 500;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px 10px 0px 0px;
  font-size: 14px;
  margin-bottom: -5px;
}

.promo-bold {
  font-weight: 700;
  color: #1c5e2d;
}


.free-plan-check-color {
  background: rgba(126, 168, 255, 1) no-repeat center;
}

.pro-plan-check-color {
  background: rgba(112, 238, 147, 1) no-repeat center;
}

.select-plans-div-text-mobile {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0 !important;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Neurialgrotesk, sans-serif;
}

.select-plans-div-sub-text-mobile {
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.select-plans-text-mobile {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0 !important;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Neurialgrotesk, sans-serif;
}

.select-button-box {
  display: flex;
  text-align: center;
  justify-content: center;
}

.text-cancel-plan {
  font-size: 12px;
  font-family: Neurialgrotesk, sans-serif;
  margin-top: -11px !important;
  margin-bottom: 10px;
}

.message-cancel-div {
  font-size: 14px;
  outline: none;
  width: 100%;
  border: 2px solid rgba(228, 228, 231, 1);
  border-radius: 12px;
  height: 125px;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
}

.feedback-reason-div {
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.renewal-payment-div {
  display: flex;
  justify-content: space-around;
}
.renewal-div {
  width: 30%;
}
.payment-div {
  width: 65%;
}
.inner-payment-div {
  display: flex;
  vertical-align: middle;
}
.inner-payment-text {
  font-size: 13px;
  display: flex;
  align-items: center;
  font-family: Neurialgrotesk, sans-serif;
  vertical-align: middle;
}
.div-flex {
  display: flex;
  align-items: center;
}
.input-box-margin {
  align-content: center;
}
.icon-div {
  width: 35px;
  height: 28px;
}
.change-btn {
  background-color: rgba(70, 155, 136, 1);
  padding: 4px 15px 4px 15px;
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 25px;
  font-size: 13px;
  font-family: Neurialgrotesk, sans-serif;
}
.text-label {
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none;
  width: 100%;
  font-family: Neurialgrotesk, sans-serif;
}
.feedback-div-default-border {
  border: 2px solid rgba(228, 228, 231, 1);
}
.feedback-div-green-border {
  border: 2px solid rgba(20, 184, 166, 1);
}

.checkbox-round {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: rgba(20, 184, 166, 1);
  content: '\2713'; /* Unicode checkmark symbol */
  font-size: 14px; /* Adjust as needed */
  color: white; /* Color of the checkmark */
  transform: translate(1%, 1%);
  width: 20px;
  height: 20px;
  border: none;
}

.checkbox-round:checked::after {
  content: '\2713';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -55%);
  width: 20px;
  height: 20px;
}

/* Hide default checkbox */
.feedback-cancel-checkbox {
  width: 16px; /* Adjust as needed */
  height: 16px; /* Adjust as needed */
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Show custom checkbox */
.feedback-cancel-checkbox::before {
  content: '';
  display: block;
  width: 16px; /* Adjust as needed */
  height: 16px; /* Adjust as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 50%;
}

/* When checkbox is checked */
.feedback-cancel-checkbox:checked::before {
  background-color: rgba(20, 184, 166, 1);
}

/* Show checkmark */
.feedback-cancel-checkbox:checked::after {
  content: '\2713'; /* Unicode checkmark symbol */
  font-size: 14px; /* Adjust as needed */
  color: white; /* Color of the checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feedback-cancel-text {
  display: flex;
  font-size: 14px;
  font-family: Neurialgrotesk, sans-serif;
  font-weight: 500;
}
.mt-4 {
  margin-top: 0rem !important;
}

@media only screen and (max-width: 600px) {
  .plans-div {
    height: 100% !important;
    width: 95% !important;
  }
  .changes-plans-div {
    height: 100% !important;
    width: 95% !important;
  }
  .select-plan-billing-wrapper {
    display: contents !important;
  }
  .select-plan-billing-wrapper-div {
    margin-bottom: 10px;
  }
  .logo-plan {
    text-align: center !important;
  }
  .confirm-plan {
    margin-bottom: 30px !important;
  }
  .plan-box-mobile-view-free {
    width: 92% !important;
  }
  .plan-box-mobile-view-standard {
    width: 92% !important;
  }
  .plan-box-mobile-view-free-account {
    width: 92% !important;
  }
  .plan-box-mobile-view-14-day-trail-account {
    width: 92% !important;
  }
  .plan-box-mobile-view-freemium-account {
    width: 92% !important;
  }
  .plan-box-mobile-view-pro {
    width: 92% !important;
  }
  .promo-banner{
    width: 92% !important;
    font-size: 12px;
  }
  .promo-bold{
    font-size: 12px !important;
  }
  .text-cancel-plan {
    width: 92% !important;
  }
  .feedback-cancel-text {
    font-size: 11px;
  }
  .inner-payment-text {
    font-size: 10px;
  }
  .change-btn {
    font-size: 10px;
  }
  .icon-div {
    width: 25px;
    height: 25px;
  }
  .text-label {
    font-size: 12px;
  }
}

@media only screen and (max-width: 390px) {
  .plans-div {
    height: 100% !important;
    width: 95% !important;
  }

  .changes-plans-div {
    height: 100% !important;
    width: 95% !important;
  }
  .plan-box-mobile-view-free {
    width: 92% !important;
  }
  .plan-box-mobile-view-standard {
    width: 92% !important;
  }
  .plan-box-mobile-view-pro {
    width: 92% !important;
  }
  .text-cancel-plan {
    width: 92% !important;
  }
  .feedback-cancel-text {
    font-size: 11px;
  }
  .inner-payment-text {
    font-size: 10px;
  }
  .change-btn {
    font-size: 10px;
  }
  .icon-div {
    width: 25px;
    height: 25px;
  }
  .text-label {
    font-size: 12px;
  }
}
