.chart-section-vertical {
  display: flex;
  position: relative;
  justify-content: space-between;
  top: 10px;
}

.bar-chart-budget {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: auto;
  width: 100%;
  justify-content: space-between;
  height: 230px;
}

.bar-budget {
  background-color: rgba(38, 50, 56, 0.16);
  color: #263238;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  margin: 5px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.power-wifi-bar {
  background-color: #2260ff;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  padding: 5px;
  margin: 5px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.label-budget {
  display: block;
  padding: 5px;
  font-size: 15px;
  transform: rotate(-90deg);
  white-space: nowrap;
  align-items: center;
}

/* Food bar */
/* .bar {
  background-color: rgba(38, 50, 56, 0.16);
  padding: 90px 3px 43px 3px;
  display: flex;
  width: 30px;
  color: #263238;
  height: 167px;
  align-items: flex-end;
  border-radius: 35px;
  justify-content: center;
}

.bar h3 {
  transform: rotate(-90deg);
  margin-bottom: 0px;
  font-size: 15px;
  width: 166.536px;
  height: 28px;
  position: inherit;
  display: inline-flex;
  text-align: center;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  align-items: center;
  white-space: nowrap;
} */

/* .food-bar {
  margin-top: 23px;
}

.food-bar h3 {
  height: 2px !important;
} */

/* Food bar end */

/* power-wifi */

/* .power-wifi {
  background-color: #2260ff !important;
  padding: 90px 3px 36px 3px;
  height: 115.609px !important;
  margin-top: 66px;
}

.power-wifi h3 {
  font-size: 15px;
  width: 115.609px;
  height: 50px;
  color: #ffff;
} */

/* power-wifi end */

/* insurance bar */
/*
.insurance {
  padding: 90px 3px 46px 3px;
  height: 154.168px !important;
  margin-top: 38px;
}

.insurance h3 {
  transform: rotate(-90deg);
  width: 138px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
} */

/* insurance bar end */

/* mortgage bar */

/* .mortgage {
  padding: 90px 3px 36px 3px;
  display: flex;
  height: 116px !important;

  margin-top: 66px;
}

.mortgage h3 {
  margin-bottom: 1px;
  width: 138px;
  height: 50px;
  font-size: 15px;
  display: inline-flex;
  text-align: left;
  line-height: 20px;
} */

/* mortgage bar end */

/* car bar */

/* .car {
  padding: 90px 3px 25px 3px;
  height: 138px !important;
  margin-top: 54px;
}

.car h3 {
  width: 138px;
  font-size: 15px;
  line-height: 20px;
} */

/* car bar end */

/* rates bar */

/* .rates {
  height: 192px !important;
}

.rates h3 {
  width: 138px;
  height: 5px;
  font-size: 15px;
  line-height: 20px;
} */

/* rates bar end */

/* eating-out  */

/* .eating-out {
  padding: 90px 3px 45px 3px;
  height: 166.536px !important;
  margin-top: 25px;
  position: relative;
}

.eating-out h3 {
  transform: rotate(-90deg);
  margin-bottom: 0px;
  width: 166.536px;
  height: 28.039px;
  font-size: 15px;
} */

/*  eating-out end */

/* clothes  */

/* .clothes {
  height: 100.562px !important;

  margin-top: 58px;
}

.clothes h3 {
  margin-bottom: 0px;
  width: 138px;
  height: 8px;
  font-size: 15px;
  display: inline-flex;
} */

/*  clothes end */

.dark-blue {
  color: #2260ff !important;
}
.goals-wrp {
  margin-top: 70px;
}
.new-small-content {
  font-size: 15px;
}
.one-off-budget{
  width: 100% !important;
}
.income-merchant {
  width: 47% !important;
}
.white {
  color: #fff !important;
}

/* .pie-blck-circular{
  /* width: 45% !important; */
/* }   */

.budget-summary-main .pie-blck-circular {
  width: 45%;
}

@media only screen and (max-width: 1650px) and (min-width: 1600px) {
  .pie-blck-circular {
    width: 49% !important;
  }
}

@media only screen and (max-width: 2200px) and (min-width: 1801px) {
  .budget-summary-main .pie-blck-circular {
    width: 35% !important;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1600px) {
  .budget-summary-main .pie-blck-circular {
    width: 40% !important;
  }
}

@media only screen and (max-width: 1599px) and (min-width: 1500px) {
  .pie-blck-circular {
    width: 52% !important;
  }
}

@media only screen and (max-width: 1499px) and (min-width: 1400px) {
  .pie-blck-circular {
    width: 58% !important;
  }
}

@media only screen and (max-width: 1399px) and (min-width: 1300px) {
  .pie-blck-circular {
    width: 65% !important;
  }
  .budget-summary-main .spend-vs-budget-wrapper,
  .income-merchant .merchantSummary .small-content {
    font-size: 19px;
  }
}

@media only screen and (max-width: 1299px) and (min-width: 1200px) {
  .pie-blck-circular {
    width: 70% !important;
  }

  .budget-summary-main .spend-vs-budget-wrapper,
  .income-merchant .merchantSummary .small-content {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1025px) {
  .pie-blck-circular {
    width: 30% !important;
  }

  .chart-section-vertical .bar {
    width: 5% !important;
  }

  .spend-budget-wrp .right-top-blck {
    height: 53%;
  }

  .budget-summary-main .merchant-blck {
    margin-top: 90px;
  }

  .clothes {
    margin-top: 58px;
  }

  .car h3 {
    height: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .pie-blck-circular {
    width: 30% !important;
  }

  .chart-section-vertical .bar {
    width: 5% !important;
  }

  .spend-budget-wrp .right-top-blck {
    height: 53%;
  }

  .budget-summary-main .merchant-blck {
    margin-top: 90px;
  }

  .clothes {
    margin-top: 58px;
  }

  .car h3 {
    height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pie-blck-circular {
    width: 30% !important;
  }

  .chart-section-vertical .bar {
    width: 7% !important;
  }
}

@media only screen and (max-width: 479px) {
  .pie-blck-circular {
    width: 30% !important;
  }

  .chart-section-vertical .bar {
    width: 9% !important;
  }
}

@media only screen and (max-width: 374px) {
  .pie-blck-circular {
    width: 30% !important;
  }

  .chart-section-vertical .bar {
    width: 10% !important;
  }
}
