.row.top-nav {
  align-items: center;
}
.middle-seciton {
  margin-top: 65px;
}
.budget-tabs .nav-link {
  padding: 10px 120px !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.budget-summ-tabber-wrp .budget-tabs {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 3px;
}
.main-title {
  font-size: 25px;
  line-height: 32px;
}
.merchantSummary {
  display: -webkit-inline-box;
}
.icon-tag {
  display: -webkit-inline-box;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  width: auto;
  padding: 2px 1px 1px 3px;
  margin-bottom: 8px;
}

.icon-tag-merchant {
  display: -webkit-inline-box;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  padding: 2px 1px 1px 3px;
  margin-bottom: 8px;
}
.icon-img {
  display: flex;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 8%;
}
.icon-transaction-img {
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background-color: rgba(0, 170, 255, 0.2);
}
.icon-content {
  font-size: 16px;
  line-height: 26px;
  color: #707070;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}
.small-content {
  font-size: 20px;
  line-height: 30px;
  color: #707070;
  margin-bottom: 0px;
}
.left-block-widgets .right-top-blck.new-top-blck {
  height: auto !important;
  padding: 0px;
  margin-top: 0px;
}
.right-top-blck.new-top-blck {
  padding: 26px 26px;
  height: 82%;
  margin-top: 15px;
}

.right-top-blck.new-top-blck .per-head {
  color: #40af66;
}

.right-top-blck.new-top-blck .content .right-section select {
  color: #607d8b;
  padding: 5px 20px 5px 0px;
  width: 105px;
  font-weight: 500;
}

.progress.blue-progress {
  background-color: rgba(0, 170, 255, 0.2);
  /* border-radius: 7px; */
}
.progress {
  border-radius: 7px !important;
}
.second-section {
  margin-top: 90px;
}
.right-section select {
  background-image: url('../../assests/images/icons/Arrow-down.png');
  padding: 5px 20px;
  background-repeat: no-repeat;
  font-weight: 700;
  background-position: top 14px right;
  background-size: 14px;
}
.blue {
  border-radius: 7px;
  background-color: rgba(0, 170, 255, 1);
}
select:focus {
  outline: none;
}
/* .right-top-blck.bottom-right-block {
  /* height: 38%; */
/* }  */
select.bold.big-select {
  padding: 5px 5px;
}
.new-bottom-blck {
  /* height: 40% !important; */
  padding: 20px 25px 20px 25px !important;
}
.red-img {
  /* width: 55px; */
  background-color: rgba(254, 48, 48, 0.2);
  /* height: 55px; */
  /* display: flex; */
  /* font-size: 25px; */
  /* justify-content: center; */
  /* align-items: center; */
}
.yellow-img {
  background-color: rgba(238, 216, 104, 0.2);
  /* display: flex; */
  /* font-size: 25px; */
  /* justify-content: center; */
  /* align-items: center; */
}
.red-progress {
  background-color: #ffd6d6;
}
.pink-img {
  background-color: #f0b3d4;
}
.insurance-img {
  background-color: #ebf0b3;
}
.plug-img {
  background-color: #b3f0e1;
}
.eating-img {
  background-color: #f0dfb3;
}
.groceries-img {
  background-color: rgba(224, 83, 61, 0.25);
}
.shopping-img {
  background-color: rgba(198, 179, 240, 1);
}

.light-blue-img {
  background-color: rgba(0, 170, 255, 0.2);
}

.new-red {
  border-radius: 7px;
  background-color: rgba(254, 48, 48, 1);
}
.wrp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrp .income-merchant {
  width: 47% !important;
}
.merchant-blck {
  background-color: #fff;
  padding: 27px 25px;
  display: inline-flex;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  flex-direction: column;
}
.little-big-font {
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
}
.spend-budget-wrp .right-top-blck {
  height: auto;
}
.content h5.bold {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 0px;
}
.img-back .nav-item .active {
  background-color: #001b49 !important;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.img-back .nav-item .active:before {
  content: '';
  position: absolute;
  background-color: #47c1fe;
  height: 55px;
  width: 55px;
  top: -25px;
  left: -21px;
  border-radius: 50%;
}
.img-back .nav-item .active:after {
  content: '';
  position: absolute;
  background-color: #dd7802;
  height: 55px;
  width: 55px;
  bottom: -28px;
  right: -21px;
  border-radius: 50%;
}
.togger-wrp-financial .nav-item-financial .nav-link {
  font-size: 14px;
  font-weight: bold;
}

.togger-wrp-financial .nav-item-financial .active {
  background-color: #efeded !important;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  color: black !important;
  font-weight: bold;
  font-size: 14px;
}

.content p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(96, 125, 139, 1);
  margin-bottom: 0px;
}
.per-head {
  color: rgba(70, 155, 136, 1);
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 25px;
}

.per-head-red {
  color: rgba(223, 92, 92, 1);
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 25px;
}
.right-top-blck {
  background-color: #fff;
  padding: 23px 25px;
  border-radius: 13px;
  justify-content: space-between;
  display: inline-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.col-md-8.col-xl-8.col-sm-12.top-section {
  display: flex;
}
.new-blck {
  display: flex;
  justify-content: space-between;
}
.CircularProgressbar-text {
  font-weight: bold !important;
}
.pie-blck {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
.new-top-blck .pie-blck {
  justify-content: space-between;
}

/* .of-income-wrp {
  position: absolute;
  bottom: 55px;
  color: #263238;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.333px;
  letter-spacing: 2px;
  text-transform: uppercase;
} */

.back-red {
  background-color: #e0533d;
}
.light-red {
  background-color: rgba(254, 48, 48, 0.2);
}
.purple-img {
  background-color: rgba(197, 55, 200, 0.2);
}
.new-purple {
  background-color: #c537c8;
}
.wrp .light-yellow {
  background-color: rgba(250, 232, 72, 0.2);
}
.yellow {
  background-color: #fae848;
}
.light-green {
  background-color: rgba(70, 155, 136, 0.2) !important;
}
.green {
  background-color: #469b88;
}

/* New cssss */

.row.top-nav .fw-bold {
  font-size: 32px !important;
  font-weight: 700 !important;
}

ul#pills-tab {
  background-color: #ffff;
}

.groceries-edit-second-section .back-section {
  padding: 15px 15px !important;
}
.groceries-edit-second-section .back-section .color-option .planner {
  padding: 8px 60px;
  font-size: 18px;
}

.grocery-edit-recent-trans .name-merchant h6 {
  font-size: 14px !important;
  color: #707070 !important;
}
.grocer-edit-bar-heading .see-all {
  font-size: 14px !important;
  color: #489fcd;
}

.grocery-edit-recent-trans .percentage {
  padding: 5px 15px !important;
}

/* .groceries-edit-table .new-table .table-text-color .planner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.groceries-edit-table .planner {
  width: 175px !important;
} */

/* -----------------------------------------------Media query----------------------------------------------- */

@media screen and (min-width: 2001px) {
  .second-pie-blck {
    max-width: 200px;
  }
}

@media only screen and (max-width: 1900px) and (min-width: 1800px) {
  .budget-tabs .nav-link {
    padding: 10px 100px !important;
  }

  .main-title {
    font-size: 24px !important;
  }

  .top-section .right-section select {
    font-size: 18px;
  }

  .right-top-blck .title {
    font-size: 20px;
  }

  .right-top-blck .per-head {
    font-size: 20px;
  }
  .right-top-blck .common-content {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1600px) {
  .budget-tabs .nav-link {
    padding: 10px 95px !important;
  }

  .main-title {
    font-size: 24px !important;
  }

  .top-section .right-section select {
    font-size: 18px;
  }

  .right-top-blck .title {
    font-size: 20px;
  }

  .right-top-blck .per-head {
    font-size: 20px;
  }
  .right-top-blck .common-content {
    font-size: 16px;
  }

  .budget-summary-main .income-vs-budget-wrapper .right-section select {
    font-size: 19px !important;
    padding: 5px 5px;
  }

  .budget-summary-main .spend-vs-budget-top-side .right-section select {
    font-size: 19px !important;
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 1599px) and (min-width: 1401px) {
  .budget-tabs .nav-link {
    padding: 10px 85px !important;
  }

  .right-top-blck .title {
    font-size: 20px;
  }

  .right-top-blck .per-head {
    font-size: 20px;
  }
  .right-top-blck .common-content {
    font-size: 16px;
  }

  .budget-summary-main .back-to-portal-top .setting_background_color {
    padding: 15px 7px 15px 7px !important;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 55%;
  }

  .grocery-edit-nav .edit-your-budget {
    width: 25%;
  }

  .budget-summary-main .income-vs-budget-wrapper .right-section select {
    font-size: 18px !important;
    padding: 5px 5px;
  }

  .budget-summary-main .spend-vs-budget-top-side .right-section select {
    font-size: 18px !important;
    padding: 5px 5px;
  }

  .groceries-edit-second-section {
    width: 67%;
  }
  .budget-summary-main .setting_background_color .profile-dashboard h3 {
    font-size: 18px;
  }

  .budget-summary-main .setting_background_color .comn-btn {
    padding: 15px 10px;
  }

  .budget-summary-main .setting_background_color .profile-dashboard {
    margin-left: 10px;
  }
  .back-to-portal-grocery .setting_background_color .comn-btn {
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .budget-tabs .nav-link {
    padding: 10px 80px !important;
  }
  .planner {
    padding: 12px 76px;
  }
  .main-title {
    font-size: 26px !important;
  }

  .top-section .right-section select {
    font-size: 16px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 18px;
  }
  .right-top-blck .common-content {
    font-size: 16px;
  }

  .row.top-nav .setting_background_color {
    padding: 12px 15px 11px 7px;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 60%;
  }
  .grocery-edit-nav .edit-your-budget {
    width: 25%;
  }

  .budget-summary-main .income-vs-budget-wrapper .right-section select {
    font-size: 18px !important;
    padding: 5px 5px;
  }

  .budget-summary-main .spend-vs-budget-top-side .right-section select {
    font-size: 18px !important;
    padding: 5px 5px;
  }
  .back-to-portal-grocery .setting_background_color .comn-btn {
    padding: 15px 10px;
  }
  .budget-summary-main .setting_background_color .profile-dashboard h3 {
    font-size: 18px;
  }

  .budget-summary-main .setting_background_color .comn-btn {
    padding: 15px 10px;
  }

  .budget-summary-main .setting_background_color .profile-dashboard {
    margin-left: 10px;
  }
  .groceries-edit-second-section {
    width: 70%;
  }
}

@media only screen and (max-width: 1365px) {
  .row.top-nav .fw-bold {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .budget-tabs .nav-link {
    padding: 10px 60px !important;
  }
  /* table.table-image {
    width: 125% !important;
} */
  .row.top-nav .setting_background_color {
    padding: 12px 10px 12px 5px;
  }

  .row.top-nav .profile-dashboard h3 {
    font-size: 18px;
  }

  .budget-summary-main .setting_background_color .profile-img img {
    width: 50px;
  }

  .budget-summary-main .setting_background_color .profile-img {
    margin-right: 0px;
  }
  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 70%;
  }

  .grocery-edit-nav .edit-your-budget {
    width: 30%;
  }

  .grocery-edit-nav .back-to-portal-grocery {
    width: 35%;
  }

  .budget-summary-main .back-to-portal-top .comn-btn {
    padding: 12px 5px;
  }
  .budget-summary-main .back-to-portal-top .profile-dashboard h3 {
    font-size: 16px;
  }

  .back-to-portal-grocery .setting_background_color .comn-btn {
    padding: 15px 10px;
  }
  .groceries-edit-second-section {
    width: 80%;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1025px) {
  .row.top-nav .setting_text_color {
    font-size: 13px;
  }

  .row.top-nav .col-lg-4 {
    width: 33%;
  }
  .budget-summary-main .edit-button-top {
    width: 34% !important;
  }

  .budget-summary-main .edit-button-top .edit-btn h4 {
    font-size: 15px !important;
  }

  .budget-summary-main .back-to-portal-top {
    width: 37% !important;
  }
  .budget-summary-main .back-to-portal-top .setting_background_color {
    padding: 12px 0px 12px 5px !important;
  }

  .budget-summary-main .back-to-portal-top .setting_background_color .comn-btn {
    font-size: 16px !important;
    padding: 12px 3px;
  }
  .budget-summary-main .back-to-portal-top .setting_background_color .profile-dashboard h3 {
    font-size: 16px;
  }

  .budget-tabs .nav-link {
    padding: 10px 50px !important;
  }

  .row.top-nav .setting_background_color {
    padding: 12px 10px 12px 5px;
  }

  .row.top-nav .profile-dashboard h3 {
    font-size: 12px;
  }

  .row.top-nav .profile-dashboard .comn-btn i {
    margin-left: 5px;
    margin-right: 5px;
  }

  .row.top-nav .profile-dashboard .profile-img img {
    width: 50px;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 12px;
    padding: 12px 3px;
  }
  .budget-summary-main .setting_background_color .profile-dashboard {
    margin-left: 5px;
  }

  .grocery-edit-nav .edit-btn-col .left-arrow {
    padding: 7px;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 10px;
  }
  .col-md-8.col-xl-8.col-sm-12.top-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .main-title {
    font-size: 24px !important;
  }

  .top-section .right-section select {
    font-size: 14px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 18px;
  }
  .right-top-blck .common-content {
    font-size: 14px;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 85%;
  }
  .grocery-edit-nav .edit-your-budget {
    width: 33%;
  }
  .grocery-edit-nav .back-to-portal-grocery {
    width: 32%;
  }

  .grocery-edit-main .big-section-block {
    width: 100%;
  }

  .investment-blck {
    margin-bottom: 20px;
  }

  .groceries-edit-second-section {
    width: 95%;
  }
}

@media only screen and (max-width: 1024px) {
  .row.top-nav .setting_text_color {
    font-size: 14px;
  }

  .row.top-nav .page-heading-title-sub-title {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .row.top-nav .edit-button-top {
    width: 50% !important;
  }

  .row.top-nav .edit-button-top .edit-btn {
    width: 90%;
  }

  .row.top-nav .back-to-portal-top {
    width: 40% !important;
    margin-top: 0px;
  }

  .budget-tabs .nav-link {
    padding: 10px 80px !important;
  }

  .row.top-nav .setting_background_color {
    padding: 15px 10px 14px 5px;
  }

  .row.top-nav .profile-dashboard h3 {
    font-size: 13px;
  }

  .second-section {
    margin-top: 20px;
  }

  .goals-wrp {
    margin-top: 30px !important;
  }

  .row.top-nav .profile-dashboard .profile-img img {
    width: 50px;
    margin-left: 10px;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 11px;
    padding: 12px 7px;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 10px;
  }
  .col-md-8.col-xl-8.col-sm-12.top-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .main-title {
    font-size: 24px !important;
  }

  .top-section .right-section select {
    font-size: 14px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 18px;
  }
  .right-top-blck .common-content {
    font-size: 14px;
  }

  .row.top-nav .edit-btn h4 {
    font-size: 14px;
    line-height: 23px;
  }
  .row.top-nav .edit-btn p {
    font-size: 13px;
  }

  .row.top-nav .profile-dashboard {
    margin-left: 5px;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    width: 100%;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 12px 15px;
  }

  .grocery-edit-nav .edit-your-budget .edit-btn .left-arrow {
    padding: 7px 7px;
  }
  .grocery-edit-nav .edit-your-budget {
    width: 31%;
  }

  .grocery-edit-main .big-section-block {
    width: 100%;
  }

  .grocery-edit-main .grocer-edit {
    width: 100%;
  }

  .grocery-edit-main .sideblck {
    padding: 16px 24px;
  }

  .grocery-edit-main .bar-cart h5 {
    font-size: 15px;
  }

  .grocery-edit-main .name-merchant h6 {
    font-size: 14px;
  }

  .grocery-edit-main .date-time-blck h4,
  .grocery-edit-main .date-time-blck span {
    font-size: 14px;
  }

  .grocery-edit-main .status-btn .percentage {
    width: 90px;
    font-size: 13px;
  }

  .grocery-edit-main .transaction-amt h4 {
    font-size: 16px;
  }

  .budget-summary-main .back-to-portal-top .setting_background_color .comn-btn {
    font-size: 16px !important;
    padding: 12px 6px;
  }
  .budget-summary-main .back-to-portal-top .setting_background_color .profile-dashboard h3 {
    font-size: 16px;
  }

  .investment-blck {
    margin-bottom: 20px;
  }

  .groceries-edit-second-section {
    width: 100% !important;
  }
}

@media only screen and (max-width: 979px) {
  .row.top-nav .fw-bold {
    font-size: 28px !important;
    font-weight: 700 !important;
  }
  .row.top-nav .edit-btn p {
    font-size: 10px;
  }

  .row.top-nav .setting_background_color {
    padding: 6px 10px 12px 5px;
  }

  .row.top-nav .page-heading-title-sub-title {
    width: 100% !important;
  }

  .row.top-nav .edit-button-top {
    width: 50% !important;
  }

  .row.top-nav .edit-button-top .edit-btn {
    width: 90%;
  }

  .row.top-nav .edit-btn h4 {
    font-size: 13px;
    line-height: 23px;
  }
  .row.top-nav .edit-btn p {
    font-size: 11px;
  }

  .row.top-nav .back-to-portal-top {
    width: 50% !important;
    margin-top: 20px;
  }

  .row.top-nav .edit-btn-col {
    margin-top: 20px;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 12px;
    padding: 12px 3px;
  }
  .row.top-nav .setting_text_color {
    font-size: 14px;
    line-height: 30px;
  }
  .row.top-nav .profile-dashboard h3 {
    font-size: 15px;
  }

  .budget-tabs .nav-link {
    padding: 10px 50px !important;
  }

  .setting_background_color .profile-img {
    width: 40px;
    margin-left: 10px;
  }

  .row.top-nav .profile-dashboard .profile-img img {
    width: 40px;
  }

  .main-title {
    font-size: 22px !important;
  }

  .top-section .right-section select {
    font-size: 13px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 16px;
  }
  .right-top-blck .common-content {
    font-size: 14px;
  }

  .merchantSummary .small-content {
    font-size: 14px;
  }

  .right-top-blck.new-top-blck .right-section select {
    font-size: 13px;
  }

  .grocery-edit-nav .main-title-heading {
    width: 100%;
  }

  .grocery-edit-nav .edit-your-budget {
    width: 43%;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 12px 30px;
  }
  .grocery-edit-nav .back-to-portal-grocery {
    width: 55%;
    margin-top: 20px;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    display: inline;
  }

  .grocery-edit-main .col-md-6.col-xl-6.d-flex {
    padding: 10px;
  }

  .grocery-edit-main .status-btn .percentage {
    width: 90px;
    padding: 8px 15px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .row.top-nav .col-sm-12 {
    flex: 0 0 auto;
    width: 50%;
  }
  .row.top-nav .fw-bold {
    font-size: 26px !important;
    font-weight: 700 !important;
  }
  .row.top-nav .setting_text_color {
    font-size: 13px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .row.top-nav .edit-button-top {
    width: 100% !important;
  }
  .row.top-nav .back-to-portal-top {
    width: 100% !important;
    margin-top: 20px;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 16px;
    padding: 13px 8px;
  }

  .row.top-nav .edit-btn h4 {
    font-size: 13px;
    line-height: 23px;
  }
  .row.top-nav .edit-btn p {
    font-size: 12px;
  }
  .row.top-nav .setting_background_color {
    padding: 10px 12px 16px 10px;
  }

  .row.top-nav .profile-dashboard h3 {
    font-size: 16px;
  }
  .row.top-nav .profile-dashboard .profile-img img {
    width: 45px;
    margin-left: 0px;
  }

  .budget-tabs .nav-link {
    padding: 10px 40px !important;
    font-size: 16px;
  }

  .main-title {
    font-size: 20px !important;
  }

  .top-section .right-section select {
    font-size: 13px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 16px;
  }
  .right-top-blck .common-content {
    font-size: 14px;
  }

  .merchantSummary .red-img {
    width: 55px;
    height: 55px;
  }

  .wrp .income-merchant {
    width: 100% !important;
  }

  .merchantSummary .small-content {
    font-size: 16px;
  }
  .profile-dashboard .comn-btn i {
    margin-right: 0px;
  }

  .second-section {
    margin-top: 10px;
  }

  .middle-seciton {
    margin-top: 10px;
  }

  .income-merchant.col-lg-5.col-xl-5.col-sm-12.back-red.white {
    width: 100% !important;
  }

  .grocer-edit .right-section {
    justify-content: flex-end;
  }

  .grocery-edit-nav .edit-your-budget {
    width: 50%;
  }

  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 12px 30px;
  }
  .grocery-edit-nav .back-to-portal-grocery {
    width: 62%;
    margin-top: 20px;
  }

  .col-md-6.col-xl-6.row.groceries-edit-second-section {
    display: inline;
  }

  .grocery-edit-main .col-md-6.col-xl-6.d-flex {
    padding: 10px;
  }

  .grocery-edit-main .status-btn .percentage {
    width: 90px;
    padding: 8px 15px;
    font-size: 13px;
  }

  .grocery-edit-main .sideblck {
    padding: 16px 4px;
  }
}

@media only screen and (max-width: 599px) {
  .row.top-nav .setting_text_color {
    font-size: 12px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .row.top-nav .edit-btn h4 {
    font-size: 14px;
    line-height: 23px;
  }
  .row.top-nav .edit-btn p {
    font-size: 13px;
  }

  .row.top-nav .edit-button-top {
    width: 100% !important;
  }
  .row.top-nav .back-to-portal-top {
    width: 100% !important;
    margin-top: 20px;
  }

  .row.top-nav .setting_background_color {
    padding: 7px 10px 11px 10px;
  }
  .row.top-nav .setting_background_color .comn-btn {
    font-size: 16px;
    padding: 14px 10px;
  }

  .budget-tabs .nav-link {
    padding: 10px 35px !important;
    font-size: 14px;
  }
  .middle-seciton {
    margin-top: 25px;
  }

  .col-lg-8 {
    margin-bottom: 0;
  }

  .main-title {
    font-size: 18px !important;
  }

  .top-section .right-section select {
    font-size: 13px;
  }

  .right-top-blck .title {
    font-size: 18px;
  }

  .right-top-blck .per-head {
    font-size: 16px;
  }
  .right-top-blck .common-content {
    font-size: 14px;
  }

  .row.top-nav .profile-dashboard .comn-btn i {
    margin-right: 0px;
  }

  .merchant-blck {
    width: 100%;
  }

  .second-section {
    margin-top: 10px;
  }

  .wrp .income-merchant {
    width: 100% !important;
  }

  .grocery-edit-nav .edit-your-budget {
    width: 60%;
  }
  .grocery-edit-nav .back-to-portal-grocery {
    width: 72%;
    margin-top: 20px;
  }
  /* .grocery-edit-main .date-time-blck h4, .grocery-edit-main .date-time-blck span {
  font-size: 12px;
}
.grocery-edit-main .status-btn .percentage {
  padding: 4px 8px;
  width: 75px;
  font-size: 12px;
}
.grocery-edit-main .transaction-amt h4 {
  font-size: 13px;
} */

  .grocery-edit-main .grocer-edit {
    overflow-x: scroll;
  }

  .grocery-edit-main .grocer-edit .sideblck {
    width: 800px !important;
    padding: 13px 30px;
  }

  .grocer-edit-bar-heading {
    display: flex;
    justify-content: space-between;
    width: 800px; /*Remove this width and padding when no space needed*/
    padding: 0px 16px;
  }

  .grocer-edit .right-section {
    justify-content: flex-end !important;
  }
}

@media only screen and (max-width: 479px) {
  .row.top-nav .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 0px;
  }
  .row.top-nav .edit-btn h4 {
    font-size: 14px;
    line-height: 23px;
  }
  .row.top-nav .edit-btn p {
    font-size: 13px;
  }

  .row.top-nav .edit-button-top .edit-btn {
    width: 100%;
  }

  .row.top-nav .setting_background_color {
    padding: 12px 15px 15px 20px;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 16px !important;
    padding: 14px 7px;
  }
  .row.top-nav .profile-dashboard .comn-btn i {
    margin-right: 10px;
  }
  .row.top-nav .profile-dashboard h3 {
    font-size: 16px;
    margin-left: 10px;
  }
  .row.top-nav .profile-dashboard .profile-img img {
    width: 45px;
    /* margin-left: 30px; */
  }

  .second-section {
    margin-top: 10px;
  }
  .budget-tabs .nav-link {
    font-size: 16px;
  }
  .middle-seciton {
    margin-top: 10px;
  }

  /* .grocery-edit-main .date-time-blck h4, .grocery-edit-main .date-time-blck span {
    font-size: 11px;
    margin-left: 10px;
  }
  .grocery-edit-main .status-btn .percentage {
    padding: 10px 10px;
    width: 70px;
    font-size: 12px;
  }
  .grocery-edit-main .transaction-amt h4 {
    font-size: 13px;
  } */

  .grocery-edit-main .grocer-edit {
    overflow-x: scroll;
  }
  .grocery-edit-main .edit-your-budget .edit-btn {
    padding: 12px 60px;
  }
  .grocery-edit-nav .edit-your-budget {
    width: 100%;
  }

  .grocery-edit-main .grocer-edit .sideblck {
    width: 800px !important;
    padding: 13px 30px;
  }

  .grocery-edit-nav .back-to-portal-grocery {
    width: 100%;
    margin-top: 20px;
  }

  .planner {
    padding: 9px 70px;
  }

  .grocery-edit-main .sideblck {
    padding: 13px 4px;
  }
}

@media only screen and (max-width: 374px) {
  .row.top-nav .edit-button-top .edit-btn {
    width: 100%;
  }

  .row.top-nav .setting_background_color {
    padding: 10px 10px 10px 5px !important;
  }

  .row.top-nav .setting_background_color .comn-btn {
    font-size: 14px !important;
    padding: 12px 7px;
  }
  .row.top-nav .profile-dashboard .comn-btn i {
    margin-right: 0px;
  }
}
