/* .app-main__outer .row {
    align-items: center;
} */
.new-img {
  width: 24px;
}
.left-heading .text-merchant {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}
.widget-blck {
  background-color: #fff;
  padding: 12px 13px;
  display: flex;
  border-radius: 8px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}

.right-pie-chart {
  width: 20%;
}
/* .widget-blck.right-top-blck.new-top-blck {
    padding: 0px;
    height: 0%;
    margin-top: 0px !important;
} */
.widget-blck .chart-section-vertical {
  display: unset;
  top: 0px;
}
.new-left-widgets .new-block {
  padding: 12px 26px !important;
  margin-top: 15px !important;
}
.widget-income {
  display: inherit !important;
}

.setting-text-color-widget {
  font-size: 12px;
  font-weight: 400;
  color: #707070;
}

.widget-sub-title {
  font-size: 18px !important;
}

.widget-pie-blck .pie-blck-circular {
  width: 50%;
  padding-top: 20px;
}

.widget-sub-wrp .right-section select {
  color: #607d8b;
  padding: 5px 20px 5px 0px;
  width: 105px;
  font-weight: 500;
}

.widget-bottom-right-block {
  background-color: #fff;
  padding: 23px 25px;
  border-radius: 13px;
  justify-content: space-between;
  display: inline-flex;
  width: 100%;

  flex-direction: column;
}
